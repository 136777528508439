<template>
  <div style="width: 100%; height: 100%">
    <div id="model">
      <div class="loader" v-if="loading" :class="{ out: loadingFinish }">
        <div class="logo-parent">
          <img src="images/site/loaderOFF.png" alt="" class="logo" />
          <div class="logo-loader" :style="'clip-path: polygon(0 0, ' + loadingPct + '% 0, ' + loadingPct + '% 100%, 0% 100%);'">
            <img src="images/site/loaderON.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Scene } from '@/viewer3d/class/Scene';
import Composition from '../viewer3d/class/Composition';

const scene = new Scene();

export default {
  name: 'NFTViewer',
  components: {},
  props: {
    assets3d: { type: Object, default: null },
  },
  data() {
    return {
      customizable: window.customizable,

      picture: false,

      loading: false,
      loadingStep: 0,
      loadingStepPct: 0,
      loadingFinish: false,
      assetLoaderLoaded: false,

      canvasReady: false,

      customizableOpen: true,
    };
  },
  watch: {
    async assets3d() {
      scene.stopRenderer();
      await scene.initialisation(this, this.assets3d, {
        stats: false,
      });
      this.$emit('ready');
    },
  },
  computed: {
    loadingPct() {
      if (!this.assetLoaderLoaded) {
        return 0;
      }
      return (((1 / Composition.assets.length) * this.loadingStepPct + this.loadingStep / Composition.assets.length) * 100).toFixed(2);
    },
  },
  methods: {},
  async mounted() {
    await scene.initialisation(this, this.assets3d, {
      stats: false,
    });
    this.$emit('ready');
  },
};
</script>
