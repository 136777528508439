import { BtcValue } from './BtnValue';

export class Amount {
  fees = new BtcValue(0);
  price = new BtcValue(0);
  total = new BtcValue(0);

  constructor(_amount) {
    if (!_amount) {
      return;
    }
    this.fees = new BtcValue(_amount.fees);
    this.price = new BtcValue(_amount.price);
    this.total = new BtcValue(_amount.total);
  }

  getFeesBtc() {
    return this.fees.toBtc();
  }
  getPriceBtc() {
    return this.price.toBtc();
  }
  getTotalBtc() {
    return this.total.toBtc();
  }

  getFeesUsd(btcPrice) {
    return this.fees.toUsd(btcPrice);
  }
  getPriceUsd(btcPrice) {
    return this.price.toUsd(btcPrice);
  }
  getTotalUsd(btcPrice) {
    return this.total.toUsd(btcPrice);
  }
}
