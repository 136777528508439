<script>
export default {
  name: 'SocialInstagram',
  props: {
    url: String,
  },
};
</script>

<template>
  <a :href="url" target="_blank">
    <svg class="ico"><use xlink:href="#ico-instagram"></use></svg>
  </a>
</template>

<style scoped lang="scss"></style>
