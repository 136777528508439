<script>
import * as md5 from 'md5';
import SocialTwitter from '@/components/gallery/social/SocialTwitter.vue';
import SocialTelegram from '@/components/gallery/social/SocialTelegram.vue';
import SocialDiscord from '@/components/gallery/social/SocialDiscord.vue';
import SocialMagicEden from '@/components/gallery/social/SocialMagicEden.vue';
import SocialSoundCloud from '@/components/gallery/social/SocialSoundCloud.vue';
import SocialInstagram from '@/components/gallery/social/SocialInstagram.vue';
import SocialTiktok from '@/components/gallery/social/SocialTiktok.vue';
import SocialThreads from '@/components/gallery/social/SocialThreads.vue';
import SocialWebsite from '@/components/gallery/social/SocialWebsite.vue';
import SocialYoutube from '@/components/gallery/social/SocialYoutube.vue';

export default {
  name: 'GalleryDetail',
  components: { SocialTwitter, SocialTelegram, SocialDiscord, SocialMagicEden, SocialInstagram, SocialSoundCloud, SocialTiktok, SocialThreads, SocialWebsite, SocialYoutube },
  props: {
    item: {
      type: Object,
      default: () => ({
        articleType: 'LEARN',
        content: '',
        contentUrl: '',
        socials: [],
        tags: [],
        title: '',
      }),
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    videoUrl() {
      return 'https://www.youtube.com/embed/' + this.videoYoutubeId;
    },
    videoYoutubeId() {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = this.item.contentUrl.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
  },
  async mounted() {
    await this.$nextTick();
    setTimeout(() => {
      this.open = true;
    }, 100);
  },
  methods: {
    close() {
      this.$emit('closing');
      this.open = false;
      setTimeout(() => {
        this.$emit('close');
      }, 500);
    },
    searchTag(tag) {
      this.$parent.currentTag = tag;
      this.close();
    },
  },
};
</script>

<template>
  <div id="gallery-detail" @click.prevent.stop="close" :class="{ open }">
    <div class="content" @click.prevent.stop>
      <div class="content-header">
        <div class="title">
          <h2>{{ item.title }}</h2>
        </div>
        <div class="close">
          <div class="btn btn-secondary" @click.prevent.stop="close">
            Close
            <svg class="ico"><use xlink:href="#ico-cross"></use></svg>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="video">
          <iframe :src="videoUrl" :title="item.title" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="description" v-html="item.content"></div>
        <div class="author" v-if="item.author">Author: {{ item.author }}</div>
        <div class="row" v-if="(item.socials && item.socials.length > 0) || (item.tags && item.tags.length > 0)">
          <div class="socials" id="tutorial-socials" v-if="item.socials && item.socials.length > 0"><component :is="'Social' + social.key" :url="social.value" v-for="social in item.socials" :key="social.key" /></div>
          <div class="tags">
            <div class="tag" v-for="tag in item.tags" :key="tag" @click="searchTag(tag)">{{ tag }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#gallery-detail {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s;
  overflow: auto;
  backdrop-filter: blur(0px);

  @media (max-width: 900px) {
    align-items: flex-start;
    padding: 10px;
  }
  @media (max-width: 520px) {
    padding: 0px;
  }

  &.open {
    opacity: 1;
    backdrop-filter: blur(10px);

    .content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .content {
    background: #0a1b1b;
    border: 1px solid #356666;
    border-radius: 6px;
    width: 100%;
    max-width: 1200px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: 0.5s;
    transform: translateY(30px);
    padding: 33px;

    @media (max-width: 1200px) {
      max-width: calc(100vw - 40px);
    }
    @media (max-width: 900px) {
      padding: 10px 20px 20px 20px;
    }
    @media (max-width: 520px) {
      max-width: calc(100vw);
      border-radius: 0;
      padding: 20px;
      border-color: transparent;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      @media (max-width: 900px) {
        margin-bottom: 10px;
      }
      @media (max-width: 520px) {
        flex-wrap: wrap;
        .title {
          order: 2;
        }
        .close {
          margin-left: auto;
        }
      }

      .title {
        margin-bottom: 0px;
        h2 {
          font-size: 40px;
        }
        @media (max-width: 900px) {
          margin-bottom: 0px;
          h2 {
            font-size: 20px;
          }
        }
      }

      .btn-secondary {
        background: #0a1b1b;
        color: #39a0a0;
        border-color: #39a0a0;
        padding: 4px 9px;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100px;
        margin-left: auto;
        margin-bottom: 5px;

        svg {
          width: 10px;
          margin: 0;
          margin-left: auto;

          * {
            fill: #39a0a0;
          }
        }
      }
    }
    .content-wrapper {
      @media (max-width: 520px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        .author {
          margin-top: auto;
        }
      }
    }

    .video {
      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        max-height: 70vh;
      }
    }
    .description {
      margin-top: 30px;
      @media (max-width: 900px) {
        margin-top: 10px;
      }
    }
    .author {
      margin-top: 30px;
      @media (max-width: 900px) {
        margin-top: 10px;
      }
    }
    .socials {
      display: flex;
      gap: 10px;
      align-items: center;

      svg {
        fill: #00ffff;
      }
    }
    .tags {
      display: flex;
      gap: 10px;
      align-items: center;
      .tag {
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
#tutorial-socials {
  svg {
    * {
      fill: #fff;
    }
  }
}
</style>
