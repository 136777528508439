<template>
  <section id="downloadModule" :class="{ active: true }">
    <div class="dm_container">
      <div class="dm_close" @click="close">
        close <svg class="ico"><use xlink:href="#ico-more"></use></svg>
      </div>
      <div class="dm_content">
        <div class="dm_infos">
          <h3 class="h3">{{ $t('downloadmodule.title') }}</h3>
          <div class="dm_btns">
            <a class="dm_id" @click.prevent.stop="openNft">
              AILU <span>#{{ nft.itemId }}</span>
            </a>
            <a class="dm_id" :href="'https://magiceden.io/ordinals/item-details/' + nft.inscriptionId" target="_blank">Magic Eden</a>
          </div>

          <div class="dm_nft_mobile"><img :src="urlFB" alt="" /></div>
          <div class="dm_links">
            <!--            <a class="btn" :href="urlModelA" target="_blank" :download="nft.itemId + '_A.glb'" @click.prevent.stop.exact="clickedDownload(urlModelA, nft.itemId + '_A.glb')">-->
            <!--              <div class="icon">-->
            <!--                <svg class="ico"><use xlink:href="#ico-download"></use></svg>-->
            <!--              </div>-->
            <!--              <div class="text">-->
            <!--                <span>{{ $t('downloadmodule.modelA') }}</span>-->
            <!--                <span class="sub-text">{{ $t('downloadmodule.glb') }}</span>-->
            <!--              </div>-->
            <!--            </a>-->
            <a class="btn" :href="urlPFP" target="_blank" :download="nft.itemId + '_fb'" @click.prevent.stop.exact="clickedDownload(urlPFP, nft.itemId + '_pfp.png')">
              <div class="icon">
                <svg class="ico"><use xlink:href="#ico-download"></use></svg>
              </div>
              <div class="text">
                <span>{{ $t('downloadmodule.pfp') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </div>
            </a>
            <a class="btn" :href="urlFB" target="_blank" :download="nft.itemId + '_fb'" @click.prevent.stop.exact="clickedDownload(urlFB, nft.itemId + '_fb.png')">
              <div class="icon">
                <svg class="ico"><use xlink:href="#ico-download"></use></svg>
              </div>
              <div class="text">
                <span>{{ $t('downloadmodule.fullbody') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </div>
            </a>
            <a class="btn" :href="urlNoBG" target="_blank" :download="nft.itemId + '_nobg'" @click.prevent.stop.exact="clickedDownload(urlNoBG, nft.itemId + '_nobg.png')">
              <div class="icon">
                <svg class="ico"><use xlink:href="#ico-download"></use></svg>
              </div>
              <div class="text">
                <span>{{ $t('downloadmodule.nobackground') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.png') }}</span>
              </div>
            </a>
            <a class="btn" :href="urlModelT" target="_blank" :download="nft.itemId + '_T.glb'" @click.prevent.stop.exact="clickedDownload(urlModelT, nft.itemId + '_T.glb')">
              <div class="icon">
                <svg class="ico"><use xlink:href="#ico-download"></use></svg>
              </div>
              <div class="text">
                <span>{{ $t('downloadmodule.modelT') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.glb') }}</span>
              </div>
            </a>
            <a class="btn" :href="urlVRM" target="_blank" :download="nft.itemId + '_VRM.glb'" @click.prevent.stop.exact="clickedDownload(urlVRM, nft.itemId + '_VRM.vrm')">
              <div class="icon">
                <svg class="ico"><use xlink:href="#ico-download"></use></svg>
              </div>
              <div class="text">
                <span>{{ $t('downloadmodule.vrm') }}</span>
                <span class="sub-text">{{ $t('downloadmodule.vrm-file') }}</span>
              </div>
            </a>
          </div>
        </div>
        <div class="dm_nft"><img :src="urlFB" alt="" /></div>
      </div>
      <!--      <div class="dm_gallery scroll-style">-->
      <!--        <div class="dm_item active">-->
      <!--          <div class="activeAnimation">-->
      <!--            <span class="center"></span>-->
      <!--          </div>-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--        <div class="dm_item">-->
      <!--          <img src="images/site/nft.png" alt="" />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import { NftInscription } from '@/components/btc-mint/order';
import * as md5 from 'md5';
import { gsap } from 'gsap';
import config from '@/config';

export default {
  name: 'btcDownload',
  props: {
    nft: {
      type: NftInscription,
      default: () => new NftInscription(),
    },
  },
  data: () => ({
    delivery: 'https://delivery.endangeredlabs.io/download/',
  }),
  mounted() {
    this.animateIn();
  },
  computed: {
    isIframe() {
      return this.nft.fileUrl.match(/(.html)/i);
    },
    previewUrl() {
      if (this.isIframe) {
        return this.nft.previewUrl;
      }
      return this.nft.fileUrl;
    },
    urlModelA() {
      return this.delivery + 'model-A/' + this.toUrl(this.nft.id + '_A.glb');
    },
    urlModelT() {
      return this.delivery + 'model-T/' + this.toUrl(this.nft.id + '_T.glb');
    },
    urlVRM() {
      return this.delivery + 'vrm/' + this.toUrl(this.nft.id + '_VRM.vrm');
    },
    urlFB() {
      return this.delivery + 'fb/' + this.toUrl(this.nft.id + '_FB.png');
    },
    urlPFP() {
      return this.delivery + 'pfp/' + this.toUrl(this.nft.id + '_PFP.png');
    },
    urlNoBG() {
      return this.delivery + 'nobg/' + this.toUrl(this.nft.id + '_NoBG.png');
    },
  },
  methods: {
    openNft() {
      // window.open('https://www.ord.io/' + nft.inscriptionId);
      window.open('https://ordinals.com/inscription/' + this.nft.inscriptionId);
    },
    toUrl(file) {
      const ext = file.split('.')[1];
      const hash = md5(file);
      const newName = hash.substring(8, 16).toUpperCase();
      return newName + '.' + ext;
    },
    async clickedDownload(fileName, name) {
      try {
        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    shareOnTwitter(nft) {
      const nfts = ['AILU #' + nft.itemId];
      const shareText = `I just inscribed ${nfts.join(',')} on bitcoin forever @ailu2100`;

      const encodedUri = encodeURI(shareText).replace(/#/g, '%23');

      const result = `https://twitter.com/intent/tweet?text=${encodedUri}`;

      window.open(result, '_blank').focus();
    },
    async close() {
      await this.animateOut();
      this.$emit('close');
    },
    async animateIn() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          '#downloadModule',
          {
            opacity: 0,
            y: 30,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
    async animateOut() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          '#downloadModule',
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: 30,
            duration: 0.5,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
  },
};
</script>

<style scoped lang="scss"></style>
