import Home from './pages/Home';
import Cms from '@/pages/Cms.vue';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/hub/:ailu?',
    name: 'hub',
    component: Cms,
  },
  {
    path: '/documents',
    name: 'documents',
    component: Cms,
  },
  {
    path: '/community',
    name: 'community',
    component: Cms,
  },
];
