<template>
  <div class="btc-mint__notification" :class="'--' + notif.type">
    {{ notif.text }}
  </div>
</template>

<script>
export default {
  name: 'btcNotifications',
  props: {
    notif: {
      type: Object,
      default: () => ({
        text: '',
        type: 'success',
      }),
    },
  },
  data: () => ({
    timeout: 0,
  }),
  watch: {
    notif: {
      handler() {
        clearTimeout(this.timeout);
        this.start();
      },
      deep: true,
    },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      this.timeout = setTimeout(() => {
        this.$emit('end');
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.btc-mint__notification {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  color: var(--btc-white);
  border-radius: 5px;

  &.--error {
    background: var(--btc-error);
  }
  &.--success {
    background: var(--btc-primary-darker);
  }
}
</style>
