<template>
  <div id="btc-mint" class="btc-mint">
    <div class="btc-mint__bg" ref="btc-mint__bg"></div>
    <div class="btc-mint__container" ref="btc-mint__container" v-if="walletConnected">
      <div class="btc-mint__menu">
        <div class="btc-mint__btns">
          <div class="btc-mint__btn --secondary" @click="popup = 'connect'" :class="{ '--active': popup === 'connect' }" v-if="!walletConnected">{{ $t('mint.popup.connect') }}</div>
          <div class="btc-mint__btn --secondary" @click="disconnectUserWalletSession" v-if="walletConnected">{{ $t('mint.popup.disconnect') }}</div>
          <!--          <div-->
          <!--            class="btc-mint__btn &#45;&#45;secondary"-->
          <!--            @click="-->
          <!--              menu = '';-->
          <!--              currentOrderId = null;-->
          <!--            "-->
          <!--            :class="{ '&#45;&#45;active': menu === '' && currentOrderId === null }"-->
          <!--          >-->
          <!--            {{ $t('mint.popup.mint') }}-->
          <!--          </div>-->
          <!--          <div class="btc-mint__btn &#45;&#45;secondary" @click="menu = 'orderList'" :class="{ '&#45;&#45;active': menu === 'orderList' }">MY ORDERS ({{ ordersFiltered.length }})</div>-->
          <div class="btc-mint__btn --secondary" @click="menu = 'myInscription'" :class="{ '--active': menu === 'myInscription' }">MY AILUS ({{ nftInscriptions.length }})</div>
          <div class="btc-mint__btn --secondary --close" @click="close">
            {{ $t('mint.popup.close') }}
            <svg class="ico">
              <use xlink:href="#ico-more"></use>
            </svg>
          </div>
        </div>
      </div>
      <BtcMinting v-if="step === 'minting' && collection && collection.id" ref="btc-mint__minting" />
      <BtcWaitingPayment v-if="step === 'waiting'" :order="currentOrder" />
      <BtcOrders v-if="step === 'orders'" :orders="ordersFiltered" />
      <btcOrderCompleted v-if="step === 'completed'" :order="currentOrder" />
      <btcSalesClosed v-if="step === 'closed'" :collection="collection" ref="btc-mint__closing" />
      <btcMyInscriptions v-if="step === 'myInscription'" :orders="orders" />
      <btcNotifications :notif="notif" v-if="notif" @end="notif = null" />
    </div>
    <BtcConnect v-if="popup === 'connect'" />
    <btcDownload v-if="nftDownload" :nft="nftDownload" @close="nftDownload = null" />
  </div>
</template>

<script>
import BtcMinting from '@/components/btc-mint/btcMinting.vue';
import BtcWaitingPayment from '@/components/btc-mint/btcWaitingPayment.vue';
import BtcOrders from '@/components/btc-mint/btcOrders.vue';
import btcOrderCompleted from '@/components/btc-mint/btcOrderCompleted.vue';
import btcSalesClosed from '@/components/btc-mint/btcSalesClosed.vue';
import btcMyInscriptions from '@/components/btc-mint/btcMyInscriptions.vue';
import BtcNotifications from '@/components/btc-mint/btcNotifications.vue';
import api from '@/components/btc-mint/api';
import { Collection } from '@/components/btc-mint/collection';
import { Order } from './order';
import BtcConnect from '@/components/btc-mint/btcConnect.vue';
import { gsap } from 'gsap';
// import { AnchorMode } from '@stacks/transactions';
import config, { envs } from '../../config';
import { AddressPurpose, BitcoinNetworkType, getAddress, sendBtcTransaction } from 'sats-connect';
import BtcDownload from '@/components/btc-mint/btcDownload.vue';
import Wallet from 'sats-connect';

export default {
  name: 'BtcMintPopup',
  data: () => ({
    network: envs.NETWORK_NAME, // 'regtest', // 'testnet', // 'mainnet'
    loaders: {},
    collectionId: envs.COLLECTION_ID,
    collection: null,
    orders: [],
    menu: 'myInscription',
    popup: '',
    currentOrderId: null,
    btcPrice: 0,
    autoRefresh: false,
    autoRefreshInterval: 0,
    walletConnected: false,
    walletConnectedType: '',
    userOrdAddress: null,
    userRefundAddress: null,
    notif: null,
    currentPasswordIsValid: null,
    currentPasswordWhitelist: '',
    maxAllowed: 10,
    nftDownload: null,
  }),
  components: { BtcDownload, BtcNotifications, BtcConnect, btcSalesClosed, btcOrderCompleted, BtcOrders, BtcWaitingPayment, BtcMinting, btcMyInscriptions },
  async created() {
    api.init(envs.BACKEND_URI);

    await this.getCollection();
    await this.getCurrentBtcUsd();
  },
  async mounted() {
    this.emitter.on('download', this.onDownload);
    this.animateIn();

    this.getUserWalletSession();

    if (!this.walletConnected) {
      this.popup = 'connect';
    }

    if (this.userOrdAddress) {
      await this.getOrders(this.userOrdAddress);
    }

    this.startAutoRefresh();

    this.refreshCollection = setInterval(() => {
      this.getCollection(false);
    }, 15000);
  },
  unmounted() {
    clearInterval(this.autoRefreshInterval);
    clearInterval(this.refreshCollection);
    this.emitter.off('download', this.onDownload);
  },
  computed: {
    step() {
      if (this.menu === 'orderList') {
        return 'orders';
      }
      if (this.menu === 'myInscription') {
        return 'myInscription';
      }
      if (this.currentOrderId && this.currentOrder) {
        return this.currentOrder.getOrderState();
      }
      // if (this.collection && (this.collection.isSoldOut() || this.collection.isClosed())) {
      //   return 'closed';
      // }

      return 'myInscription';
    },
    currentOrder() {
      if (!this.currentOrderId) {
        return null;
      }
      const current = this.orders.find((order) => order.id === this.currentOrderId);
      if (!current) {
        return null;
      }

      return current;
    },
    nftInscriptions() {
      let nfts = [];
      for (const order of this.orders) {
        nfts = [...nfts, ...order.nftsInscriptions];
      }
      return nfts;
    },
    ordersFiltered() {
      return this.orders.filter((order) => !order.cursed);
    },
  },
  methods: {
    onDownload(_nft) {
      this.nftDownload = _nft;
    },
    async getCollection(loader = true) {
      loader && (await this.setLoader('global', true));
      const result = await api.get('collection/' + this.collectionId);
      if (result.error()) {
        loader && (await this.setLoader('global', false));
        // @TODO géré les erreur
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.collection') });
        return;
      }

      this.collection = new Collection(result.data());

      loader && (await this.setLoader('global', false));
    },
    async getOrders(userOrdAddress, loader = true) {
      if (!userOrdAddress) {
        return;
      }

      loader && (await this.setLoader('orders', true));
      const result = await api.get('orders/' + this.collectionId + '/' + userOrdAddress);
      loader && (await this.setLoader('orders', false));
      if (result.error()) {
        // @TODO géré les erreur
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.orders') });
        return false;
      }

      this.orders = result.data().map((order) => new Order(order));

      return true;
    },
    startAutoRefresh() {
      if (this.autoRefresh === true) {
        return;
      }
      this.autoRefresh = true;
      this.autoRefreshInterval = setInterval(() => {
        this.getOrders(this.userOrdAddress, false);
      }, 15000);
    },
    async getCurrentBtcUsd() {
      await this.setLoader('global', true);
      const result = await api.get('https://cex.io/api/last_price/BTC/USD');
      await this.setLoader('global', false);
      if (result.error()) {
        //@TODO gérer les erreur
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.currency') });
        return;
      }

      this.btcPrice = parseFloat(result.data().lprice);
    },
    async copyClipboard(value, $el) {
      await navigator.clipboard.writeText(value);

      if (!$el) {
        return;
      }

      $el.innerHTML = 'COPIED';
      setTimeout(() => {
        $el.innerHTML = 'COPY';
      }, 2000);
    },
    async setLoader(name, value) {
      if (value === true) {
        this.loaders[name] = Date.now();
      } else {
        const diff = Date.now() - this.loaders[name];
        if (diff > 0 && diff < 500) {
          await new Promise((resolve) => setTimeout(() => resolve(), 500 - diff));
        }
        this.loaders[name] = 0;
      }
    },
    setUserWalletSession(cardinalAddress, ordinalAddress, type) {
      console.log('setUserWalletSession', cardinalAddress, ordinalAddress);
      if (!ordinalAddress) {
        return;
      }

      this.userRefundAddress = cardinalAddress;
      this.userOrdAddress = ordinalAddress;

      localStorage.setItem('_btc_cardinal_address', cardinalAddress);
      localStorage.setItem('_btc_ordinal_address', ordinalAddress);
      localStorage.setItem('_btc_connected', '1');

      if (type) {
        localStorage.setItem('_btc_connected_type', type);
        this.walletConnectedType = type;
      }
      this.walletConnected = true;

      if (this.$refs['btc-mint__minting']) {
        this.$refs['btc-mint__minting'].ordAddress = ordinalAddress;
        this.$refs['btc-mint__minting'].cardAddress = cardinalAddress;
        this.$refs['btc-mint__minting'].checkWhitelist();
      }

      if (this.$refs['btc-mint__closing']) {
        this.$refs['btc-mint__closing'].ordAddress = ordinalAddress;
        this.$refs['btc-mint__closing'].checkWhitelist();
      }
    },
    getUserWalletSession() {
      if (localStorage.getItem('_btc_ordinal_address')) {
        this.userOrdAddress = localStorage.getItem('_btc_ordinal_address');
      }

      if (localStorage.getItem('_btc_cardinal_address')) {
        this.userRefundAddress = localStorage.getItem('_btc_cardinal_address');
      }

      if (localStorage.getItem('_btc_connected') === '1') {
        this.walletConnected = true;
      }

      if (localStorage.getItem('_btc_connected_type')) {
        this.walletConnectedType = localStorage.getItem('_btc_connected_type');
      }

      if (this.walletConnectedType === 'phantom') {
        this.connectUserWalletSessionPhantom();
      }
      if (this.walletConnectedType === 'unisat') {
        this.connectUserWalletSessionUnisat();
      }
    },
    async connectUserWalletSessionUnisat() {
      let unisatProvider = null;
      if ('unisat' in window) {
        const anyWindow = window;
        const provider = anyWindow.unisat;

        if (provider) {
          unisatProvider = provider;
        }
      }
      if (!unisatProvider) {
        this.popup = '';
        this.showNotif({ type: 'error', text: this.$t('mint.notifs.connectError.unisat') });
        return;
      }

      const $this = this;
      const accounts = await unisatProvider.requestAccounts();
      // console.log(accounts);
      // unisatProvider.on('accountsChanged', (accounts) => {
      //   if (accounts.length > 0) {
      //     // Set new address and continue as usual
      //     setWallets(accounts);
      //   } else {
      //     // Attempt to reconnect to Phantom
      //     this.disconnectUserWalletSession();
      //   }
      // });

      const setWallets = (addresses) => {
        const cardinalAddress = '';
        const ordinalAddress = addresses[0];

        this.setUserWalletSession(cardinalAddress, ordinalAddress, 'unisat');

        this.getOrders($this.userOrdAddress, false);

        this.showNotif({ type: 'success', text: $this.$t('mint.notifs.connectSuccess.unisat') });

        this.popup = '';
      };

      setWallets(accounts);
    },
    async connectUserWalletSessionXVerse() {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: this.$t('mint.connectMessage'),
          network: {
            type: config.envs.NETWORK_NAME === 'testnet3' ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (result) => {
          if (result.addresses && result.addresses.length > 0) {
            const cardinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Payment)?.address;
            const ordinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

            this.setUserWalletSession(cardinalAddress, ordinalAddress, 'xverse');

            this.getOrders(this.userOrdAddress, false);

            this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.xverse') });

            this.popup = '';
          }
        },
        onCancel: () => {},
      };

      await getAddress(getAddressOptions);
    },
    async connectUserWalletSessionPhantom() {
      let phantomProvider = null;
      if ('phantom' in window) {
        const anyWindow = window;
        const provider = anyWindow.phantom?.bitcoin;

        if (provider && provider.isPhantom) {
          phantomProvider = provider;
        }
      }
      if (!phantomProvider) {
        this.popup = '';
        this.showNotif({ type: 'error', text: this.$t('mint.notifs.connectError.phantom') });
        return;
      }

      const $this = this;
      const accounts = await phantomProvider.requestAccounts();
      phantomProvider.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          // Set new address and continue as usual
          setWallets(accounts);
        } else {
          // Attempt to reconnect to Phantom
          this.disconnectUserWalletSession();
        }
      });

      const setWallets = (addresses) => {
        const cardinalAddress = addresses.find((address) => address.purpose === AddressPurpose.Payment)?.address;
        const ordinalAddress = addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

        this.setUserWalletSession(cardinalAddress, ordinalAddress, 'phantom');

        this.getOrders($this.userOrdAddress, false);

        this.showNotif({ type: 'success', text: $this.$t('mint.notifs.connectSuccess.phantom') });

        this.popup = '';
      };

      setWallets(accounts);
    },
    async connectUserWalletSessionMagicEden() {
      const getAddressOptions = {
        getProvider: async () => window.BitcoinProvider,
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: this.$t('mint.connectMessage'),
          network: {
            type: config.envs.NETWORK_NAME === 'testnet3' ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (result) => {
          if (result.addresses && result.addresses.length > 0) {
            const cardinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Payment)?.address;
            const ordinalAddress = result.addresses.find((address) => address.purpose === AddressPurpose.Ordinals)?.address;

            this.setUserWalletSession(cardinalAddress, ordinalAddress, 'magicEden');

            this.getOrders(this.userOrdAddress, false);

            this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.magicEden') });

            this.popup = '';
          }
        },
        onCancel: () => {},
      };

      await getAddress(getAddressOptions);
    },
    async connectUserWalletSessionHiro() {
      // gestion via Hiro Wallet
      if (window.btc) {
        const userAddresses = await window.btc?.request('getAddresses');

        const cardinalAddress = userAddresses?.result?.addresses?.find((address) => address?.type === 'p2wpkh')?.address;
        const ordinalAddress = userAddresses?.result?.addresses?.find((address) => address?.type === 'p2tr')?.address;

        this.setUserWalletSession(cardinalAddress, ordinalAddress, 'hiro');

        this.getOrders(this.userOrdAddress, false);

        this.showNotif({ type: 'success', text: this.$t('mint.notifs.connectSuccess.hiro') });

        this.popup = '';
      }
    },
    disconnectUserWalletSession() {
      if (!this.walletConnected) {
        return;
      }

      this.walletConnected = false;
      this.autoRefresh = false;
      clearInterval(this.autoRefreshInterval);

      localStorage.removeItem('_btc_ordinal_address');
      localStorage.removeItem('_btc_cardinal_address');
      localStorage.removeItem('_btc_connected');
      localStorage.removeItem('_btc_connected_type');

      this.orders = [];

      if (this.$refs['btc-mint__minting']) {
        this.$refs['btc-mint__minting'].ordAddress = '';
        this.$refs['btc-mint__minting'].userOrdAddress = '';
        this.$refs['btc-mint__minting'].cardAddress = '';
        this.$refs['btc-mint__minting'].userRefundAddress = '';
      }

      this.forceClose();
    },
    isTaprootAddress(address) {
      return address.startsWith('bc1p');
    },
    async payWithWalletSession(order) {
      if (this.walletConnectedType === 'hiro') {
        await this.setLoader('payWithWallet', true);
        const resp = await window.btc?.request('sendTransfer', {
          address: order.receiveAddress,
          amount: order.totalNeeded.satoshi.toString(),
        });
        console.log(resp);
        await this.setLoader('payWithWallet', false);
      } else if (this.walletConnectedType === 'xverse') {
        await this.setLoader('payWithWallet', true);
        const sendBtcOptions = {
          payload: {
            network: {
              type: config.envs.NETWORK_NAME === 'testnet3' ? BitcoinNetworkType.Testnet : BitcoinNetworkType.Mainnet,
            },
            recipients: [
              {
                address: order.receiveAddress,
                amountSats: order.totalNeeded.satoshi,
              },
            ],
            senderAddress: this.userRefundAddress,
          },
          onFinish: async (response) => {
            await this.setLoader('payWithWallet', false);
            this.showNotif({ type: 'success', text: this.$t('mint.notifs.payWithWallet.xverse') });
            console.log(response);
          },
          onCancel: async () => {
            await this.setLoader('payWithWallet', false);
          },
        };

        try {
          await sendBtcTransaction(sendBtcOptions);
        } catch (e) {
          console.error(e);
          await this.setLoader('payWithWallet', false);
          this.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.payWithWallet.xverse') });
        }
      } else if (this.walletConnectedType === 'unisat') {
        let unisatProvider = null;
        if ('unisat' in window) {
          const anyWindow = window;
          const provider = anyWindow.unisat;

          if (provider) {
            unisatProvider = provider;
          }
        }
        if (!unisatProvider) {
          this.popup = '';
          this.showNotif({ type: 'error', text: this.$t('mint.notifs.connectError.unisat') });
          return;
        }
        await this.setLoader('payWithWallet', true);
        try {
          const tx = await unisatProvider.sendBitcoin(order.receiveAddress, Number(order.totalNeeded.satoshi));
          this.showNotif({ type: 'success', text: this.$t('mint.notifs.payWithWallet.unisat') });
          console.log(tx);
        } catch (e) {
          console.log(e);
          this.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.payWithWallet.unisat') });
        }
        await this.setLoader('payWithWallet', false);
      }
      // openSTXTransfer({
      //   network: new StacksTestnet(),
      //
      //   recipient: order.receiveAddress, // which address we are sending to
      //   amount: order.totalNeeded.satoshi, // tokens, denominated in micro-STX
      //   anchorMode: AnchorMode.Any,
      //
      //   onFinish: response => console.log(response.txid),
      //   onCancel: () => console.log('User canceled'),
      // });
    },
    formatDuration(seconds) {
      if (seconds < 0) return 'Veuillez saisir un nombre positif de secondes.';

      const timeUnits = [
        { unit: 'year', duration: 60 * 60 * 24 * 365 },
        { unit: 'month', duration: 60 * 60 * 24 * 30 },
        { unit: 'day', duration: 60 * 60 * 24 },
        { unit: 'hour', duration: 60 * 60 },
        { unit: 'minute', duration: 60 },
        { unit: 'second', duration: 1 },
      ];

      let result = '';

      for (let i = 0; i < timeUnits.length; i++) {
        const { unit, duration } = timeUnits[i];
        if (seconds >= duration) {
          const num = Math.round(seconds / duration);
          return `${num} ${unit}${num > 1 ? 's' : ''}`;
        }
      }

      return 'now';
    },
    async close() {
      await this.animateOut();
      this.$emit('close');
    },
    async forceClose() {
      await gsap
        .timeline({ paused: true })
        .fromTo(
          this.$refs['btc-mint__bg'],
          {
            backdropFilter: 'blur(10px)',
          },
          {
            backdropFilter: 'blur(0px)',
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
      this.$emit('close');
    },
    async animateIn() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          this.$refs['btc-mint__container'],
          {
            opacity: 0,
            y: 30,
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .fromTo(
          this.$refs['btc-mint__bg'],
          {
            backdropFilter: 'blur(0px)',
          },
          {
            backdropFilter: 'blur(10px)',
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
    async animateOut() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          this.$refs['btc-mint__container'],
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: 30,
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .fromTo(
          this.$refs['btc-mint__bg'],
          {
            backdropFilter: 'blur(10px)',
          },
          {
            backdropFilter: 'blur(0px)',
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
    showNotif(notif) {
      this.notif = notif;
    },
  },
};
</script>

<style lang="scss">
:root {
  --btc-bg-primary: #0a1b1b;
  --btc-primary: #00ffff;
  --btc-primary-desaturated: #39a0a0;
  --btc-primary-darker: #356666;
  --btc-primary-darker-bg: #004444;
  --btc-secondary: #bbeb54;
  --btc-secondary-darker: #153232;
  --btc-white: #ffffff;
  --btc-black: #071010;
  --btc-error: red;

  --btc-font-title: 'Itc Bold', serif;
  --btc-font-text: 'SpaceGrotesk Regular', serif;

  --btc-font-size-title: 33px;
  --btc-font-size-subtitle: 18px;
  --btc-font-size-text: 16px;

  --btc-border-radius: 5px;

  --btc-transition: 0.3s;
}

#btc-mint {
  position: fixed;
  inset: 0;
  z-index: 99999;
  overflow: auto;
  font-family: var(--btc-font-text);
  font-size: var(--btc-font-size-text);
  line-height: 100%;
  user-select: none;
  display: flex;
  align-items: center;

  h2 {
    color: var(--btc-primary);
    font-size: var(--btc-font-size-title);
    font-family: var(--btc-font-title);
    margin-bottom: 10px;
  }

  h3 {
    color: var(--btc-secondary);
    font-size: var(--btc-font-size-text);
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  h4 {
    color: var(--btc-white);
    font-size: var(--btc-font-size-subtitle);
    margin-bottom: 6px;
  }

  p {
    font-family: var(--btc-font-text);
    font-size: var(--btc-font-size-text);
    color: var(--btc-white);
    line-height: 130%;
  }

  b {
    font-weight: bold;
  }

  .btc-mint__ {
    &bg {
      position: absolute;
      inset: 0;
      z-index: 1;
      backdrop-filter: blur(10px);
    }

    &container {
      position: relative;
      z-index: 2;
      background-color: var(--btc-bg-primary);
      border: 1px solid var(--btc-primary-darker);
      max-width: 680px;
      width: 100%;
      margin: auto;
      border-radius: var(--btc-border-radius);

      & > .--padding {
        padding: 30px;
      }
    }

    &container-wrapper {
      max-height: calc(100vh - 200px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border: 1px solid var(--btc-primary-desaturated);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--btc-primary-desaturated);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: var(--btc-primary);
      }
    }

    &menu {
      position: absolute;
      z-index: 3;
      bottom: calc(100% + 10px);
      right: 10px;
    }
    &btns {
      display: flex;
      gap: 10px;
    }

    &btn {
      border-radius: var(--btc-border-radius);
      border: 1px solid var(--btc-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-color: var(--btc-primary);
      background-color: var(--btc-primary-darker-bg);
      padding: 8px 10px;
      transition: var(--btc-transition);

      &.--secondary {
        color: var(--btc-primary-desaturated);
        border: 1px solid var(--btc-primary-darker);
        background-color: var(--btc-bg-primary);

        svg {
          fill: var(--btc-primary-desaturated);
        }
      }

      &:hover {
        color: var(--btc-primary);
        border-color: var(--btc-primary);
        background-color: var(--btc-primary-darker-bg);

        svg {
          fill: var(--btc-primary);
        }
      }

      svg {
        width: 10px;
        height: 10px;
        fill: var(--btc-primary);
      }

      &.--active {
        border-color: var(--btc-primary);
        background-color: var(--btc-primary-darker-bg);
      }

      &.--disabled {
        border: 1px solid var(--btc-primary-darker);
        background-color: transparent;

        svg {
          fill: var(--btc-primary-desaturated);
        }
      }

      &.--big {
        font-size: var(--btc-font-size-subtitle);
        padding: 15px;
        text-transform: uppercase;
      }

      &.--close {
        display: flex;
        align-items: center;
        gap: 20px;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    &header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      &.--inline {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &copy {
      cursor: pointer;
    }

    &label {
      display: flex;
      padding: 7px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: var(--btc-primary-darker-bg);
      border-radius: var(--btc-border-radius);
      color: var(--btc-primary-desaturated);

      b {
        color: var(--btc-primary);
      }

      &.--light {
        background: var(--btc-primary-darker);
        color: var(--btc-black);

        b {
          color: var(--btc-black);
        }
      }
    }

    &step {
      margin-bottom: 30px;
      &.--disabled {
        opacity: 0.7;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &bordered {
      border: 1px solid var(--btc-primary-darker);
      border-radius: var(--btc-border-radius);
      padding: 20px;

      &.--no-padding {
        padding: 0px;
      }
    }

    &click {
      &:hover,
      &.--active {
        border: 1px solid var(--btc-primary);
        cursor: pointer;
        transition: var(--btc-transition);
        background: var(--btc-primary-darker-bg);
      }
    }

    &rows {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &row {
      display: flex;
      justify-content: space-between;

      &.--center {
        justify-content: center;
      }

      &.--col {
        flex-direction: column;
      }

      &.--align-center {
        align-items: center;
      }

      &.--w-equal > * {
        flex: 1;
      }

      &.--gap {
        gap: 20px;
      }
      &.--gap-s {
        gap: 10px;
      }

      &.--flex-start {
        justify-content: flex-start;
      }
    }

    &ico {
    }

    &input {
      display: flex;
      align-items: center;
      background-color: var(--btc-black);
      border: 1px solid var(--btc-primary-darker);
      border-radius: var(--btc-border-radius);

      h4 {
        padding-left: 15px;
      }

      input {
        border-radius: var(--btc-border-radius) 0 0 var(--btc-border-radius);
        border-width: 0;
      }

      .btc-mint__btn {
        border-radius: 0 var(--btc-border-radius) var(--btc-border-radius) 0;
        padding: 11px 30px 12px;
        width: fit-content;
        white-space: nowrap;
      }

      &.--light {
        background-color: var(--btc-primary-darker);

        input {
          background-color: var(--btc-primary-darker);
        }
      }
    }

    &gallery {
      display: flex;
      gap: 5px;

      &.--wrap {
        flex-wrap: wrap;
      }
    }

    &picture {
      max-width: 200px;
      border-radius: var(--btc-border-radius);
      border: 1px solid var(--btc-primary-desaturated);
      overflow: hidden;

      img {
        width: 100%;
        object-fit: contain;
      }

      iframe {
        width: 100%;
        pointer-events: none;
      }

      .state {
        background: var(--btc-primary-darker-bg);
        color: var(--btc-primary);
        font-size: 10px;
        padding: 5px;
        border-radius: 5px 0 0 5px;
        opacity: 0.5;
        text-align: center;
      }

      &.--more {
        background-color: var(--btc-black);
        font-size: var(--btc-font-size-subtitle);
        color: var(--btc-primary-desaturated);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.--active {
        cursor: pointer;
        transition: var(--btc-transition);
        img {
          transition: var(--btc-transition);
        }

        &:hover {
          border-color: var(--btc-primary);
          img {
            transform: scale(1.05);
          }
        }
      }
      .state {
        border-radius: 0;
        opacity: 0.8;
        background: var(--btc-bg-primary);
        &.--over {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          cursor: pointer;
          &.--no-click {
            cursor: default;
            &:hover {
              background: var(--btc-bg-primary);
            }
          }
        }
        &.--bg {
          background: var(--btc-primary-desaturated);
        }
        &:hover {
          background: var(--btc-primary-darker-bg);
        }
      }
    }

    &bg-image {
    }

    &c-primary {
      color: var(--btc-primary);
    }

    &c-primary-desaturated {
      color: var(--btc-primary-desaturated);
    }

    &c-primary-darker {
      color: var(--btc-primary-darker);
    }

    &c-white {
    }

    &c-secondary {
    }

    &bg-black {
      background-color: var(--btc-black);
    }

    &bg-primary {
      background-color: var(--btc-bg-primary);
    }

    &w-fixed {
      width: 150px;
    }

    &link {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &loader,
    &loader-global {
      svg {
        fill: var(--btc-primary);
        animation: btc-loader 2s infinite linear;
        height: 30px;
        width: 30px;
      }

      &.--small {
        svg {
          height: 10px;
          width: 10px;
        }
      }
      &.--medium {
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }

    &loader-global {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--btc-bg-primary);
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      transition: 0.1s;

      &.show {
        opacity: 1;
        transition: var(--btc-transition);
      }
    }
    &paiement {
      border-bottom: 1px solid var(--btc-primary-darker);
      padding-bottom: 15px;
    }
  }

  input {
    width: 100%;
    background-color: var(--btc-black);
    border: 1px solid var(--btc-primary-darker);
    border-radius: var(--btc-border-radius);
    padding: 10px;
    color: var(--btc-white);
    font-family: var(--btc-font-text);
    font-size: var(--btc-font-size-text);
    outline: none;

    &::placeholder {
      color: var(--btc-white);
    }

    &:focus {
      border-color: var(--btc-primary);
    }

    &.--error {
      border-color: var(--btc-error);
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .--mb {
    margin-bottom: 25px;
  }

  .--mb-s {
    margin-bottom: 15px;
  }
  .--mb-xs {
    margin-bottom: 10px;
  }

  .--mb-g {
    margin-bottom: 50px;
  }

  .--text-center {
    text-align: center;
  }

  .--relative {
    position: relative;
  }

  .--overflow-hidden {
    overflow: hidden;
  }

  .--text-right {
    text-align: right;
  }
}

@keyframes btc-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 710px) {
  :root {
    --btc-font-size-title: 25px;
  }
  #btc-mint {
    .--mb-s {
      margin-bottom: 10px;
    }
    h2 {
      text-align: center;
      line-height: 100%;
    }
    .btc-mint__ {
      &menu {
        padding-bottom: 11px;
        left: -10px;
        right: -10px;
        overflow: auto;
        .btc-mint__btns {
          padding: 0 10px;
          width: fit-content;
        }
        .btc-mint__btn {
          width: fit-content;
          white-space: nowrap;
        }
      }
      &container {
        margin-left: 10px;
        margin-right: 10px;
        > .--padding {
          padding: 30px 10px 10px 10px;
        }
      }
      &container-wrapper {
        max-height: calc(100vh - 130px);
      }
      &header {
        &.--inline {
          flex-direction: column;
        }
      }
      &minting__resume {
      }
      &minting {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
      }
      &bordered {
        padding: 10px;
      }
      &minting__fees {
        flex-direction: column;
      }
      &price-usd {
        display: none;
      }

      &orders {
        .btc-mint__click {
          flex-wrap: wrap;
          gap: 5px;
        }
        .btc-mint__input {
          flex-wrap: wrap;
          background: none;
          border: 0;
          border-radius: 0;
          justify-content: center;
          gap: 10px;

          input {
            background-color: var(--btc-black);
            border: 1px solid var(--btc-primary-darker);
            border-radius: var(--btc-border-radius);
          }

          .btc-mint__btn {
            border-radius: var(--btc-border-radius);
          }
        }
      }
      &step {
        overflow-wrap: break-word;
      }
      &connect {
        padding: 10px;
        h2 {
          text-align: center;
        }
      }
      &gallery {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      &label {
        font-size: 12px;
      }
      &waiting {
        .btc-mint__input {
          .btc-mint__btn {
            display: none;
          }
        }
      }
      &waiting__loader {
        p {
          text-align: center;
        }
      }
      &paiement {
        flex-wrap: wrap;
        padding-bottom: 10px;
      }
      &w-fixed {
        width: auto;
      }
      &info {
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}
</style>
