<script>
import SocialDiscord from '@/components/gallery/social/SocialDiscord.vue';
import SocialTelegram from '@/components/gallery/social/SocialTelegram.vue';
import SocialMagicEden from '@/components/gallery/social/SocialMagicEden.vue';
import SocialTwitter from '@/components/gallery/social/SocialTwitter.vue';
import SocialInstagram from '@/components/gallery/social/SocialInstagram.vue';
import SocialSoundCloud from '@/components/gallery/social/SocialSoundCloud.vue';
import SocialTiktok from '@/components/gallery/social/SocialTiktok.vue';
import SocialThreads from '@/components/gallery/social/SocialThreads.vue';
import SocialWebsite from '@/components/gallery/social/SocialWebsite.vue';
import SocialYoutube from '@/components/gallery/social/SocialYoutube.vue';

export default {
  name: 'CommunityItem',
  components: { SocialTwitter, SocialMagicEden, SocialTelegram, SocialDiscord, SocialInstagram, SocialSoundCloud, SocialTiktok, SocialThreads, SocialWebsite, SocialYoutube },
  props: {
    image: String, //'https://delivery.endangeredlabs.io/download/public/fb/' + tokenId + '.png'
    text: String,
    description: {
      type: String,
      default: '',
      required: false,
    },
    socials: {
      type: Array,
      default: () => [],
      required: false,
    },
    hasHover: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    hasSocialType(type) {
      return this.socials.some((s) => s.key.toLowerCase() === type);
    },
    getSocialType(type) {
      return this.socials.find((s) => s.key.toLowerCase() === type);
    },
  },
};
</script>

<template>
  <div class="item">
    <div class="image">
      <img :src="image" alt="" v-if="image" />
    </div>
    <div class="text">{{ text }}</div>
    <div class="socials tutorial-socials-thumbnail" v-if="socials && socials.length > 0" @click.stop>
      <component :is="'Social' + social.key" :url="social.value" v-for="social in socials" :key="social.key" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.item {
  width: 100%;
  position: relative;
  cursor: pointer;

  .image {
    border: 1px solid #356666;
    overflow: hidden;
    transition: 0.3s;
    border-radius: 8px;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: -6px;
    }
  }

  &:hover {
    .image {
      border-color: #00ffff;
    }
    .over {
      opacity: 1;
      padding-top: 30px;
      pointer-events: all;
    }
    .text {
      color: #00ffff;
    }
  }

  .text {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    background: rgba(2, 8, 8, 0.7);
    font-weight: 700;
    padding: 4px 10px;
    border-radius: 3px;
    transition: 0.5s;
  }

  .socials {
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 5;
    background: rgba(2, 8, 8, 0.7);
    font-weight: 700;
    padding: 4px 10px;
    border-radius: 3px;
    display: flex;
    gap: 10px;
  }
  .over {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    inset: 1px;
    padding: 50px 30px 30px 30px;
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 2;
    overflow: auto;
  }
  .description {
  }
}
</style>
<style lang="scss">
.tutorial-socials-thumbnail {
  svg {
    * {
      fill: #fff;
    }
  }
}
.wysiwyg {
  h1 {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 2rem;
    color: #00ffff;
  }
  h2 {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    color: #00ffff;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    color: #00ffff;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: number;
    padding-left: 20px;
  }
  p {
    margin-bottom: 10px;
  }
}
</style>
