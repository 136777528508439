<script>
import api from '@/components/btc-mint/api';
import TutorialDetail from '@/components/gallery/popup/TutorialDetail.vue';
import TutorialItem from '@/components/gallery/item/TutorialItem.vue';
import CommunityItem from '@/components/gallery/item/CommunityItem.vue';
import CommunityDetail from '@/components/gallery/popup/CommunityDetail.vue';

export default {
  name: 'ContentPage',
  components: { TutorialItem, TutorialDetail, CommunityItem, CommunityDetail },
  props: {
    categoryId: String,
    label: String,
  },
  data: () => ({
    tutorialItems: [],
    page: 1,
    itemsPerPage: 32,
    totalItems: 0,
    totalPages: 0,
    detailItem: null,
    currentTag: null,
    fetchLoading: false,
    tags: [],
  }),
  computed: {
    items() {
      let items = [...this.tutorialItems];
      return items;
    },
    itemPaginated() {
      return this.items.slice(0, this.page * this.itemsPerPage + this.itemsPerPage);
    },
  },
  watch: {
    async categoryId() {
      this.fetchLoading = true;
      this.page = 1;
      this.currentTag = null;
      await this.fetchTags();
      await this.fetchData();
      this.fetchLoading = false;
    },
    async page() {
      await this.fetchData();
    },
    async currentTag() {
      this.resetData();
      this.fetchLoading = true;
      await this.fetchData();
      this.fetchLoading = false;
    },
  },
  async mounted() {
    // const url = "https://miinded-admin-backend.nftprojects.fr/public/paginated/articles/sr24mHG9euBE32JYs6p43s4T4ZmjH3wF?filter.articleType=LEARN"
    this.fetchLoading = true;
    await this.fetchTags();
    await this.fetchData();
    this.fetchLoading = false;

    this.interval = setInterval(() => this.infiniteScrollStart(), 150);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    resetData() {
      this.items = [];
    },
    async fetchTags() {
      // const url = 'http://localhost:5000/public/tags/sr24mHG9euBE32JYs6p43s4T4ZmjH3wF/' + this.categoryId;
      const url = 'https://api.miinded.com/v1/cms/public/tags/1d8fde50-1780-453c-8fcf-6854329df10e/' + this.categoryId;
      const result = await api.get(url);
      if (result.error()) {
        return;
      }
      this.tags = result.data();
    },
    async fetchData() {
      const tags = this.currentTag ? '&filter.tags.name=$in:' + this.currentTag : '';
      // const url = `http://localhost:5000/public/paginated/articles/sr24mHG9euBE32JYs6p43s4T4ZmjH3wF?filter.category.id=${this.categoryId}&page=${this.page}&limit=${this.itemsPerPage}${tags}`;
      const url = `https://api.miinded.com/v1/cms/public/paginated/articles/1d8fde50-1780-453c-8fcf-6854329df10e?filter.category.id=${this.categoryId}&page=${this.page}&limit=${this.itemsPerPage}${tags}`;
      const result = await api.get(url);
      if (result.error()) {
        return;
      }
      const data = result.data();

      this.tutorialItems = data.data;
      this.totalItems = data.meta.totalItems;
      this.totalPages = data.meta.totalPages;
    },
    infiniteScrollStart() {
      if (this.page >= this.totalPages) {
        return;
      }
      const element = document.querySelector('#infinite-scroll');

      const top = element.getBoundingClientRect().top;
      // console.log(top);
      if (top < window.innerHeight + 500) {
        this.page++;
      }
    },
    onOpenDetail(item) {
      this.detailItem = item;
      this.limitBody();
    },
    onCloseDetail() {
      this.detailItem = null;
      this.unlimitBody();
    },
    openPopup(variable) {
      this[variable] = true;
      this.limitBody();
    },
    closePopup(variable) {
      this[variable] = false;
      this.unlimitBody();
    },
    limitBody() {
      document.body.style.overflowY = 'hidden';
    },
    unlimitBody() {
      document.body.style.overflow = '';
    },
    changeTag(tag) {
      if (tag === this.currentTag) {
        this.currentTag = null;
      } else {
        this.currentTag = tag;
      }
    },
    videoYoutubeId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
  },
};
</script>

<template>
  <div class="content">
    <div class="header">
      <div class="menu-mobile" @click.prevent.stop="$parent.openPopup('sidebarOpen')">
        <svg class="ico">
          <use xlink:href="#ico-tutorial"></use>
        </svg>
        {{ label }}
      </div>
    </div>
    <div class="content-page">
      <div class="content-header">
        <div class="title">
          <div class="title-decoration" data-sr-id="15">
            <p><span>Data</span>01001011001</p>
            <p>////////</p>
          </div>
          <h2>{{ label }}</h2>
        </div>
        <div class="filters-barre">
          <div class="search">
            <div class="btn btn-toggle" v-for="tag in tags" :key="tag" @click="changeTag(tag)" :class="{ active: currentTag === tag }">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="items-loading" v-if="fetchLoading">
          <h2 class="title">Loading...</h2>

          <div id="infinite-scroll"></div>
        </div>
        <div class="items" v-else-if="itemPaginated.length > 0">
          <component
            :is="item.articleType === 'VIDEO' ? 'TutorialItem' : 'CommunityItem'"
            v-for="item in itemPaginated"
            :key="item.tokenId"
            v-bind="{
              image: item.media?.url ? item.media?.url : item.articleType === 'VIDEO' ? 'https://img.youtube.com/vi/' + videoYoutubeId(item.contentUrl) + '/maxresdefault.jpg' : '/images/site/thumbnail.jpg',
              text: item.title,
              description: item.content,
              socials: item.socials,
              hasHover: true,
            }"
            @click="onOpenDetail(item)"
          />
          <div id="infinite-scroll"></div>
        </div>
        <div class="items-empty" v-else>
          <h2 class="title">No results</h2>

          <div id="infinite-scroll"></div>
        </div>
      </div>
      <component :is="detailItem.articleType === 'VIDEO' ? 'TutorialDetail' : 'CommunityDetail'" :item="detailItem" v-if="detailItem" @close="onCloseDetail" @closing="unlimitBody" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-page {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 7px 16px;
  }

  .btn-secondary {
    background: #0a1b1b;
    color: #39a0a0;
    border-color: #39a0a0;
    padding: 4px 9px;
    height: 100%;
    font-size: 13px;

    svg {
      width: 10px;

      * {
        fill: #39a0a0;
      }
    }
  }
}

.content-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #020808;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  @media (max-width: 620px) {
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    .title {
      width: 100%;
    }
    .filters-barre {
      width: 100%;
    }
    .search {
      width: 100%;
    }
    .btn {
      width: 100%;
      justify-content: center;

      .ico {
        margin: 0;
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  .search {
    display: flex;
    align-items: center;
    gap: 11px;

    @media (max-width: 620px) {
      flex-wrap: wrap;
      .btn-toggle {
        font-size: 12px;
        padding: 2px 10px;
        width: auto;
      }
    }
  }
}

.filters-barre {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-end;

  .count {
    padding-right: 5px;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0px;
  }
}

.items-empty,
.items-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  .title {
    font-family: 'Itc Bold', serif;
    font-size: 63px;
    color: #004444;
    text-transform: uppercase;
    margin-left: -3px;
    line-height: 50px;
    text-align: center;
    @media (max-width: 520px) {
      font-size: 43px;
    }
  }

  @media (max-width: 520px) {
    .btn {
      padding: 2px 10px;
      font-size: 11px;

      svg {
        width: 8px;
        height: 8px;
      }
    }
  }
}
</style>
