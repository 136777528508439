<template>
  <div class="btc-mint__connect" @click.prevent.stop="close" ref="btc-mint__connect">
    <div class="btc-mint__container-wrapper" ref="btc-mint__container-wrapper">
      <div class="btc-mint__header">
        <h2>{{ $t('connect.connect') }}</h2>
      </div>
      <div class="btc-mint__row --center --mb-s">
        <div class="btc-mint__btn --big" @click="$parent.connectUserWalletSessionXVerse">{{ $t('connect.xverse') }}</div>
      </div>
      <div class="btc-mint__row --center --mb-s">
        <div class="btc-mint__btn --big" @click="$parent.connectUserWalletSessionUnisat">{{ $t('connect.unisat') }}</div>
      </div>
      <div class="btc-mint__row --center --mb-s">
        <div class="btc-mint__btn --big" @click="$parent.connectUserWalletSessionHiro">{{ $t('connect.hiro') }}</div>
      </div>
      <div class="btc-mint__row --center --mb-s">
        <div class="btc-mint__btn --big" @click="$parent.connectUserWalletSessionPhantom">{{ $t('connect.phantom') }}</div>
      </div>
      <div class="btc-mint__row --center --mb-s">
        <div class="btc-mint__btn --big" @click="$parent.connectUserWalletSessionMagicEden">{{ $t('connect.magicEden') }}</div>
      </div>
      <!--      <div class="btc-mint__row &#45;&#45;center &#45;&#45;mb-s" @click="close(null)">-->
      <!--        <div class="btc-mint__link btc-mint__c-primary-desaturated">{{ $t('connect.noconnect') }}</div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
export default {
  name: 'btcConnect',
  data: () => ({
    open: false,
  }),
  mounted() {
    this.animateIn();
  },
  methods: {
    async close($event) {
      if (!$event || ($event && $event.target === this.$refs['btc-mint__connect'])) {
        this.$parent.forceClose();
        await this.animateOut();
        this.$parent.popup = '';
      }
    },
    async animateIn() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          this.$refs['btc-mint__container-wrapper'],
          {
            opacity: 0,
            y: 30,
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .fromTo(
          this.$refs['btc-mint__connect'],
          {
            backdropFilter: 'blur(0px)',
          },
          {
            backdropFilter: 'blur(10px)',
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
    async animateOut() {
      return gsap
        .timeline({ paused: true })
        .fromTo(
          this.$refs['btc-mint__container-wrapper'],
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: 30,
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .fromTo(
          this.$refs['btc-mint__connect'],
          {
            backdropFilter: 'blur(10px)',
          },
          {
            backdropFilter: 'blur(0px)',
            duration: 1,
            ease: 'Power4.easeOut',
          },
          0,
        )
        .restart();
    },
  },
};
</script>

<style scoped lang="scss">
.btc-mint__connect {
  position: absolute;
  inset: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;

  .btc-mint__container-wrapper {
    background: var(--btc-bg-primary);
    border: 1px solid var(--btc-primary-darker);
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
}
</style>
