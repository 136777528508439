<template>
  <div>
    <router-view v-slot="{ Component, route }">
      <transition :name="route.name === 'Home' ? '' : 'route'" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>
