<template>
  <div class="btc-mint__order-completed --padding btc-mint__container-wrapper">
    <BtcLoaderGlobal :show="$parent.loaders.global || $parent.loaders.orders" />
    <div class="btc-mint__header">
      <h2 v-if="!order.isMintCompleted()">{{ $t('orders.completed.orderDetails') }}</h2>
      <h2 v-else>AILU ACQUIRED!</h2>
      <div class="btc-mint__label --light --mb-xs">ID: {{ order.id }}</div>
      <div class="btc-mint__row --gap-s">
        <div class="btc-mint__label --light">x{{ order.nftCount }} Artifact{{ order.nftCount > 1 ? 's' : '' }}</div>
        <div class="btc-mint__label --light">{{ order.vSats }} vsats/vB</div>
      </div>
    </div>
    <p class="btc-mint__c-white --mb" v-if="!order.isMintCompleted()">{{ $t('orders.completed.ordinal.payment') }}</p>
    <p class="btc-mint__c-white --mb" v-else>{{ $t('orders.completed.ordinal.congrats') }}</p>
    <div class="btc-mint__step">
      <div class="btc-mint__c-primary-darker --mb-s">
        <b v-if="!order.isMintCompleted()">{{ $t('orders.completed.ordinal.preview') }}</b>
        <b v-else>{{ $t('orders.completed.ordinal.completed') }}</b>
      </div>
      <div class="btc-mint__gallery">
        <BtcNftPreview v-for="(nft, index) in order.nftsInscriptions" :key="index" :nft="nft" :collection="collection" />
        <div class="btc-mint__picture --more" v-if="order.nftsInscriptions.length > 5">
          <b>+{{ order.nftsInscriptions.length - 5 }}</b>
        </div>
      </div>
    </div>
    <div class="btc-mint__step">
      <div class="btc-mint__btn --primary" v-if="order.isMintCompleted()" @click="shareOnTwitter(order)">
        {{ $t('orders.completed.shareTitle') }}
      </div>
    </div>
    <div class="btc-mint__step">
      <h3>{{ $t('orders.completed.recipientAddress') }}</h3>
      <p class="btc-mint__c-white" v-if="!order.isMintCompleted()">
        {{ $t('orders.completed.receiveAddress') }} <b>{{ order.userOrdAddress }}</b>
      </p>
      <p class="btc-mint__c-white" v-else>
        {{ $t('orders.completed.receivedAddress') }} <b>{{ order.userOrdAddress }}</b>
      </p>
    </div>
    <div class="btc-mint__step">
      <h3>{{ $t('orders.completed.status') }}</h3>
      <p class="btc-mint__c-white" v-if="order.state === 'RECEIVE_ALL_FUNDS' && order.queue.total > 0 && !collectionDisabled">
        We have received all the funds.<br />
        Your position in the queue: {{ order.queue.position }} / {{ order.queue.total }}
        <span class="btc-mint__loader --medium --mb-s">
          <svg class="btc-mint__ico" viewBox="0 0 93 95">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
          </svg>
        </span>
        <br />
        Estimation time: around {{ $parent.formatDuration(order.estimationTime) }} <br />
        Please be patient...
      </p>
      <p class="btc-mint__c-white" v-else-if="order.state === 'RECEIVE_ALL_FUNDS' && (!order.queue.total || collectionDisabled)">
        We have received all the funds.<br />
        All our nodes are busy, waiting for a free node to continue.
        <span class="btc-mint__loader --medium --mb-s">
          <svg class="btc-mint__ico" viewBox="0 0 93 95">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
          </svg>
        </span>
        <br />
        Your ordinals will be inscribe as soon as possible. <br />Please wait...
      </p>
      <p class="btc-mint__c-white" v-else-if="!order.isMintCompleted()">
        {{ $t('orders.completed.mintingProgress') }}: <br />
        {{ $t('orders.completed.minting') }}: {{ nftMintingStat.minting }}
        <span class="btc-mint__loader --medium --mb-s">
          <svg class="btc-mint__ico" viewBox="0 0 93 95">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
          </svg>
        </span>
        <br />
        {{ $t('orders.completed.minted') }}: {{ nftMintingStat.minted }}
      </p>
      <p v-else>{{ $t('orders.completed.all') }}</p>
    </div>
    <div class="btc-mint__step" v-if="order.refunds.length > 0">
      <h3>{{ 'Partial refund' }}</h3>
      <div class="btc-mint__c-white --col --mb" v-for="(refund, index) in order.refunds" :key="index">
        <p class="btc-mint__c-white --mb">
          <template v-if="refund.state !== 'COMPLETED'"> This order will be partially refunded.<br /> </template>
          <template v-else> This order has been partially refunded.<br /> </template>
          Amount: {{ order.totalAmount.toBtc() }} BTC<br />
          Refund address: <a :href="`https://mempool.space/address/${refund.userRefundAddress}`" target="_blank">{{ refund.userRefundAddress }}</a>
          <template v-if="refund.tx">
            <br />
            Tx: <a :href="`https://mempool.space/tx/${refund.tx}`" target="_blank">{{ refund.tx }}</a>
          </template>
        </p>
      </div>
    </div>
    <div class="btc-mint__row --flex-start --gap">
      <div class="btc-mint__btn --primary" v-if="!order.isMintCompleted()" @click="$parent.getOrders(order.userOrdAddress)">{{ $t('orders.completed.refresh') }}</div>
      <div class="btc-mint__btn --primary" @click="$parent.menu = 'orderList'">{{ $t('orders.completed.orders') }}</div>
      <div class="btc-mint__btn --primary" @click="inscribeMore" v-if="!collectionDisabled">{{ $t('orders.completed.more') }}</div>
    </div>
  </div>
</template>

<script>
import { Order } from './order';
import BtcLoaderGlobal from './btcLoaderGlobal';
import BtcNftPreview from './btcNftPreview.vue';

export default {
  name: 'btcOrderCompleted',
  components: { BtcNftPreview, BtcLoaderGlobal },
  props: {
    order: {
      type: Order,
      default: () => new Order(),
    },
  },
  methods: {
    inscribeMore() {
      this.$parent.menu = '';
      this.$parent.currentOrderId = null;
    },
    shareOnTwitter(order) {
      const nfts = [];
      for (const nft of order.nftsInscriptions) {
        nfts.push('AILU #' + nft.itemId);
      }
      const shareText = `I just inscribed ${nfts.join(',')} on bitcoin forever @ailu2100`;

      const encodedUri = encodeURI(shareText).replace(/#/g, '%23');

      const result = `https://twitter.com/intent/tweet?text=${encodedUri}`;

      window.open(result, '_blank').focus();
    },
  },
  computed: {
    collection() {
      return this.$parent.collection;
    },
    collectionDisabled() {
      return this.collection && (this.collection.isSoldOut() || this.collection.isClosed());
    },
    nftMintingStat() {
      return this.order.nftMintingStat();
    },
  },
};
</script>

<style scoped lang="scss"></style>
