<script>
export default {
  name: 'SocialTiktok',
  props: {
    url: String,
  },
};
</script>

<template>
  <a :href="url" target="_blank">
    <svg class="ico" style="margin-right: -3px"><use xlink:href="#ico-tiktok"></use></svg>
  </a>
</template>

<style scoped lang="scss"></style>
