<script>
import SocialDiscord from '@/components/gallery/social/SocialDiscord.vue';
import SocialTelegram from '@/components/gallery/social/SocialTelegram.vue';
import SocialMagicEden from '@/components/gallery/social/SocialMagicEden.vue';
import SocialTwitter from '@/components/gallery/social/SocialTwitter.vue';
import SocialSoundCloud from '@/components/gallery/social/SocialSoundCloud.vue';

export default {
  name: 'GalleryItem',
  components: { SocialSoundCloud, SocialTwitter, SocialMagicEden, SocialTelegram, SocialDiscord },
  props: {
    image: String, //'https://delivery.endangeredlabs.io/download/public/fb/' + tokenId + '.png'
    text: String,
    description: {
      type: String,
      default: '',
      required: false,
    },
    socials: {
      type: Array,
      default: () => [],
      required: false,
    },
    hasHover: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    hasSocialType(type) {
      return this.socials.some((s) => s.type === type);
    },
    getSocialType(type) {
      return this.socials.find((s) => s.type === type);
    },
  },
};
</script>

<template>
  <div class="item">
    <div class="image">
      <img :src="image" alt="" />
    </div>
    <div class="text">{{ text }}</div>
    <div class="over" v-if="hasHover">
      <div class="description">{{ description }}</div>
      <ul class="social" v-if="socials">
        <li class="social-link" v-if="hasSocialType('discord')">
          <SocialDiscord :url="getSocialType('discord').url" />
        </li>
        <li class="social-link" v-if="hasSocialType('telegram')">
          <SocialTelegram :url="getSocialType('telegram').url" />
        </li>
        <li class="social-link" v-if="hasSocialType('magic-eden')">
          <SocialMagicEden :url="getSocialType('magic-eden').url" />
        </li>
        <li class="social-link" v-if="hasSocialType('twitter')">
          <SocialTwitter :url="getSocialType('twitter').url" />
        </li>
        <li class="social-link" v-if="hasSocialType('twitter')">
          <SocialTwitter :url="getSocialType('twitter').url" />
        </li>
        <li class="social-link" v-if="hasSocialType('soundcloud')">
          <SocialSoundCloud :url="getSocialType('soundcloud').url" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item {
  width: 100%;
  position: relative;
  cursor: pointer;

  .image {
    border: 1px solid #356666;
    overflow: hidden;
    transition: 0.3s;
    border-radius: 8px;
    aspect-ratio: 1;

    img {
      width: 100%;
      object-fit: cover;
      margin-bottom: -6px;
    }
  }

  &:hover {
    .image {
      border-color: #00ffff;
    }
  }

  .text {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    background: rgba(2, 8, 8, 0.3);
    font-weight: 700;
    padding: 4px 10px;
    border-radius: 3px;
  }
}
</style>
