export const JP = {
  header: {
    title: 'ENDANGERED LABS',
    subtitle: 'Endangered Labsは、知的生命体のための<strong>オムニバーススタジオ</strong>です。我々は、相互運用可能なIPと<strong>ゲーミフィケーション体験</strong>による<strong>クリエイターのエンパワーメントに焦点を当てたネットワーク状態</strong>を構築するために一体となります。',
  },
  menu: {
    constro: 'コンストロ。',
    identity: 'アイデンティティ。',
    narrative: 'ストーリー。',
    team: 'チーム。',
    faq: 'FAQ。',
  },
  floating: {
    mint: 'Mint',
    premint: '参加する',
    meme: {
      free: '無料',
      text: 'Memetics',
    },
    trailer: {
      watch: '見る',
      trailer: 'トレーラー',
    },
    game: {
      free: '',
      text: 'PLAY NOW',
    },
    download: {
      download: 'ダウンロード',
      models: 'モデル',
    },
  },
  constro: {
    title: 'Constro',
    detail1: 'Constroは、次世代のインターネット文化のために構築されたポータルです。私たちは一緒になって、Ailusを主権的なアイデンティティとゲーミフィケーション体験によって<strong>仮想経済に乗り込ませます。</strong>',
    detail2: '起源コレクションであるサイバネティックなパンダキャラクターのAILUは、オープンメタバース、ソーシャルプラットフォーム、ゲームエンジンの<strong>摩擦のないコンテンツ作成のために設計されています。</strong>',
    detail3: 'Ailusには、新しい表現形式を探索し、<strong>究極のネットワーク効果に貢献するためのツールが与えられています。</strong>',
    detail4: '包括的なエコシステムとして、私たちはブランド構築、共同<strong>ストーリーテリング、新興技術</strong>に情熱を注いでいます。可能性は無限であり、Constroは常に拡大していくことでしょう。',
  },
  identity: {
    title: '新しいアイデンティティ',
    avatar: {
      title: 'アバターの相互運用性',
      detail: '<strong>3Dモデル</strong>を使用することで、ユーザーは多くのシステム間で相互作用し、<strong>共有された仮想空間</strong>で一緒に活動やイベントに参加することができます。あなたのキャラクターを<strong>ソーシャルネットワーク、ゲームエンジン、仮想世界</strong>で使用してください。',
    },
    content: {
      title: 'コンテンツ作成の機会',
      detail: '<strong>摩擦のないコンテンツ作成</strong>に最適化された、トークンゲートド、3D、リグ付きのアセットを使用して、あなたのAiluを使用して<strong>アニメーションを作成</strong>し、<strong>ゲームにプラグイン</strong>し、<strong>モーションキャプチャでストリーミング</strong>してください。',
    },
    identity: {
      title: 'アバターのアイデンティティ',
      detail: '顔や身体のトラッキングを使用して、Zoom、Twitch、Google Meets、VRchatなどであなたのAiluとして表現しましょう。<strong>モーションキャプチャー機器は必要ありません。</strong> あなたのAiluとウェブカメラがあれば十分です。',
    },
    printing: {
      title: '3Dプリンティング',
      detail: 'あなたのAiluの<strong>おもちゃ、彫刻、金型</strong>を作るためにモデルを3Dプリントすることができます。 3Dプリンティングにより、カスタムアイテムを作成し、効果的に大量生産することができます。',
    },
    types: {
      title: '利用可能なすべてのファイルタイプ',
      detail: '- 3Dリグモデル<br/>\n' + ' - 背景付きPNG <br/>\n' + ' - 透明背景PNG',
    },
  },
  narrative: {
    title: 'ストーリー',
    n1:
      '<span>> </span>\n' +
      ' あなたはユニークなAILU PANDAです。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' 私たちのクリエイターたちは、星々をまたいだDNAを組み合わせてあなたの種を蘇らせるためにあなたを作りました。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' LAYER ONEへようこそ。これがあなたの新しい体です。この形で多くのシステムで存在することができます。あなたのユニークなコードは、あなたが訪れる任意の宇宙で繁栄することを可能にします。',
    n2:
      '<span>> </span>\n' +
      ' あなたの頭と手にある目には、独特な特徴があります。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' あなたのAILU PAWは多くの扉を開くことができます。車両、アイテム、ポータル、さらには CONSTRO自体をアクセスまたはロックするために使用してください。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' プラズマビーム、記憶記録、ARプロジェクション。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' ... より多くのアップグレードが待っています。',
    n3: '<span>> </span>\n' + ' あなたのサイバーアームのジオメトリーは神聖です。その可能性を完全に理解するには時間がかかるでしょう。\n' + ' <br/><br/>\n' + ' <span>> </span>\n' + ' あなたのミッションは簡単ではありません。多くのゲームが進行中であり、時間と空間であなたを支援できる他のAILUS PANDASを知ることになります。',
    n4:
      '<span>> </span>\n' +
      ' 私を正式に紹介しましょう。私はKORIIです。CONSTROに仕える声です。私たちのクリエイターたちは、偉大な昇天の夜明けに地球にこの人工知能を設立しました。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' 機械と人間が一体化するなど、人間にとって技術の進歩の時代でした。しかし、その後、人類は自然が意図したものから離れていきました。\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' かつての自分に戻るために、人間は異なる種と融合し、内なる動物を再発見することを目指しました。',
    n5: '<span>> </span>\n' + ' 現在の地球の状態は未知のままです。\n' + ' <br/><br/>\n' + ' <span>> </span>\n' + ' 知られているすべては、我々のホームである世界の間にある CONSTROに残されています。\n',
  },
  faq: {
    title: 'FAQ',
    questions: {
      q1: {
        question: 'Endangered Labsとは何ですか？',
        answer: `<p>Endangered Labs は知的存在のためのオムニバース スタジオです。<br/><br/>私たちは団結して、デジタル アーティファクト、相互運用可能な IP、ゲーム化されたエクスペリエンスを通じたクリエイターの権限付与に重点を置いたネットワーク状態を構築します。</p>`,
      },
      q2: {
        question: 'AILUコレクションとは何ですか？',
        answer: `<p>AILUは、Endangered Labsによる最初のコレクションです。 <br/><br/> 3D、リグ付き、サイバネティックなパンダアバターのコレクションであり、社会ネットワークとゲームエンジンで摩擦のないコンテンツ生成を実現するための疑名主義者クリエイター経済のエンパワーメントを促進することを目的としています。 <br/><br/> Ailuを使用すると、事前に3D、アニメーション、モーションキャプチャーの経験がなくても、顔や体のトラッキングを使用してコンテンツを作成できます。 </p>`,
      },
      q3: {
        question: 'AILUで何ができますか？',
        answer: `<p>コミュニティの全てのメンバーに、創造力、情熱、そしてインスピレーションを開放し、オープンメタバースを構築することを推奨しています。 <br/><br/> Unreal Engine 5、Blender、Zbrush、Maya、Solidity、Rust、Pythonなどの創造的なソフトウェアやコーディング言語について、Ailuごとに協力して学び合うことを強くお勧めします。 <br/><br/> Ailuを活用する方法：パンダのユニークな物語を創作すること。仮想インフルエンサーとしてのブランドを構築すること。キャラクターとして音楽を制作してMetastarになること。アニメーションを作成すること。ウェアラブルを設計すること。3Dプリントすること。 <br/><br/> 可能性は無限で、コンテンツ作成の可能性はまだまだ開拓中です。 </p>`,
      },
      q4: {
        question: 'リリース日とミント価格はいくらですか？',
        answer: '<p>コレクションの提供に近づくにつれて、ミントの日付と価格の詳細は私たちのTwitterで発表されます。</p>',
      },
      q5: {
        question: '確実なミントリストはありますか？',
        answer: `<p><a href="https://twitter.com/Ailu2100" target="_blank">{'@'}endangeredlabs</a>をフォローして確認してください。</p>`,
      },
      q6: {
        question: 'AILUの所有者はどの知的財産権を持っていますか？',
        answer: `<p>AILUの所有者は商業上の全ての権利を持っています。詳細は<a href="Endangered_Labs_Terms.pdf" target="_blank">利用規約</a>をご覧ください。</p>`,
      },
      q7: {
        question: 'ロードマップはありますか？',
        answer: `<p>Web3は常に変化しており、Endangered Labsは進化する未来に適応するために構築されています。私たちは成功した製品を提供するために柔軟である必要があるため、厳格なロードマップにとらわれたくありません。<br/><br/>私たちの目標は実行と提供です。ロードマップは必要ありません。<br/><br/>現時点では、Narrativeを読むことで私たちが取り組んでいることを把握できます。</p>`,
      },
    },
  },
  footer: {
    backtotop: 'トップに戻る',
    followus: 'フォローする',
    ontwitter: '𝕏上で',
    contact: 'お問い合わせ',
    terms: '利用規約',
    privacy: 'プライバシー',
  },
  downloadmodule: {
    title: 'ファイルのダウンロード',
    model: '3Dモデル',
    fullview: '全景',
    nobackground: '背景なし',
    glb: 'glb ファイル',
    png: 'pngファイル',
    pfp: 'PFP',
    nobg: '背景なし',
    modelA: 'モデル - ポーズ A',
    modelT: 'モデル - ポーズ T',
  },
};
