export class Collection {
  id = '';
  name = '';
  price = '';
  active = false;
  nfts = {
    available: 0,
    pending: 0,
    total: 0,
  };
  hasWhitelist = false;
  hasPassword = false;
  parentActive = true;
  estimationTime = 0;
  maxPerWallet = null;
  maxConfirmation = 2;
  vSat = 50;

  constructor(_collection) {
    if (!_collection) {
      return;
    }
    this.id = _collection.id;
    this.name = _collection.name;
    this.price = _collection.price;
    this.active = _collection.active;
    this.nfts = _collection.nfts;
    this.hasWhitelist = _collection.hasWhitelist;
    this.hasPassword = _collection.hasPassword;
    this.parentActive = _collection.hasParent;
    this.estimationTime = _collection.estimationTime;
    this.maxPerWallet = _collection.maxPerWallet;
    this.maxConfirmation = _collection.maxConfirmation ?? 2;
    this.vSat = _collection.vSat;
  }

  isSoldOut() {
    return this.nfts.available === 0 && this.nfts.total > 0;
  }

  isClosed() {
    return !this.active;
  }

  getPriceBtc(count = 1) {
    return ((parseFloat(this.price) / 10 ** 8) * count).toFixed(4);
  }

  getNftTotal() {
    return this.nfts.total;
  }

  getNftPending() {
    return this.nfts.pending;
  }

  getNftMinted() {
    return this.nfts.total - this.nfts.available;
  }

  getNftAvailable() {
    return this.nfts.available;
  }
  hasParent() {
    return this.parentActive;
  }
}
