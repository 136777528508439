export const flexibleGetEnv = (key) => {
  const windowAny = window;

  if (windowAny.windowEnv && windowAny.windowEnv[key]) {
    return windowAny.windowEnv[key];
  }

  return process.env[key];
};

export const envs = {
  NODE_ENV: flexibleGetEnv('NODE_ENV'),
  BACKEND_URI: flexibleGetEnv('VUE_APP_BACKEND_URI'),
  NETWORK_NAME: flexibleGetEnv('VUE_APP_NETWORK_NAME'),
  COLLECTION_ID: flexibleGetEnv('VUE_APP_COLLECTION_ID'),
  CONFIRMATIONS_COUNT: flexibleGetEnv('VUE_APP_CONFIRMATIONS_COUNT'),
};

export default {
  envs: envs,
};
