<script>
import GalleryItem from '@/components/gallery/item/GalleryItem.vue';
import GalleryFilterItem from '@/components/gallery/item/GalleryFilterItem.vue';
import api from '@/components/btc-mint/api';
import GalleryDetail from '@/components/gallery/popup/GalleryDetail.vue';
import ConnectWallet from '@/components/gallery/popup/ConnectWallet.vue';
import GalleryCheckButton from '@/components/gallery/item/GalleryCheckButton.vue';

export default {
  name: 'GalleryPage',
  components: { GalleryCheckButton, ConnectWallet, GalleryDetail, GalleryFilterItem, GalleryItem },
  data: () => ({
    galleryItems: [],
    page: 0,
    itemsPerPage: 32,
    openedFilter: false,
    openedFilterItem: '',
    connectingWallet: false,
    filtersSelected: {},
    tokenId: null,
    myTokenIds: null,
    detailItem: null,
    activePfp: false,
    loading: true,
  }),
  computed: {
    items() {
      let items = [...this.galleryItems];
      if (this.tokenId) {
        items = items.filter((i) => i.tokenId === this.tokenId);
      }
      if (this.myTokenIds !== null) {
        items = items.filter((i) => this.myTokenIds.map((t) => t.tokenId).includes(Number(i.tokenId)));
      }
      return this.filterItems(items, this.filtersSelected);
    },
    itemPaginated() {
      return this.items.slice(0, this.page * this.itemsPerPage + this.itemsPerPage);
    },
    filtersLabels() {
      const filters = {};
      for (const item of this.galleryItems) {
        for (const attr of item.attributes) {
          if (!filters[attr.trait_type]) {
            filters[attr.trait_type] = true;
          }
        }
      }

      const result = [];
      for (const trait_type in filters) {
        result.push(trait_type);
      }

      result.sort();
      return result;
    },
    filters() {
      const filters = {};
      for (const item of this.galleryItems) {
        for (const attr of item.attributes) {
          if (!filters[attr.trait_type]) {
            filters[attr.trait_type] = {};
          }
          if (!filters[attr.trait_type][attr.value]) {
            filters[attr.trait_type][attr.value] = 0;
          }
          filters[attr.trait_type][attr.value]++;
        }
      }

      const result = [];
      for (const trait_type in filters) {
        const options = [];
        for (const value in filters[trait_type]) {
          options.push({ label: value, value, count: filters[trait_type][value] });
        }
        options.sort((o1, o2) => (o1.value > o2.value ? 1 : -1));
        result.push({
          label: trait_type,
          options: options,
        });
      }

      result.sort((r1, r2) => {
        return r1.label > r2.label ? 1 : -1;
      });

      return result;
    },
    selectedFilterArray() {
      const filters = [];
      for (const trait_type in this.filtersSelected) {
        let option = this.filtersSelected[trait_type].length + ' filters';
        if (this.filtersSelected[trait_type].length === 1) {
          option = this.filtersSelected[trait_type][0].label;
        }
        filters.push({
          label: trait_type,
          option: option,
        });
      }
      return filters;
    },
    hasFilters() {
      return Object.keys(this.filtersSelected).length > 0;
    },
  },
  watch: {
    '$route.params.ailu'() {
      const item = this.galleryItems.find((galleryItem) => galleryItem.tokenId === this.$route.params.ailu);
      if (item) {
        this.onOpenDetail(item);
      }
    },
  },
  async mounted() {
    const result = await api.get('https://delivery.endangeredlabs.io/download/public/metadata.json');
    if (result.error()) {
      return;
    }
    const items = result.data();
    const galleryItems = [];
    for (const tokenId in items) {
      const attributes = [];
      for (const attr of items[tokenId].attr) {
        attributes.push({
          trait_type: attr[0],
          value: attr[1],
        });
      }
      galleryItems.push({
        tokenId: tokenId,
        attributes: attributes,
        inscriptionId: items[tokenId].id,
        download: {
          nobg: items[tokenId].nobg + '.png',
          pfp: items[tokenId].pfp + '.png',
          glb: items[tokenId].glb + '.glb',
          vrm: items[tokenId].vrm + '.vrm',
        },
      });
    }
    this.galleryItems = galleryItems;
    this.loading = false;

    const item = this.galleryItems.find((galleryItem) => galleryItem.tokenId === this.$route.params.ailu);
    if (item) {
      this.onOpenDetail(item);
    }

    this.interval = setInterval(() => this.infiniteScrollStart(), 150);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    filterItems(items, filtersSelected) {
      return items.filter((item) => {
        if (!Object.keys(filtersSelected).length) {
          return item;
        }

        let isValid = 0;
        for (const trait_type in filtersSelected) {
          for (const value of filtersSelected[trait_type]) {
            if (item.attributes.some((attr) => attr.trait_type === trait_type && attr.value === value.value)) {
              isValid++;
              break;
            }
          }
        }
        if (isValid === Object.keys(filtersSelected).length) {
          return item;
        }
      });
    },
    getFilterOptions(filterLabel) {
      const options = {};
      const filter = { ...this.filtersSelected };
      delete filter[filterLabel];
      let items = this.filterItems(this.galleryItems, filter);
      if (this.tokenId) {
        items = items.filter((i) => i.tokenId === this.tokenId);
      }
      if (this.myTokenIds !== null) {
        items = items.filter((i) => this.myTokenIds.map((t) => t.tokenId).includes(Number(i.tokenId)));
      }
      for (const item of items) {
        for (const attr of item.attributes) {
          if (attr.trait_type !== filterLabel) {
            continue;
          }
          if (!options[attr.value]) {
            options[attr.value] = 0;
          }
          options[attr.value]++;
        }
      }

      const result = [];
      for (const value in options) {
        result.push({ label: value, value, count: options[value] });
      }

      result.sort((o1, o2) => (o1.value > o2.value ? 1 : -1));

      return result;
    },
    select(filter, option) {
      if (!this.filtersSelected[filter]) {
        this.filtersSelected[filter] = [];
      }

      if (this.filtersSelected[filter].some((o) => o.value === option.value)) {
        this.filtersSelected[filter] = this.filtersSelected[filter].filter((o) => o.value !== option.value);
      } else {
        this.filtersSelected[filter].push(option);
      }
      if (!this.filtersSelected[filter].length) {
        delete this.filtersSelected[filter];
      }
      this.page = 0;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    openFilterItem(filter) {
      if (this.openedFilterItem === filter) {
        this.openedFilterItem = '';
      } else {
        this.openedFilterItem = filter;
      }
      this.connectingWallet = false;
    },
    infiniteScrollStart() {
      if (this.items.length <= this.itemPaginated.length) {
        return;
      }
      const element = document.querySelector('#infinite-scroll');

      const top = element.getBoundingClientRect().top;
      // console.log(top);
      if (top < window.innerHeight + 500) {
        this.page++;
      }
    },
    removeFilters(label) {
      delete this.filtersSelected[label];
    },
    onCloseFilter() {
      this.openedFilterItem = '';
      this.connectingWallet = false;
      this.closePopup('openedFilter');
    },
    onOpenDetail(item) {
      this.detailItem = item;
      this.limitBody();
    },
    onCloseDetail() {
      this.detailItem = null;
      this.$router.push('/hub');
      this.unlimitBody();
    },
    openPopup(variable) {
      this[variable] = true;
      this.limitBody();
    },
    closePopup(variable) {
      this[variable] = false;
      this.unlimitBody();
    },
    limitBody() {
      document.body.style.overflowY = 'hidden';
    },
    unlimitBody() {
      document.body.style.overflow = '';
    },
    clearSearch() {
      this.tokenId = '';
    },
    clearFilters() {
      this.filtersSelected = {};
    },
    clearMyAilus() {
      this.myTokenIds = null;
    },
    onFetchMuToken(tokenIds) {
      this.myTokenIds = tokenIds;
    },
    onOpenConnexion() {
      this.connectingWallet = true;
      this.openedFilterItem = '';
    },
    onResetClickFilter() {
      this.openedFilterItem = '';
      this.connectingWallet = false;
    },
  },
};
</script>

<template>
  <div class="content">
    <div class="header">
      <div class="menu-mobile" @click.prevent.stop="$parent.openPopup('sidebarOpen')">
        <svg class="ico">
          <use xlink:href="#ico-gallery"></use>
        </svg>
        Gallery
      </div>
    </div>
    <div class="content-page">
      <div class="content-header">
        <div class="title">
          <div class="title-decoration" data-sr-id="15">
            <p><span>Data</span>01001011001</p>
            <p>////////</p>
          </div>
          <h2>GALLERY</h2>
        </div>
        <div class="filters-barre">
          <div class="search">
            <div v-if="hasFilters || tokenId" class="filters-selection">
              <!--            <div class="btn btn-secondary" v-for="filters of selectedFilterArray" :key="filters.label" @click="removeFilters(filters.label)">-->
              <!--              {{ filters.label }}: {{ filters.option }}-->
              <!--              <svg class="ico"><use xlink:href="#ico-cross"></use></svg>-->
              <!--            </div>-->
              <div class="btn btn-secondary" @click="clearSearch" v-if="tokenId">
                Token ID: {{ tokenId }}
                <svg class="ico" style="margin: 0">
                  <use xlink:href="#ico-cross"></use>
                </svg>
              </div>
              <div class="btn btn-secondary" @click="clearFilters" v-if="hasFilters">
                Filters
                <svg class="ico" style="margin: 0">
                  <use xlink:href="#ico-cross"></use>
                </svg>
              </div>
            </div>
            <div class="filters-search">
              <input type="text" name="tokenId" placeholder="Token ID" v-model="tokenId" class="search-input" />
              <div class="btn" @click="openPopup('openedFilter')">
                <svg class="ico">
                  <use xlink:href="#ico-filter"></use>
                </svg>
                Filters
              </div>
              <div class="connect-wallet-barre">
                <GalleryCheckButton :active="activePfp" :label="'PFP'" @click.prevent.stop="activePfp = !activePfp" />
              </div>
              <ConnectWallet :my-token-ids="myTokenIds" :connecting-wallet="connectingWallet" @fetch="onFetchMuToken" @close-filter="onCloseFilter" @open-connection="onOpenConnexion" @close-connection="connectingWallet = false" class="connect-wallet-barre" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="items-empty" v-if="loading">
          <h2 class="title">Loading...</h2>

          <div id="infinite-scroll"></div>
        </div>
        <div class="items" v-else-if="itemPaginated.length > 0">
          <GalleryItem
            v-for="item in itemPaginated"
            :key="item.tokenId"
            v-bind="{
              image: 'https://delivery.endangeredlabs.io/download/public/' + (activePfp ? 'pfp/pfp_' : 'fb/') + '' + item.tokenId + '.webp',
              text: '#' + item.tokenId,
            }"
            @click="$router.push('/hub/' + item.tokenId)"
          />
          <div id="infinite-scroll"></div>
        </div>
        <div class="items-empty" v-else>
          <h2 class="title">No results</h2>

          <div class="filters-selection">
            <div class="btn btn-secondary" @click="clearFilters" v-if="hasFilters">
              Clear filters
              <svg class="ico" style="margin: 0">
                <use xlink:href="#ico-cross"></use>
              </svg>
            </div>
            <div class="btn btn-secondary" @click="clearSearch" v-if="tokenId">
              Clear Token ID: {{ tokenId }}
              <svg class="ico" style="margin: 0">
                <use xlink:href="#ico-cross"></use>
              </svg>
            </div>
          </div>
          <div id="infinite-scroll"></div>
        </div>

        <div class="filters scroll-style" :class="{ open: openedFilter }" @click.prevent.stop.exact="onCloseFilter">
          <div class="filters-popup" @click.prevent.stop="onResetClickFilter">
            <div class="filters-header">
              <div class="row">
                <h2>Filters</h2>
                <div class="btn btn-secondary" @click.prevent.stop="onCloseFilter">
                  Close
                  <svg class="ico">
                    <use xlink:href="#ico-cross"></use>
                  </svg>
                </div>
              </div>
              <div class="count">{{ items.length }} result{{ items.length > 1 ? 's' : '' }}</div>
            </div>
            <div class="filters-content">
              <div class="connect-wallet-filter">
                <GalleryCheckButton :active="activePfp" :label="'PFP'" @click.prevent.stop="activePfp = !activePfp" />
              </div>
              <ConnectWallet :my-token-ids="myTokenIds" :connecting-wallet="connectingWallet" @fetch="onFetchMuToken" @close-filter="onCloseFilter" @open-connection="onOpenConnexion" @close-connection="connectingWallet = false" class="connect-wallet-filter" />
              <div class="search-mobile">
                <input type="text" name="tokenId" placeholder="Token ID" v-model="tokenId" class="search-input" />
              </div>

              <GalleryFilterItem v-for="(filterLabel, index) in filtersLabels" :selected="filtersSelected[filterLabel]" :key="index" :options="getFilterOptions(filterLabel)" :label="filterLabel" @select="(option) => select(filterLabel, option)" @open="openFilterItem(filterLabel)" :is-open="openedFilterItem === filterLabel" />
            </div>
          </div>
        </div>

        <GalleryDetail :item="detailItem" v-if="detailItem" @close="onCloseDetail" @closing="unlimitBody" :my-token-ids="myTokenIds" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-page {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 7px 16px;
  }

  .btn-secondary {
    background: #0a1b1b;
    color: #39a0a0;
    border-color: #39a0a0;
    padding: 4px 9px;
    height: 100%;
    font-size: 13px;

    svg {
      width: 10px;

      * {
        fill: #39a0a0;
      }
    }
  }
}

.content-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #020808;

  @media (max-width: 620px) {
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    .title {
      width: 100%;
    }
    .filters-barre {
      width: 100%;
    }
    .search {
      width: 100%;
    }
    .btn {
      width: 100%;
      justify-content: center;

      .ico {
        margin: 0;
      }
    }
  }

  .title {
    margin-bottom: 0;
  }

  .search {
    display: flex;
    align-items: center;
    gap: 11px;

    .btn-secondary {
      padding: 7px 9px;
    }

    @media (max-width: 1450px) {
      flex-direction: column;
      align-items: flex-end;
      .filters-selection {
        order: 2;
      }
    }
    @media (max-width: 900px) {
      .search-input {
        display: none;
      }
    }
    @media (max-width: 700px) {
      .filters-selection {
        width: 100%;

        .btn {
          padding: 2px 10px;
          font-size: 11px;

          svg {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    @media (max-width: 350px) {
      .filters-selection {
        flex-wrap: wrap;
      }
    }

    .filters-search {
      display: flex;
      gap: 10px;

      @media (max-width: 1300px) {
        .search-input {
          width: 100px;
        }
      }
      @media (max-width: 1100px) {
        .search-input {
          width: 200px;
        }
      }
      @media (max-width: 620px) {
        width: 100%;
      }
    }

    .connect-wallet-barre {
      display: flex;
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
}

.search-mobile {
  display: none;
  @media (max-width: 900px) {
    display: block;
    margin-bottom: 20px;
    .search-input {
      width: 100%;
    }
  }
}

input.search-input {
  font-size: 14px;
  background: #071010;
  border: 1px solid #356666;
  border-radius: 3px;
  padding: 12px;
  color: #fff;

  &::placeholder {
    color: #fff;
  }
}

.filters {
  position: fixed;
  inset: 0 0 0 0;
  transition: 0.5s;
  pointer-events: none;
  z-index: 15;
  backdrop-filter: blur(0);

  .filters-popup {
    position: absolute;
    inset: 0 0 0 auto;
    width: 380px;
    background: #0a1b1b;
    border-left: 1px solid #356666;
    overflow: auto;
    padding: 0 40px 40px 40px;
    transition: 0.5s;
    transform: translateX(380px);

    @media (max-width: 520px) {
      padding: 0 20px 20px 20px;
      max-width: 100vw;
      border-left: none;
      width: 100%;
      transform: translateX(100%);
    }
  }

  &.open {
    pointer-events: auto;
    backdrop-filter: blur(10px);

    @media (max-width: 520px) {
      background: #0a1b1b;
    }

    .filters-popup {
      transform: translateX(0);
    }
  }

  .filters-header {
    background: #0a1b1b;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #356666;
    padding-bottom: 20px;
    margin-bottom: 20px;
    z-index: 50;
    padding-top: 20px;

    @media (max-width: 520px) {
      padding-top: 10px;
    }

    .row {
      align-items: center;
    }

    h2 {
      font-size: 24px;
    }

    .count {
    }
  }

  .connect-wallet-filter {
    display: none;
    @media (max-width: 1200px) {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.filters-selection {
  display: flex;
  gap: 10px;

  .btn {
    font-size: 14px;
  }
}

.filters-barre {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-end;

  .count {
    padding-right: 5px;
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.items-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  .title {
    font-family: 'Itc Bold', serif;
    font-size: 63px;
    color: #004444;
    text-transform: uppercase;
    margin-left: -3px;
    line-height: 50px;
    text-align: center;
    @media (max-width: 520px) {
      font-size: 43px;
    }
  }

  @media (max-width: 520px) {
    .btn {
      padding: 2px 10px;
      font-size: 11px;

      svg {
        width: 8px;
        height: 8px;
      }
    }
  }
}
</style>
