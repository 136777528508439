import allAssets from '../characters/all-assets';

export default {
  assets: null,
  composition: null,
  baseComposition: null,

  assetToComposition(asset) {
    const type = asset.type;

    for (const clan in allAssets[type]) {
      for (const gender in allAssets[type][clan]) {
        for (const layer in allAssets[type][clan][gender]) {
          for (const name in allAssets[type][clan][gender][layer]) {
            if (asset.layer === layer && name === asset.name) {
              return {
                clan,
                gender,
                [type]: [
                  {
                    layer,
                    name,
                  },
                ],
              };
            }
          }
        }
      }
    }
  },

  setComposition(composition) {
    this.composition = composition;

    const clan = composition.clan;
    const gender = composition.gender;
    let assets = [];

    // gestion des assets de bases
    for (const asset of composition.base) {
      const base = allAssets.base[clan][gender][asset.layer][asset.name];
      assets.push({ ...asset, ...base });
    }

    // gestion des accessoires
    for (const asset of composition.accessories) {
      const base = allAssets.accessories[asset.layer][asset.name];
      assets.push({ ...asset, ...base });
    }

    // gestion des dépendances entre les assets de base
    for (const asset of composition.base) {
      const base = allAssets.base[clan][gender][asset.layer][asset.name];
      if (!base.dependencies) {
        continue;
      }
      if (base.dependencies.base) {
        for (const dependency of base.dependencies.base) {
          assets = assets.filter((a) => a.layer !== dependency.layer);
          const base = allAssets.base[clan][gender][dependency.layer][dependency.name];
          assets.push({ ...dependency, ...base });
        }
      }
      if (base.dependencies.accessories) {
        for (const dependency of base.dependencies.accessories) {
          assets = assets.filter((a) => a.layer !== dependency.layer);
          const base = allAssets.accessories[dependency.layer][dependency.name];
          assets.push({ ...dependency, ...base });
        }
      }
    }

    // gestion des dépendances entre les assets accessoires
    for (const asset of composition.accessories) {
      const base = allAssets.accessories[asset.layer][asset.name];

      if (!base.dependencies) {
        continue;
      }
      if (base.dependencies.base) {
        for (const dependency of base.dependencies.base) {
          assets = assets.filter((a) => a.layer !== dependency.layer);
          const base = allAssets.base[clan][gender][dependency.layer][dependency.name];
          assets.push({ ...dependency, ...base });
        }
      }
      if (base.dependencies.accessories) {
        for (const dependency of base.dependencies.accessories) {
          assets = assets.filter((a) => a.layer !== dependency.layer);
          const base = allAssets.accessories[dependency.layer][dependency.name];
          assets.push({ ...dependency, ...base });
        }
      }
    }

    assets.sort((a1) => {
      return a1.layer === 'Body' ? -1 : 1;
    });

    this.assets = assets;
  },

  hasCompositionAsset(asset) {
    return this.composition[asset.type].find((a) => a.layer === asset.layer && a.name === asset.name) !== undefined;
  },

  addCompositionAsset(asset) {
    this.composition[asset.type] = this.composition[asset.type].filter((a) => a.layer !== asset.layer);
    this.composition[asset.type].push(asset);

    this.setComposition(this.composition);
  },

  removeCompositionAsset(asset) {
    const compo = this.composition;
    compo[asset.type] = compo[asset.type].filter((a) => a.layer !== asset.layer && a.name !== asset.name);
    this.setComposition(compo);
  },

  getImageFromGlb(asset) {
    const clan = this.composition.clan;
    const gender = this.composition.gender;

    let file = '';
    if (asset.type === 'base') {
      file = allAssets.base[clan][gender][asset.layer][asset.name].file;
    } else {
      file = allAssets.accessories[asset.layer][asset.name].file;
    }
    return file.replace('.glb', '.png');
  },

  resetCompositionAsset() {
    this.setComposition(JSON.parse(JSON.stringify(this.baseComposition)));
  },
};
