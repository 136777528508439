export class BtcValue {
  satoshi = BigInt(0);

  constructor(_valueSatoshi) {
    this.satoshi = BigInt(_valueSatoshi);
  }

  convert(value) {
    return parseFloat(value.toString()) / 10 ** 8;
  }

  toBtc() {
    return this.convert(this.satoshi);
  }
  toUsd(btcPrice) {
    return (this.toBtc() * btcPrice).toFixed(2);
  }
  equal(value) {
    return this.satoshi === BigInt(value);
  }
  notEqual(value) {
    return this.satoshi !== BigInt(value);
  }
}
