import Vue, { createApp } from 'vue';
import App from './App.vue';
import '@/assets/scss/app.scss';
import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import config from './config';
import { createI18n } from 'vue-i18n';
import { FR } from '@/assets/translations/fr';
import { EN } from '@/assets/translations/en';
import { ES } from '@/assets/translations/es';
import { CH } from '@/assets/translations/ch';
import { JP } from '@/assets/translations/jp';
import mitt from 'mitt';

const devtools = {
  install(app) {
    // @ts-ignore
    if (process.env.NODE_ENV === 'development' && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
      // @ts-ignore
      window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
    }
  },
};

const router = createRouter({
  history: createWebHashHistory(),
  base: '/',
  fallback: true,
  routes,
});

const i18n = createI18n({
  locale: 'EN', // set locale
  fallbackLocale: 'EN', // set fallback locale
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
  warnHtmlInMessage: 'off',
  messages: {
    EN: EN,
    FR: FR,
    ES: ES,
    CH: CH,
    JP: JP,
  },
});

const app = createApp(App);
app.use(devtools);
app.use(router);
app.use(i18n);

app.config.globalProperties.$config = config;

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.mount('#vue');
