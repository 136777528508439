import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import Composition from './Composition';
import SceneGUI from './SceneGUI';
import Animations from './Animations';

let config;

export class Scene {
  constructor() {
    this.guiActive = false;

    this.scene = null;
    this.camera = null;

    this.environment = null;
    this.assetsLoaded = [];
    this.sceneComposition = [];
    this.lightComposition = [];
    this.renderCallback = [];

    this.alphaLoaderBackground = 0;
  }

  async toggleLoader(toggle) {
    if (!toggle) {
      this.renderCallback = this.renderCallback.filter((render) => render.callback !== Animations.animateLoader);

      this.alphaLoaderBackground = 0;
      this.renderCallback.push({
        callback: Animations.animateLoaderLight,
        params: {
          lights: this.lightComposition,
          scene: this.scene,
          assetsLoaded: this.assetsLoaded,
          render: this,
        },
      });

      this.$vue.loadingFinish = true;

      this.$vue.assetLoaderLoaded = false;
      // this.removeAsset(assetLoader)

      return new Promise((resolve) => {
        setTimeout(() => {
          this.$vue.loading = false;
          this.$vue.loadingFinish = false;

          resolve();
        }, 1000);
      });
    }

    this.$vue.loadingFinish = false;
    this.$vue.loading = true;

    // on affiche la cannette pour le loader
    // const object = await this.loadAsset(assetLoader, true)
    // object.scene.position.x = 0.1

    this.$vue.loadingStepPct = 0;
    this.$vue.assetLoaderLoaded = true;
    // this.scene.background = new THREE.Color(0x333333);

    this.renderCallback.push({ callback: Animations.animateLoader });

    for (const composition of this.lightComposition) {
      composition.light.intensity = 0;
    }
  }

  async reloadAllAssets() {
    this.$vue.canvasReady = false;

    await this.toggleLoader(true);

    this.$vue.loadingStep = 0;

    for (const asset of this.assetsLoaded) {
      if (asset.asset.layer === 'Loader') continue;
      this.removeAsset(asset.asset.layer);
    }

    for (const asset of Composition.assets) {
      await this.loadAsset(asset, false);

      this.$vue.loadingStepPct = 0;
      this.$vue.loadingStep++;
    }

    // on supprime les assets en trop
    const removeLayer = [];
    for (const asset of this.assetsLoaded) {
      if (!Composition.assets.find((a) => a.layer === asset.asset.layer && a.name === asset.asset.name)) {
        removeLayer.push(asset.asset.layer);
      }
    }
    for (const layer of removeLayer) {
      this.removeAsset(layer);
    }

    await this.toggleLoader(false);

    this.$vue.canvasReady = true;
  }

  async loadAsset(asset) {
    return new Promise((resolve) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/3dmodel/decoder/');
      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);
      loader.load(
        '/3dmodel/' + asset.file,
        (gltf) => {
          if (asset.log) {
            console.log(gltf);
          }

          // if (asset.material) {
          //     for (const material of asset.material) {
          //         gltf.scene.children[0].children[material.children].material = new THREE[material.shader]({
          //             ...material.params,
          //             envMap: this.environment,
          //             color: new THREE.Color(parseInt(material.color, 16))
          //         })
          //     }
          // }

          const meshColor = {};
          gltf.scene.traverse((n) => {
            if (n.isMesh) {
              n.castShadow = true;
              n.receiveShadow = true;
              n.material.envMap = this.environment;
              if (n.material.map) {
                n.material.map.anisotropy = 16;
              }
              if ((n.name === 'TubesGEO1' || n.name === 'CyberDetailsGEO1') && asset.file === 'Meta_Mastermind_Pose_Reduced-v2.glb') {
                // n.material.emissive = new THREE.Color(parseInt("0x00AEFF", 16))
                // n.material.emissiveIntensity = 1
                n.material.color = new THREE.Color(parseInt('0x00AEFF', 16));
              }
              // if (!visibility) {
              //     meshColor[n.uuid] = n.material.color.getHex()
              //     const color = new THREE.Color(parseInt("0x000000", 16)).lerp(meshColor[n.uuid], 1);
              //     n.material.color.set(color);
              // }
            }
          });

          this.scene.add(gltf.scene);

          this.assetsLoaded.push({ asset: asset, object: gltf, meshColor });

          resolve(gltf);
        },
        (progress) => {
          this.$vue.loadingStepPct = progress.loaded / progress.total;
        },
      );
    });
  }

  removeAsset(layer) {
    const assetObject = this.assetsLoaded.find((asset) => asset.asset.layer === layer);
    if (assetObject) {
      this.scene.remove(assetObject.object.scene);
    }
    this.assetsLoaded = this.assetsLoaded.filter((asset) => asset.asset.layer !== layer);
  }

  //gestion de la scene
  addHemiLight(hemiLightConfig) {
    const hemiLight = new THREE.HemisphereLight(parseInt(hemiLightConfig.skyColor, 16), parseInt(hemiLightConfig.groundColor, 16), hemiLightConfig.intensity);

    if (this.guiActive) {
      SceneGUI.addHemisphere(hemiLight);
    }

    this.scene.add(hemiLight);
    this.sceneComposition.push(hemiLight);
    if (hemiLightConfig.animated) {
      this.lightComposition.push({ light: hemiLight, config: hemiLightConfig });
    }
  }

  addSpotLight(spotLightConfig) {
    const spotLight = new THREE.DirectionalLight(parseInt(spotLightConfig.color, 16), spotLightConfig.intensity);

    spotLight.name = spotLightConfig.name;
    spotLight.castShadow = spotLightConfig.shadow.cast;
    spotLight.shadow.bias = spotLightConfig.shadow.bias;
    spotLight.shadow.mapSize.width = spotLightConfig.shadow.mapSize.width;
    spotLight.shadow.mapSize.height = spotLightConfig.shadow.mapSize.height;

    // spotLight.shadow.camera.near = 1;
    // spotLight.shadow.camera.far = 4;
    // const d = 1;
    // spotLight.shadow.camera.left = - d;
    // spotLight.shadow.camera.right = d;
    // spotLight.shadow.camera.top = d;
    // spotLight.shadow.camera.bottom = - d;

    spotLight.position.set(spotLightConfig.position.x, spotLightConfig.position.y, spotLightConfig.position.z);

    if (this.guiActive) {
      SceneGUI.addSpotLight(spotLight, spotLightConfig);
    }

    this.scene.add(spotLight);

    if (spotLightConfig.cameraLinked) {
      this.camera.add(spotLight);
    }
    this.sceneComposition.push(spotLight);
    if (spotLightConfig.animated) {
      this.lightComposition.push({ light: spotLight, config: spotLightConfig });
    }
  }

  addPointLight(pointLightConfig) {
    const pointLight = new THREE.PointLight(parseInt(pointLightConfig.color, 16), pointLightConfig.intensity, pointLightConfig.distance);

    pointLight.name = pointLightConfig.name;
    pointLight.castShadow = pointLightConfig.shadow.cast;
    pointLight.shadow.bias = pointLightConfig.shadow.bias;
    pointLight.shadow.mapSize.width = pointLightConfig.shadow.mapSize.width;
    pointLight.shadow.mapSize.height = pointLightConfig.shadow.mapSize.height;

    // spotLight.shadow.camera.near = 1;
    // spotLight.shadow.camera.far = 4;
    // const d = 1;
    // spotLight.shadow.camera.left = - d;
    // spotLight.shadow.camera.right = d;
    // spotLight.shadow.camera.top = d;
    // spotLight.shadow.camera.bottom = - d;

    pointLight.position.set(pointLightConfig.position.x, pointLightConfig.position.y, pointLightConfig.position.z);

    if (this.guiActive) {
      SceneGUI.addPointLight(pointLight, pointLightConfig);
    }

    this.scene.add(pointLight);

    if (pointLightConfig.cameraLinked) {
      this.camera.add(pointLight);
    }
    this.sceneComposition.push(pointLight);
    if (pointLightConfig.animated) {
      this.lightComposition.push({ light: pointLight, config: pointLightConfig });
    }
  }

  async addEnvironmentMap() {
    return new Promise((resolve) => {
      new RGBELoader().load(config.scene.environment, (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;

        this.environment = texture;
        // this.scene.background = texture;
        resolve();
        // this.scene.background = envmap.texture;
      });
    });
  }

  /*
  options:{
      stats:false,
  }
   */
  async initialisation($vue, assets3d) {
    this.$vue = $vue;

    Composition.baseComposition = JSON.parse(JSON.stringify(assets3d));
    Composition.setComposition(assets3d);

    config = assets3d.settings;

    this.createScene();

    // camera
    this.createCamera();

    // render
    this.createRenderer();

    // orbit
    this.createOrbit();

    if (this.guiActive) {
      SceneGUI.init(config);
      SceneGUI.addScene(this.scene);
      SceneGUI.addCameraOrbit(this.camera, this.orbit);
    }

    // add HemiLight
    this.addHemiLight(config.hemiLight);

    // add SpotLight
    for (const spotLightConfig of config.spotLight) {
      this.addSpotLight(spotLightConfig);
    }

    // add PointLight
    for (const pointLightConfig of config.pointLights) {
      this.addPointLight(pointLightConfig);
    }

    await this.addEnvironmentMap();
    // Lancement du render
    const animate = () => {
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.render(this.scene, this.camera);

      for (const render of this.renderCallback) {
        if (!render.callback(render.params)) {
          this.renderCallback = this.renderCallback.filter((r) => r !== render);
        }
      }

      this.requestAnimationFrame = window.requestAnimationFrame(animate);
    };

    animate();

    // chargement des assets
    await this.reloadAllAssets();
  }

  stopRenderer() {
    window.cancelAnimationFrame(this.requestAnimationFrame);
    this.renderer.domElement?.remove();
  }

  createScene() {
    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(parseInt(config.scene.background, 16));
  }

  createCamera() {
    this.camera = new THREE.PerspectiveCamera(30, config.scene.width / config.scene.height, 0.1, 1000);
    this.camera.position.x = config.camera.position.x;
    this.camera.position.y = config.camera.position.y;
    this.camera.position.z = config.camera.position.z;
    this.scene.add(this.camera);
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      precision: 'highp',
      antialias: true,
      alpha: true,
    });

    this.renderer.setSize(config.scene.width, config.scene.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = 2.3;
    this.renderer.shadowMap.enabled = true;

    document.getElementById('model').appendChild(this.renderer.domElement);
  }

  createOrbit() {
    this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbit.autoRotate = config.orbit.autoRotate;
    this.orbit.minPolarAngle = config.orbit.minPolarAngle;
    this.orbit.maxPolarAngle = config.orbit.maxPolarAngle;
    this.orbit.enableZoom = config.orbit.enableZoom;
    this.orbit.enablePan = config.orbit.enablePan;
    this.orbit.target = new THREE.Vector3(config.camera.lookAt.x, config.camera.lookAt.y, config.camera.lookAt.z);
    this.camera.lookAt(config.camera.lookAt.x, config.camera.lookAt.y, config.camera.lookAt.z);
  }
}
