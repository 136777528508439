<template>
  <div class="btc-mint__order-completed --padding btc-mint__container-wrapper">
    <BtcLoaderGlobal :show="$parent.loaders.global || $parent.loaders.orders" />
    <div class="btc-mint__header">
      <h2>{{ $t('myinscriptions.title') }}</h2>
    </div>
    <div class="btc-mint__step">
      <div class="btc-mint__gallery --wrap">
        <BtcNftPreview v-for="(nft, index) in nftInscriptions" :key="index" :nft="nft" :collection="collection" />
      </div>
      <div v-if="nftInscriptions.length <= 0">{{ $t('myinscriptions.noArtifacts') }}</div>
    </div>
    <div class="btc-mint__row --flex-start --gap">
      <div class="btc-mint__btn --primary" v-if="orders.length > 0" @click="$parent.getOrders(orders[0].userOrdAddress)">{{ $t('myinscriptions.refresh') }}</div>
      <!--      <div class="btc-mint__btn &#45;&#45;primary" @click="$parent.menu = 'orderList'">{{ $t('myinscriptions.orders') }}</div>-->
      <!--      <div class="btc-mint__btn &#45;&#45;primary" @click="inscribeMore" v-if="!collectionDisabled">{{ $t('myinscriptions.more') }}</div>-->
    </div>
  </div>
</template>

<script>
import BtcLoaderGlobal from './btcLoaderGlobal';
import BtcNftPreview from './btcNftPreview.vue';

export default {
  name: 'btcMyInscriptions',
  components: { BtcNftPreview, BtcLoaderGlobal },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    inscribeMore() {
      this.$parent.menu = '';
      this.$parent.currentOrderId = null;
    },
    openNft(nft) {
      // window.open('https://www.ord.io/' + nft.inscriptionId);
      window.open('https://ordinals.com/inscription/' + nft.inscriptionId);
    },
  },
  computed: {
    collection() {
      return this.$parent.collection;
    },
    collectionDisabled() {
      return this.collection && (this.collection.isSoldOut() || this.collection.isClosed());
    },
    nftInscriptions() {
      let nfts = [];
      for (const order of this.orders) {
        nfts = [...nfts, ...order.nftsInscriptions];
      }
      return nfts;
    },
  },
};
</script>

<style scoped lang="scss"></style>
