<script>
export default {
  name: 'GalleryMenuItem',
  props: {
    ico: String,
    icoImg: {
      type: String,
      default: '',
    },
    label: String,
    active: Boolean,
    soon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="button" :class="{ active, soon }">
    <svg class="ico" v-if="ico && !icoImg"><use :xlink:href="'#' + ico"></use></svg>
    <img :src="icoImg" class="ico-img" alt="" v-if="icoImg" />
    {{ label }}
    <span v-if="soon" class="soon"> SOON</span>
  </div>
</template>

<style scoped lang="scss">
.button {
  //padding: 16px 24px;
  padding: 16px 11px;
  display: flex;
  //gap: 12px;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0 auto 0 0;
    width: 0px;
    background: #bbeb54;
    transition: 0.3s;
  }

  &.active,
  &:hover {
    background: #356666;
  }

  &.active {
    &:before {
      content: '';
      position: absolute;
      inset: 0 auto 0 0;
      width: 5px;
      background: #bbeb54;
    }
  }

  .ico {
    width: 19px;
    height: 12px;
    margin: 0;
  }

  .ico-img {
    width: 25px;
    height: 25px;
    margin: 0;
  }

  &.soon {
    cursor: not-allowed;
  }

  & > .soon {
    display: block;
    margin-left: auto;
    font-size: 14px;
    background: #356666;
    padding: 0 10px;
    border-radius: 3px;
  }
}
</style>
