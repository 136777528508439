<script>
export default {
  name: 'GalleryCheckButton',
  props: {
    active: Boolean,
    label: String,
  },
};
</script>

<template>
  <div class="btn btn-check" :class="{ active }">
    <div class="checkbox" :class="{ active }">
      <svg class="ico" v-if="active"><use xlink:href="#ico-cross"></use></svg>
    </div>
    {{ label }}
  </div>
</template>

<style scoped lang="scss">
.btn.btn-check {
  padding: 8px 16px;
  border: 1px solid #356666;
  display: flex;
  justify-content: flex-start !important;
  &.active {
    border: 1px solid #00ffff;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    background: #071010;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    svg {
      width: 10px;
      height: 10px;
      fill: #00ffff;
    }
  }
}
</style>
