<template>
  <div class="btc-mint__waiting --padding btc-mint__container-wrapper">
    <div class="btc-mint__header --mb-g">
      <h2 v-if="!collectionClose && !fullPaiementPending">{{ $t('mint.title.waitingPaiement') }}</h2>
      <h2 v-else-if="!collectionClose && fullPaiementPending">{{ $t('mint.title.waitingConfirmations') }}</h2>
      <h2 v-else-if="collectionClose">{{ $t('mint.title.orderDetails') }}</h2>
      <div class="btc-mint__label --light --mb-xs">ID: {{ order.id }}</div>
      <div class="btc-mint__row --gap-s">
        <div class="btc-mint__label --light">x{{ order.nftCount }} Artifact{{ order.nftCount > 1 ? 's' : '' }}</div>
        <div class="btc-mint__label --light">{{ order.vSats }} vsats/vB</div>
      </div>
    </div>
    <div v-if="isFullRefund">
      <div class="btc-mint__row --col --mb">
        <h3>Will be Refunded</h3>
        <div v-for="(refund, index) in order.refunds" :key="index">
          <p class="btc-mint__c-white --mb">This order will be refunded.</p>
          <p class="btc-mint__c-white --mb">Amount: {{ order.totalAmount.toBtc() }} BTC</p>
          <p class="btc-mint__c-white --mb">
            Refund address: <a :href="`https://mempool.space/address/${refund.userRefundAddress}`" target="_blank">{{ refund.userRefundAddress }}</a>
          </p>
        </div>
      </div>
    </div>
    <div v-if="isFullRefunded">
      <div class="btc-mint__row --col --mb">
        <h3>has been refunded</h3>
        <p class="btc-mint__c-white --mb">This order has already been refunded.</p>
        <div v-for="(refund, index) in order.refunds" :key="index">
          <p class="btc-mint__c-white --mb">Amount: {{ new BtcValue(refund.amount).toBtc() }} BTC</p>
          <p class="btc-mint__c-white --mb">
            Refund address: <a :href="`https://mempool.space/address/${refund.userRefundAddress}`" target="_blank">{{ refund.userRefundAddress }}</a>
          </p>
          <p class="btc-mint__c-white --mb">
            Tx: <a :href="`https://mempool.space/tx/${refund.tx}`" target="_blank">{{ refund.tx }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="btc-mint__content" v-if="!isFullRefund && !isFullRefunded">
      <div class="btc-mint__row --center --mb-g" v-if="!collectionClose && !fullPaiementPending">
        <img src="" alt="" ref="qrcode" />
      </div>
      <div class="btc-mint__step" v-if="!collectionClose && !fullPaiementPending">
        <div class="btc-mint__row --align-center --col --mb">
          <h3>Pay on chain btc</h3>
          <p class="btc-mint__c-white">Copy address and amount below:</p>
        </div>
        <div class="btc-mint__input --mb-s">
          <input type="text" :value="order.receiveAddress" readonly />
          <div class="btc-mint__btn btc-mint__btn-primary btc-mint__btn-active" ref="receiveAddress" @click="$parent.copyClipboard(order.receiveAddress, $refs.receiveAddress)">COPY</div>
        </div>
        <div class="btc-mint__bordered btc-mint__bg-black --mb">
          <div class="btc-mint__row --mb-s">
            <h4 class="btc-mint__w-fixed">Total</h4>
            <div class="btc-mint__c-primary">
              <b>{{ order.totalAmount.toBtc() }} BTC</b>
            </div>
            <div class="btc-mint__c-white btc-mint__w-fixed --text-right btc-mint__price-usd">~${{ order.totalAmount.toUsd($parent.btcPrice) }}</div>
          </div>
          <div class="btc-mint__row --mb-s">
            <h4 class="btc-mint__w-fixed">Confirmed</h4>
            <div class="btc-mint__c-primary">
              <b>{{ order.totalReceived.toBtc() }} BTC</b>
            </div>
            <div class="btc-mint__c-white btc-mint__w-fixed --text-right btc-mint__price-usd">~${{ order.totalReceived.toUsd($parent.btcPrice) }}</div>
          </div>
          <div class="btc-mint__input --light">
            <h4 class="btc-mint__w-fixed">Needed</h4>
            <input type="text" :value="order.totalNeeded.toBtc() + ' BTC'" readonly class="btc-mint__c-primary --text-center" />
            <div class="btc-mint__btn btc-mint__btn-primary btc-mint__btn-active" ref="totalAmount" @click="$parent.copyClipboard(order.totalNeeded.toBtc(), $refs.totalAmount)">COPY</div>
          </div>
        </div>
      </div>
      <div class="btc-mint__step --relative" v-if="$parent.walletConnected && !collectionClose && !fullPaiementPending && $parent.walletConnectedType !== 'phantom'">
        <BtcLoaderGlobal :show="$parent.loaders.payWithWallet" />
        <div class="btc-mint__btn --big" @click="payWithWallet">{{ payWithWalletText }}</div>
      </div>
      <div class="btc-mint__step btc-mint__bordered btc-mint__bg-black">
        <div class="btc-mint__row btc-mint__paiement --mb-s" v-for="transaction of order.receivesTransactions" :key="transaction.id">
          <h4 class="btc-mint__w-fixed btc-mint__link" @click="openTx(transaction)">{{ transaction.tx.substr(0, 10) }}...</h4>
          <div class="btc-mint__c-primary">
            <b>{{ transaction.amount.toBtc() }} BTC</b>
          </div>
          <div class="btc-mint__c-white btc-mint__price-usd">~${{ transaction.amount.toUsd($parent.btcPrice) }}</div>
          <div class="btc-mint__c-white btc-mint__w-fixed --text-right" :class="{ 'btc-mint__c-primary': transaction.confirmations >= 6 }">Confirms: {{ transaction.confirmations }} / {{ confirmationsCount }}</div>
        </div>
        <div class="btc-mint__waiting__loader" v-if="!collectionClose">
          <div class="btc-mint__loader --mb-s">
            <svg class="btc-mint__ico" viewBox="0 0 93 95">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
            </svg>
          </div>
          <p class="btc-mint__c-white" v-if="!fullPaiementPending">{{ $t('mint.waitingPaiement') }}</p>
          <p class="btc-mint__c-white" v-else>{{ $t('mint.waitingPaiementConfirm') }}</p>
        </div>
        <div class="btc-mint__waiting__loader" v-else>
          <p class="btc-mint__c-white">{{ $t('mint.mintClosed') }}</p>
        </div>
      </div>
      <div class="btc-mint__step">
        <div class="btc-mint__row btc-mint__info --relative --align-center">
          <BtcLoaderGlobal :show="$parent.loaders.orders" />
          <div>
            <p class="btc-mint__c-primary-darker" v-if="fullPaiementPending">
              {{ $t('mint.mintDelay') }}
            </p>
            <p class="btc-mint__c-primary-darker" v-if="order.canExpire()">
              {{ $t('mint.expiration') }} <b>{{ order.expirationDate.toLocaleDateString() }} {{ order.expirationDate.toLocaleTimeString() }}</b>
            </p>
            <p class="btc-mint__c-primary-darker" v-else-if="order.getState() !== 'EXPIRED'">
              {{ $t('mint.noExpiration') }}
            </p>
            <p class="btc-mint__c-primary-darker">
              {{ $t('mint.refresh') }}
            </p>
          </div>
          <div class="btc-mint__btn --active" @click="$parent.getOrders(order.userOrdAddress)">REFRESH</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import { Order } from './order';
import BtcLoaderGlobal from '@/components/btc-mint/btcLoaderGlobal.vue';
import config from '../../config';
import { BtcValue } from '@/components/btc-mint/BtnValue';

export default {
  name: 'btcWaitingPayment',
  components: { BtcLoaderGlobal },
  props: {
    order: {
      type: Order,
      default: () => new Order(),
    },
  },
  computed: {
    BtcValue() {
      return BtcValue;
    },
    collection() {
      return this.$parent.collection;
    },
    collectionClose() {
      return (this.collection && (this.collection.isSoldOut() || this.collection.isClosed())) || this.order.isExpired();
    },
    payWithWalletText() {
      return this.$t('mint.payWithWallet.' + this.$parent.walletConnectedType);
    },
    fullPaiementPending() {
      return this.order.isFullPaiementPending();
    },
    confirmationsCount() {
      return this.collection.maxConfirmation;
    },
    isFullRefund() {
      return this.order.state === 'REFUND';
    },
    isFullRefunded() {
      return this.order.state === 'REFUNDED';
    },
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    async generateQRCode() {
      if (this.collectionClose) {
        return;
      }
      if (!this.$refs.qrcode) {
        return;
      }
      this.$refs.qrcode.src = await QRCode.toDataURL(this.order.receiveAddress, {
        width: 200,
      });
    },
    openTx(transaction) {
      const network = config.envs.NETWORK_NAME === 'testnet3' ? 'testnet/' : '';
      window.open('https://mempool.space/' + network + 'tx/' + transaction.tx);
    },
    async payWithWallet() {
      try {
        this.$parent.payWithWalletSession(this.order);
        // const resp = await window.btc?.request('sendTransfer', {
        //   address: this.order.receiveAddress,
        //   amount: this.order.totalNeeded.satoshi.toString()
        // });
        // console.log(resp)
      } catch (e) {
        console.log('No wallet detected', e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
#btc-mint {
  .btc-mint__waiting__loader {
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
</style>
