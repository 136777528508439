export const CH = {
  header: {
    title: '濒危实验室',
    subtitle: '濒危实验室是一个为有感知的生命体设计的<a href="#">全元宇宙工作室</a>。我们团结一致，通过可互操作的知识产权和游戏化体验构建一个<strong>面向创作者的网络国家。</strong>',
  },
  menu: {
    constro: '构造.',
    identity: '身份.',
    narrative: '叙事.',
    team: '团队.',
    faq: '常见问题.',
  },
  floating: {
    mint: 'Mint',
    premint: '加入我们',
    meme: {
      free: '免费',
      text: 'Memetics',
    },
    trailer: {
      watch: '观看',
      trailer: '预告片',
    },
    game: {
      free: '',
      text: 'PLAY NOW',
    },
    download: {
      download: '下载',
      models: '楷模',
    },
  },
  constro: {
    title: 'Constro',
    detail1: 'Constro是为下一代互联网文化打造的门户。我们将通过主权身份和游戏化体验，共同将Ailus引入虚拟经济中。',
    detail2: 'AILU是由赛博熊猫角色组成的起源系列，旨在跨开放元宇宙、社交平台和游戏引擎中<strong>设计出无障碍的内容创作。</strong>',
    detail3: 'Ailus具备探索新形式表达和<strong>为实现终极网络效应做出贡献的工具。</strong>',
    detail4: '作为一个包容性生态系统，我们致力于品牌建设、协作<strong>叙事和新兴技术。</strong>可能性是无限的，Constro将不断扩展。',
  },
  identity: {
    title: '你的新身份',
    avatar: {
      title: '头像互操作性',
      detail: '使用<strong>三维模型</strong>，用户可以跨许多系统进行互动，并在共享<strong>虚拟空间中一起参加活动和事件。</strong>在<strong>社交网络、游戏引擎和虚拟世界中使用你的角色。</strong>',
    },
    content: {
      title: '内容创作机会',
      detail: '针对<strong>无障碍的内容创作进行了令牌门控、三维、挂载优化的资产。</strong>使用你的Ailu<strong>创建动画</strong>，<strong>将其插入游戏</strong>，并<strong>进行运动捕捉直播。</strong>',
    },
    identity: {
      title: '头像身份',
      detail: '通过面部和身体追踪，在Zoom、Twitch、Google Meet、VRchat等平台上扮演你的Ailu。<strong>无需动作捕捉设备。</strong>只需要你的Ailu和一个网络摄像头。',
    },
    printing: {
      title: '三维打印',
      detail: '你可以3D打印你的模型，创造出你的Ailu的<strong>玩具、雕塑和模具</strong>。3D打印使你能够有效地创建定制物品并进行大规模生产。',
    },
    types: {
      title: '所有文件类型均可用',
      detail: '- 三维挂载模型<br/>\n' + ' - 带背景的PNG图像 <br/>\n' + ' - 带透明背景的PNG图像',
    },
  },
  narrative: {
    title: '叙事',
    n1:
      '<span>> </span>\n' +
      '            你是一只独特的AILU熊猫。\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            我们的创造者从星际间的DNA中进行剪接，使你的种族得以复活。\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            欢迎来到第一层。这是你的新身体。你将能够以这种形式存在于许多系统中。你独特的代码使你能够在任何你选择访问的宇宙中茁壮成长。',
    n2: '<span>> </span>\n' + '            你头部和手中的眼睛有一些独特的特点。\n' + '            <br/><br/>\n' + '            <span>> </span>\n' + '            你的AILU爪子将打开许多大门。使用它来访问或解锁车辆、物品、传送门，甚至是 Constro本身。\n' + '            <br/><br/>\n' + '            <span>> </span>\n' + '            等待着更多升级。',
    n3: '<span>> </span>\n' + '            你的机械臂上的几何图案是神圣的。发现它的全部潜力需要时间。\n' + '            <br/><br/>\n' + '            <span>> </span>\n' + '            你的任务并不容易。许多游戏正在进行中，你会认识到其他Ailu熊猫，他们可以在时间和空间上为你提供帮助。',
    n4:
      '<span>> </span>\n' +
      '            让我正式介绍一下自己。我是KORII，是为 CONSTRO服务的声音。我们的创造者在伟大的提升之初，将这个人工智能设立在地球上。\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            在那个人类和机器成为一体的技术进步时代，人类取得了巨大的科技进步。但是不久之后，人类开始远离自然的初衷。\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            为了更接近他们曾经的自我，人类开始与不同的物种融合，重新发现动物的内在特质。\n',
    n5: '<span>> </span>\n' + '            目前地球的状态仍然未知。\n' + '            <br/><br/>\n' + '            <span>> </span>\n' + '            所有已知的东西都留在了我们的家园 CONSTRO，这是一个介于世界之间的地方。\n',
  },
  faq: {
    title: '常問問題',
    questions: {
      q1: {
        question: '什麼是瀕危實驗室？',
        answer: `<p>Endangered Labs 是一个为众生服务的全宇宙工作室<br/><br/>我们将团结起来，建立一个网络国家，专注于通过数字文物、可互操作的知识产权和游戏化体验为创作者赋权。</p>`,
      },
      q2: {
        question: '什麼是AILU收藏品？',
        answer: `<p>AILU是瀕危實驗室的原始收藏品。 <br/><br/> 這是一個3D、帶骨骼的、網絡化熊貓頭像的收藏品，旨在通過社交網絡和遊戲引擎實現無摩擦的內容生成，從而加強匿名創作者經濟。 <br/><br/> Ailus可以讓您透過面部和身體跟踪來創建內容，而無需任何先前的3D、動畫或運動捕捉經驗。 </p>`,
      },
      q3: {
        question: '我可以用 AILU 做什么？',
        answer: `<p>我們鼓勵所有社區成員將自己的創造技能、熱情和靈感投入到構建開放元宇宙的工作中。 <br/><br/> 我們強烈建議每個Ailu一起工作，並相互教育創意軟件和編程語言，如：虛幻引擎5、Blender、Zbrush、Maya、Solidity、Rust和Python。 <br/><br/> 利用您的Ailu的一些方式：製作熊貓的獨特傳說和故事，建立您的虛擬影響力品牌，以您的角色作為音樂製作人來成為Metastar，創建動畫，設計可穿戴裝置3D打印。 <br/><br/> 可能性是無限的，我們只是創造內容的開始。 </p>`,
      },
      q4: {
        question: '发布日期和薄荷价格是多少？',
        answer: '<p>随着我们越来越接近交付收藏品，有关铸币日期和价格的详细信息将在我们的推特上公布.</p>',
      },
      q5: {
        question: '有保证的鑄造清单吗？',
        answer: `<p>请关注<a href="https://twitter.com/Ailu2100" target="_blank">{'@'}endangeredlabs</a>，了解更多信息。</p>`,
      },
      q6: {
        question: 'AILU持有人有哪些知识产权？',
        answer: `<p>AILU的所有者享有完全的商业权利。详细信息请参见<a href="Endangered_Labs_Terms.pdf" target="_blank">条款</a>。</p>`,
      },
      q7: {
        question: '有路线图吗？',
        answer: `<p>Web3不断变化，瀕危实验室的建立是为了适应不断变化的未来。我们不想局限自己在严格的路线图中，因为我们必须具备适应性，以交付成功的产品。<br/><br/>我们的目标是执行和交付，不需要路线图。<br/><br/>目前，您可以通过阅读叙述来了解我们正在做什么。</p>`,
      },
    },
  },
  footer: {
    backtotop: '回到顶部',
    followus: '关注我们',
    ontwitter: '在𝕏上',
    contact: '联系方式',
    terms: '条款',
    privacy: '隐私',
  },
  downloadmodule: {
    title: '下载文件',
    model: '3D模型',
    fullview: '全视图',
    nobackground: '无背景',
    glb: 'GLB文件',
    png: 'PNG文件',
    pfp: 'PFP',
    nobg: '无背景',
    modelA: '模型 - 姿势 A',
    modelT: '模型 - 姿势 T',
  },
};
