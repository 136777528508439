import { BtcValue } from './BtnValue';

export class Order {
  id = '';
  userOrdAddress = '';
  receiveAddress = '';
  totalAmount = new BtcValue(0);
  totalNeeded = new BtcValue(0);
  totalReceived = new BtcValue(0);
  fees = new BtcValue(0);
  state = 'WAITING';
  nftCount = 0;
  vSats = 0;
  receivesTransactions = [];
  nftsInscriptions = [];
  createdAt = new Date();
  expirationDate = new Date();
  queue = { position: 0, total: 0 };
  estimationTime = 0;
  userRefundAddress = '';
  cursed = false;
  refunds = [];

  constructor(_order) {
    if (!_order) {
      return;
    }

    this.id = _order.id;
    this.userOrdAddress = _order.userOrdAddress;
    this.receiveAddress = _order.receiveAddress;
    this.userRefundAddress = _order.userRefundAddress;
    this.totalAmount = new BtcValue(_order.totalAmount);
    this.totalNeeded = new BtcValue(_order.totalNeeded);
    this.totalReceived = new BtcValue(_order.totalReceived);
    this.fees = new BtcValue(_order.totalSatsFees);
    this.state = _order.state;
    this.nftCount = _order.nftCount;
    this.vSats = _order.vSats;
    this.cursed = _order.cursed;
    this.refunds = _order.refunds;
    if (_order.queue) {
      this.queue = _order.queue;
    }
    if (_order.estimationTime) {
      this.estimationTime = _order.estimationTime;
    }
    this.receivesTransactions = _order.receivesTransactions.map((receiveTransaction) => new ReceiveTransaction(receiveTransaction));
    this.nftsInscriptions = _order.ordinals.map((nftInscription) => new NftInscription(nftInscription));
    this.expirationDate = new Date(_order.expirationDate);
    this.createdAt = new Date(_order.createdAt);
  }
  getOrderState() {
    if (this.nftsInscriptions.length > 0) {
      return 'completed';
    }
    return 'waiting';
  }

  isMintCompleted() {
    for (const nft of this.nftsInscriptions) {
      if (!nft.isMinted()) {
        return false;
      }
    }
    return true;
  }

  nftMintingStat() {
    const result = {
      total: this.nftsInscriptions.length,
      minting: 0,
      minted: 0,
    };
    for (const nft of this.nftsInscriptions) {
      result.minted += nft.isMinted() ? 1 : 0;
      result.minting += !nft.isMinted() ? 1 : 0;
    }
    return result;
  }

  getState() {
    switch (this.state) {
      case 'WAITING':
        if (this.isFullPaiementPending()) {
          return 'PAYMENT PROGRESS';
        }
        return 'PENDING PAYMENT';
      case 'MINTING':
        return 'INSCRIBE IN PROGRESS';
      case 'COMPLETED':
        return 'COMPLETED';
      case 'RECEIVE_ALL_FUNDS':
        return 'RECEIVED ALL FUNDS';
      case 'REFUND':
        return 'WILL BE REFUNDED';
    }
    return this.state;
  }

  isFullPaiementPending() {
    if (this.receivesTransactions.length <= 0) {
      return false;
    }
    let total = BigInt(0);
    for (const tx of this.receivesTransactions) {
      total += tx.amount.satoshi;
    }
    return total === this.totalAmount.satoshi;
  }

  canExpire() {
    return this.receivesTransactions.length <= 0 && this.state !== 'EXPIRED';
  }

  isExpired() {
    return this.expirationDate <= new Date() && this.state === 'EXPIRED';
  }
}

export class ReceiveTransaction {
  id = '';
  tx = '';
  amount = new BtcValue(0);
  confirmations = 0;
  completedAt = new Date();

  constructor(_receiveTransaction) {
    if (!_receiveTransaction) {
      return;
    }

    this.id = _receiveTransaction.id;
    this.tx = _receiveTransaction.tx;
    this.amount = new BtcValue(_receiveTransaction.amount);
    this.confirmations = _receiveTransaction.confirmations;
    this.completedAt = new Date(_receiveTransaction.completedAt);
  }
}

export class NftInscription {
  inscriptionId = null;
  metadataUrl = '';
  fileUrl = '';
  previewUrl = '';
  state = 'WAITING';
  confirmations = '';
  tx = '';
  txSending = '';
  itemId = 0;
  id = '';
  constructor(_nftInscription) {
    if (!_nftInscription) {
      return;
    }

    this.inscriptionId = _nftInscription.inscriptionId;
    this.metadataUrl = _nftInscription.metadataUrl;
    this.fileUrl = _nftInscription.fileUrl;
    this.previewUrl = _nftInscription.previewUrl;
    this.state = _nftInscription.state;
    this.confirmations = _nftInscription.confirmations;
    this.tx = _nftInscription.tx;
    this.txSending = _nftInscription.txSending;
    this.itemId = _nftInscription.itemId;
    this.id = _nftInscription.id;
  }

  isReserved() {
    return this.state === 'RESERVED';
  }

  isMinted() {
    return this.state === 'COMPLETED';
  }

  getState() {
    return this.state;
  }
}
