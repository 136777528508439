export default {
  clan: 'Player',
  gender: 'M',
  base: [
    {
      layer: 'Body',
      name: 'Hold2',
    },
  ],
  accessories: [],
  image: '/3dmodel/Meta_mobileViewer.png',
  settings: {
    scene: {
      width: 581,
      height: 532,
      background: '0x091919',
      environment: '/3dmodel/studio_small_08_2k.hdr',
    },
    renderer: {
      precision: 'highp',
      antialias: true,
    },
    camera: {
      position: {
        x: -1.51,
        y: 1.73,
        z: 4.52,
      },
      lookAt: {
        x: 0.12,
        y: 1.05,
        z: -0.06,
      },
    },
    orbit: {
      minPolarAngle: Math.PI / 2 - 0.15,
      maxPolarAngle: Math.PI / 2 - 0.15,
      autoRotate: false,
      enableZoom: false,
      enablePan: false,
    },
    hemiLight: {
      intensity: 0,
      animated: true,
      skyColor: '0xffeeb1',
      groundColor: '0x080820',
    },
    spotLight: [
      {
        name: 'Front',
        animated: true,
        cameraLinked: true,
        color: '0x96fdfd',
        position: {
          x: 17,
          y: 20,
          z: 40,
        },
        intensity: 0.9,
        shadow: {
          cast: true,
          bias: -0.0001,
          mapSize: {
            width: 1024 * 4,
            height: 1024 * 4,
          },
        },
      },
      {
        name: 'Back',
        animated: true,
        cameraLinked: true,
        color: '0x96fdfd',
        position: {
          x: -50,
          y: 0,
          z: -40,
        },
        intensity: 6.5,
        shadow: {
          cast: true,
          bias: -0.0001,
          mapSize: {
            width: 1024 * 4,
            height: 1024 * 4,
          },
        },
      },
      {
        name: 'Back-right',
        animated: true,
        cameraLinked: true,
        color: '0x96fdfd',
        position: {
          x: 50,
          y: 0,
          z: -40,
        },
        intensity: 6.5,
        shadow: {
          cast: true,
          bias: -0.0001,
          mapSize: {
            width: 1024 * 4,
            height: 1024 * 4,
          },
        },
      },
    ],
    pointLights: [
      {
        name: 'Arm',
        animated: true,
        cameraLinked: false,
        color: '0x00AEFF',
        position: {
          x: 0.17,
          y: 1.29,
          z: 0.41,
        },
        intensity: 3.12,
        distance: 0.9,
        shadow: {
          cast: true,
          bias: -0.0001,
          mapSize: {
            width: 1024 * 4,
            height: 1024 * 4,
          },
        },
      },
    ],
  },
};
