<template>
  <div class="btc-mint__minting --padding btc-mint__container-wrapper">
    <BtcLoaderGlobal :show="$parent.loaders.global" style="z-index: 20" />
    <div class="btc-mint__header --inline">
      <h2>
        {{ $t(title) }}
      </h2>
      <div class="btc-mint__label --light">
        {{ $t('mint.supply') }}: <b>{{ collection.getNftMinted() }}/{{ collection.getNftTotal() }}</b>
        <span v-if="collection.getNftPending() > 0">
          (
          <span class="btc-mint__loader --small">
            <svg class="btc-mint__ico" viewBox="0 0 93 95">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
            </svg>
          </span>
          {{ collection.getNftPending() }}
          )
        </span>
      </div>
    </div>
    <div class="btc-mint__step btc-mint__bordered btc-mint__bg-black" v-if="!publicIsOpen">
      <div v-if="!privateIsOpen">Access List starts in <BtcCountdown :date="new Date(1713106800000).toString()" @done="privateIsOpen = true" /></div>
      <div v-if="privateIsOpen">Access List closes in <BtcCountdown :date="new Date(1713150000000).toString()" @done="publicIsOpen = true" /></div>
    </div>
    <div v-if="!mintWithWhitelistCompleted" class="btc-mint__content">
      <div v-if="collection.hasPassword" class="btc-mint__step">
        <h3>1. {{ $t('mint.password.title') }}</h3>
        <p class="btc-mint__c-white --mb-s" v-html="$t('mint.password.text')"></p>
        <input type="text" :placeholder="$t('mint.password.placeholder')" v-model="passwordWhitelist" :class="{ '--error': $parent.currentPasswordWhitelist === null || $parent.currentPasswordIsValid === false }" />
      </div>
      <div v-else-if="collection.hasWhitelist" class="btc-mint__step">
        <h3>1. {{ $t('mint.wallet.title') }}</h3>
        <p class="btc-mint__c-white --mb-s" v-html="$t('mint.wallet.text')"></p>
        <input type="text" :placeholder="$t('mint.wallet.placeholder')" v-model="ordAddress" :class="{ '--error': userOrdAddressIsValid === null || userOrdAddressIsValid === false }" />
      </div>
      <div class="btc-mint__step --relative" @click="checkWhitelist">
        <BtcLoaderGlobal :show="$parent.loaders.checkWhitelist" />
        <div class="btc-mint__btn --active --big" :class="{ '--disabled': $parent.loaders.amount || !amount }">{{ $t('mint.check') }}</div>
      </div>
    </div>
    <div v-else class="btc-mint__content">
      <div class="btc-mint__step">
        <h3 class="--mb">1. {{ $t('mint.amount.title') }}</h3>
        <div class="btc-mint__row btc-mint__bordered btc-mint__minting">
          <div class="btc-mint__row btc-mint__minting__count">
            <div class="btc-mint__btn --active" :class="{ '--disabled': count <= 1 }" @click="setCount(-1)">
              <svg class="btc-mint__ico" viewBox="0 0 62 62"><rect x="12" y="29" width="37" height="4" /></svg>
            </div>
            <p class="btc-mint__bordered">
              {{ count }}
            </p>
            <div class="btc-mint__btn --active" :class="{ '--disabled': count >= max }" @click="setCount(1)">
              <svg class="btc-mint__ico" viewBox="0 0 14 14"><path d="M8 0H6V6H0V8H6V14H8V8H14V6H8V0Z" /></svg>
            </div>
          </div>
          <div class="btc-mint__label">
            {{ $t('mint.amount.mintPrice') }}: <b>{{ collection.getPriceBtc(count) }} BTC</b>
          </div>
        </div>
      </div>
      <div class="btc-mint__step">
        <h3>2. {{ $t('mint.recipientAddress.title') }}</h3>
        <p class="btc-mint__c-white --mb-s" v-html="$t('mint.recipientAddress.text')"></p>
        <input type="text" :placeholder="$t('mint.recipientAddress.placeholder')" :disabled="collection.hasWhitelist && !collection.hasPassword && !$parent.walletConnectedType !== 'unisat'" v-model="ordAddress" :class="{ '--error': userOrdAddress === null }" @change="checkWhitelist" />
      </div>
      <div class="btc-mint__step">
        <h3>3. {{ $t('mint.refundAddress.title') }}</h3>
        <p class="btc-mint__c-white --mb-s" v-html="$t('mint.refundAddress.text')"></p>
        <input type="text" :placeholder="$t('mint.refundAddress.placeholder')" v-model="cardAddress" :class="{ '--error': userRefundAddress === null }" />
      </div>
      <div class="btc-mint__step" v-if="fees && enableUserFees">
        <h3 class="btc-mint__c-secondary">4. {{ $t('mint.fee.title') }}</h3>
        <p class="btc-mint__c-white --mb" v-html="$t('mint.fee.text')"></p>
        <div class="btc-mint__row --w-equal --gap btc-mint__minting__fees">
          <div class="btc-mint__bordered btc-mint__click" :class="{ '--active': fee === fees.hourFee }" @click="setFee(fees.hourFee)">
            <h4>{{ $t('mint.fee.button.economy') }}</h4>
            <p class="btc-mint__c-primary-desaturated --mb-s">
              <b>{{ fees.hourFee }} sats/vB</b>
            </p>
            <p class="btc-mint__c-primary-desaturated">{{ $t('mint.fee.delay.multipleHours') }}</p>
          </div>
          <div class="btc-mint__bordered btc-mint__click" :class="{ '--active': fee === fees.fastestFee }" @click="setFee(fees.fastestFee)">
            <h4>{{ $t('mint.fee.button.normal') }}</h4>
            <p class="btc-mint__c-primary-desaturated --mb-s">
              <b>{{ fees.fastestFee }} sats/vB</b>
            </p>
            <p class="btc-mint__c-primary-desaturated">1 {{ $t('mint.fee.delay.hours') }}</p>
          </div>
          <div class="btc-mint__bordered btc-mint__click" :class="{ '--active': fee === customFees }" @click="setFee(customFees)">
            <h4>{{ $t('mint.fee.button.custom') }}</h4>
            <p class="btc-mint__c-primary-desaturated --mb-s">
              <b>{{ customFees }} sats/vB</b>
            </p>
            <p class="btc-mint__c-primary-desaturated">
              <input type="number" :min="this.fees.hourFee" placeholder="Choose fee" v-model="customFees" @change="calculAmount" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" />
            </p>
          </div>
        </div>
      </div>
      <div class="btc-mint__step">
        <p class="btc-mint__c-primary-darker --text-center" v-html="$t('mint.fee.delay.timeNotGuaranteed')"></p>
      </div>
      <div class="btc-mint__step btc-mint__bordered btc-mint__bg-black btc-mint__minting__resume --relative">
        <BtcLoaderGlobal :show="$parent.loaders.amount" />
        <div class="btc-mint__row --w-equal" v-if="amount">
          <h4>{{ $t('mint.fee.cost.mint') }}:</h4>
          <div class="btc-mint__c-primary-desaturated">
            <b>{{ amount.getPriceBtc() }} BTC</b>
          </div>
          <div class="btc-mint__c-white btc-mint__price-usd">~${{ amount.getPriceUsd($parent.btcPrice) }}</div>
        </div>
        <div class="btc-mint__row --w-equal" v-if="amount">
          <h4>{{ $t('mint.fee.cost.network') }}:</h4>
          <div class="btc-mint__c-primary-desaturated">
            <b>{{ amount.getFeesBtc() }} BTC</b>
          </div>
          <div class="btc-mint__c-white btc-mint__price-usd">~${{ amount.getFeesUsd($parent.btcPrice) }}</div>
        </div>
        <div class="btc-mint__row --w-equal" v-if="amount">
          <h4>{{ $t('mint.fee.cost.service') }}:</h4>
          <div class="btc-mint__c-primary-desaturated">
            <b>- BTC</b>
          </div>
          <div class="btc-mint__c-white btc-mint__price-usd">$0</div>
        </div>
        <hr />
        <div class="btc-mint__row --w-equal" v-if="amount">
          <h4>{{ $t('mint.fee.cost.total') }}:</h4>
          <div class="btc-mint__c-primary-desaturated">
            <b>{{ amount.getTotalBtc() }} BTC</b>
          </div>
          <div class="btc-mint__c-white btc-mint__price-usd">~${{ amount.getTotalUsd($parent.btcPrice) }}</div>
        </div>
        <div class="btc-mint__row --w-equal" v-if="!enableUserFees && collection.estimationTime > 0">
          <h4>Estimation Time:</h4>
          <div class="btc-mint__c-primary-desaturated"></div>
          <div class="btc-mint__c-white btc-mint__price-usd">~{{ $parent.formatDuration(collection.estimationTime) }}</div>
        </div>
      </div>
      <div v-if="$parent.maxAllowed > 0" class="btc-mint__step --relative" @click="mint">
        <BtcLoaderGlobal :show="$parent.loaders.mint" />
        <div class="btc-mint__btn --active --big" :class="{ '--disabled': $parent.loaders.amount || !amount }">{{ $t('mint.mint') }}</div>
      </div>
      <div v-else class="btc-mint__step --relative">
        <BtcLoaderGlobal :show="$parent.loaders.mint" />
        <div class="btc-mint__btn --active --big" :class="{ '--disabled': true }" style="cursor: default">{{ $t('mint.notAllowed') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collection } from '@/components/btc-mint/collection';
import api from '@/components/btc-mint/api';
import { Amount } from '@/components/btc-mint/amount';
import BtcLoaderGlobal from '@/components/btc-mint/btcLoaderGlobal.vue';
import BtcCountdown from '@/components/btc-mint/btcCountdown.vue';

export default {
  name: 'btcMinting',
  components: { BtcCountdown, BtcLoaderGlobal },
  data: () => ({
    count: 1,

    fee: 35,
    customFees: 70,
    fees: null,

    ordAddress: null,
    userOrdAddress: '',

    cardAddress: null,
    userRefundAddress: '',

    amount: null,

    ordAddressWhitelist: null,
    userOrdAddressWhitelist: '',
    userOrdAddressIsValid: null,

    passwordWhitelist: null,

    privateIsOpen: false,
    publicIsOpen: false,
  }),
  computed: {
    title() {
      if (!this.mintWithWhitelistCompleted) {
        return 'mint.title.whitelist';
      }
      return 'mint.title.mint';
    },
    mintWithWhitelistCompleted() {
      if (!this.collection.hasPassword && !this.collection.hasWhitelist) {
        return true;
      }

      return this.$parent.currentPasswordIsValid || this.userOrdAddressIsValid;
    },
    collection() {
      return this.$parent.collection || new Collection();
    },
    max() {
      return this.collection.getNftAvailable() > this.$parent.maxAllowed ? this.$parent.maxAllowed : this.collection.getNftAvailable();
    },
    enableUserFees() {
      return !this.collection.hasParent();
    },
  },
  async mounted() {
    if (localStorage.getItem('_btc_ordinal_address')) {
      this.ordAddress = localStorage.getItem('_btc_ordinal_address');
      this.ordAddressWhitelist = localStorage.getItem('_btc_ordinal_address');
    }
    if (localStorage.getItem('_btc_cardinal_address')) {
      this.cardAddress = localStorage.getItem('_btc_cardinal_address');
    }

    await this.$parent.setLoader('global', true);
    await this.getCurrentFees();
    if (!this.enableUserFees) {
      this.fee = this.collection.vSat;
    }
    await this.checkWhitelist();
    await this.calculAmount();
    await this.$parent.setLoader('global', false);
  },
  watch: {
    ordAddress() {
      this.userOrdAddress = this.ordAddress;
    },
    passwordWhitelist() {
      this.$parent.currentPasswordWhitelist = this.passwordWhitelist;
    },
    cardAddress() {
      this.userRefundAddress = this.cardAddress;
    },
    customFees() {
      this.fee = Math.abs(this.customFees);
    },
    async collection() {
      if (!this.enableUserFees && this.fee !== this.collection.vSat) {
        this.fee = this.collection.vSat;
        await this.calculAmount();
      }
    },
  },
  methods: {
    setCount(sens) {
      if ((this.count === this.max && sens === 1) || (this.count === 1 && sens === -1)) {
        return;
      }

      this.count += sens;
      if (this.count > this.max) this.count = this.max;
      if (this.count < 1) this.count = 1;
      this.calculAmount();
    },
    setFee(fee) {
      this.fee = fee;
      this.calculAmount();
    },
    async getCurrentFees() {
      const result = await api.get('https://mempool.space/api/v1/fees/recommended');

      if (result.error()) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.gas') });
        return;
      }

      this.fees = result.data();
      this.customFees = this.fees.fastestFee * 2;
      if (this.fees.hourFee === this.fees.fastestFee) {
        this.fees.hourFee -= 1;
      }
      this.fee = this.fees.fastestFee;
    },
    async calculAmount() {
      if (this.enableUserFees) {
        if (this.customFees < this.fees.hourFee) {
          this.customFees = this.fees.hourFee;
        }
      }
      await this.$parent.setLoader('amount', true);
      await this.$parent.setLoader('mint', true);
      const result = await api.get(`amount/${this.collection.id}/${this.count}/${this.fee}`);
      await this.$parent.setLoader('amount', false);
      await this.$parent.setLoader('mint', false);

      if (result.error()) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.cost') });
        return;
      }

      this.amount = new Amount(result.data());
    },
    async mint() {
      if (this.userOrdAddress === '' || !this.userOrdAddress) {
        this.userOrdAddress = null;
        return;
      }
      if (this.$parent.loaders.amount || this.$parent.loaders.mint) {
        return;
      }

      if (!this.userRefundAddress) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.userRefundAddress') });
        return;
      }

      // if (!this.$parent.isTaprootAddress(this.userOrdAddress)) {
      //   this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.notTaproot') });
      //   this.userOrdAddress = null;
      //   return;
      // }

      if (this.enableUserFees) {
        if (this.fee < this.fees.hourFee) {
          this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.gasTooLow') });
          return;
        }
      } else {
        this.fee = this.collection.vSat;
        // ne pas utiliser setFee ici pour éviter le clignotement
      }

      localStorage.setItem('_btc_ordinal_address', this.userOrdAddress);

      await this.$parent.setLoader('mint', true);
      const result = await api.post('order', {
        collectionId: this.collection.id,
        userOrdAddress: this.userOrdAddress,
        userRefundAddress: this.userRefundAddress,
        nftCount: this.count,
        vSat: this.fee,
        password: this.$parent.currentPasswordWhitelist,
      });

      if (result.error()) {
        await this.$parent.setLoader('mint', false);

        const errorMsg = result.errorMsg();

        if (errorMsg && errorMsg.includes('address is not whitelist')) {
          return this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.notWhitelisted') });
        }
        if (errorMsg && errorMsg.includes('wrong password')) {
          return this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.passwordBad') });
        }
        if (errorMsg && errorMsg.includes('too many inscriptions asked')) {
          return this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.maxAllowed') });
        }

        if (errorMsg && errorMsg.includes('too low')) {
          return this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.gasTooLow') });
        }

        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.orders') });
        return;
      }

      if (!(await this.$parent.getOrders(this.userOrdAddress))) {
        return;
      }

      this.$parent.setUserWalletSession(this.userRefundAddress, this.userOrdAddress);
      this.$parent.startAutoRefresh();

      await this.$parent.setLoader('mint', false);
      this.$parent.currentOrderId = result.data().id;
    },
    async checkWhitelist() {
      if (this.collection.hasPassword && !this.$parent.currentPasswordWhitelist) {
        this.$parent.currentPasswordWhitelist = null;
        // this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.passwordMissing') });
        return;
        // call http to check all datas.
      }
      if (this.collection.hasWhitelist && !this.collection.hasPassword && !this.userOrdAddressIsValid && !this.ordAddress) {
        this.userOrdAddressIsValid = null;
        // this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.passwordMissing') });
        return;
        // call http to check all datas.
      }

      if (this.collection.maxPerWallet && !this.ordAddress) {
        return;
      }

      await this.$parent.setLoader('checkWhitelist', true);
      await this.$parent.setLoader('amount', true);
      await this.$parent.setLoader('mint', true);

      const result = await api.post(`collection/${this.collection.id}/check`, {
        address: this.ordAddress, //this.userOrdAddressWhitelist,
        password: this.$parent.currentPasswordWhitelist,
      });

      if (result.error()) {
        await this.$parent.setLoader('checkWhitelist', false);
        await this.$parent.setLoader('amount', false);
        await this.$parent.setLoader('mint', false);
        //@TODO gérer les erreur
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.whitelist') });
        return;
      }

      await this.$parent.setLoader('checkWhitelist', false);
      await this.$parent.setLoader('amount', false);
      await this.$parent.setLoader('mint', false);
      const data = result.data();

      this.userOrdAddressIsValid = data.addressIsValid;
      this.$parent.currentPasswordIsValid = data.passwordIsValid;
      if (data.numberAllowed !== undefined && data.numberAllowed !== -1) {
        this.$parent.maxAllowed = data.numberAllowed;
      }

      if (this.collection.hasPassword && !this.$parent.currentPasswordIsValid) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.passwordBad') });
      } else if (this.collection.hasWhitelist && !this.collection.hasPassword && !this.userOrdAddressIsValid) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.whitelistWalletBad') });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#btc-mint {
  .btc-mint__minting {
    &__count {
      width: 200px;
      gap: 10px;

      p {
        width: 100%;
        text-align: center;
        padding: 5px;
      }

      .btc-mint__btn {
        min-width: 36px;
      }
    }
    &__fees {
      text-align: center;
    }
    &__resume {
      min-height: 148px;
      h4 {
        font-size: var(--btc-font-size-text);
      }
    }
  }
}
</style>
