import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min';
import * as THREE from 'three';

export default {
  gui: null,
  config: null,

  init(config) {
    this.config = config;
    this.gui = new GUI();
    this.gui.close();
  },
  addScene() {
    const sceneFolder = this.gui.addFolder('Scene');
    // sceneFolder.addColor(scene, 'background')
    sceneFolder.open();
  },
  addCameraOrbit(camera, orbit) {
    const camFolder = this.gui.addFolder('Camera');
    camFolder.add(camera.position, 'x', -5, 5, 0.01).onChange(orbit.update);
    camFolder.add(camera.position, 'y', -5, 5, 0.01).onChange(orbit.update);
    camFolder.add(camera.position, 'z', -5, 5, 0.01).onChange(orbit.update);
    camFolder.close();
    const camTargetFolder = this.gui.addFolder('Camera target');
    const updateTarget = () => {
      camera.lookAt(this.config.camera.lookAt.x, this.config.camera.lookAt.y, this.config.camera.lookAt.z);
      orbit.target = new THREE.Vector3(this.config.camera.lookAt.x, this.config.camera.lookAt.y, this.config.camera.lookAt.z);
      orbit.update();
    };
    camTargetFolder.add(this.config.camera.lookAt, 'x', -5, 5, 0.01).onChange(updateTarget);
    camTargetFolder.add(this.config.camera.lookAt, 'y', -5, 5, 0.01).onChange(updateTarget);
    camTargetFolder.add(this.config.camera.lookAt, 'z', -5, 5, 0.01).onChange(updateTarget);
    camTargetFolder.close();
  },
  addHemisphere(hemiLight) {
    const trace = () => {
      console.log('Hemi Light', {
        intensity: hemiLight.intensity,
        skyColor: '0x' + hemiLight.color.getHexString(),
        groundColor: '0x' + hemiLight.groundColor.getHexString(),
      });
    };

    const hemiFolder = this.gui.addFolder('Hemi Light');
    hemiFolder.add(hemiLight, 'intensity', 0, 10, 0.01).onChange(trace);
    hemiFolder.addColor(hemiLight, 'color').onChange(trace);
    hemiFolder.addColor(hemiLight, 'groundColor').onChange(trace);
    hemiFolder.open();
  },
  addSpotLight(spotLight, spotLightConfig) {
    const trace = () => {
      console.log('Spot Light: ' + spotLightConfig.name, {
        position: spotLight.position,
        intensity: spotLight.intensity,
        color: '0x' + spotLight.color.getHexString(),
      });
    };

    const LightFolder = this.gui.addFolder('Spot Light: ' + spotLightConfig.name);
    LightFolder.add(spotLight.position, 'x', -100, 100, 0.01).onChange(trace);
    LightFolder.add(spotLight.position, 'y', -100, 100, 0.01).onChange(trace);
    LightFolder.add(spotLight.position, 'z', -100, 100, 0.01).onChange(trace);
    LightFolder.add(spotLight, 'intensity', -10, 10, 0.01).onChange(trace);
    LightFolder.addColor(spotLight, 'color').onChange(trace);
    LightFolder.open();
  },
  addPointLight(pointLight, pointLightConfig) {
    const trace = () => {
      console.log('Spot Light: ' + pointLightConfig.name, {
        position: pointLight.position,
        intensity: pointLight.intensity,
        distance: pointLight.distance,
        color: '0x' + pointLight.color.getHexString(),
      });
    };

    const LightFolder = this.gui.addFolder('Point Light: ' + pointLightConfig.name);
    LightFolder.add(pointLight.position, 'x', -10, 10, 0.01).onChange(trace);
    LightFolder.add(pointLight.position, 'y', -10, 10, 0.01).onChange(trace);
    LightFolder.add(pointLight.position, 'z', -10, 10, 0.01).onChange(trace);
    LightFolder.add(pointLight, 'intensity', -10, 10, 0.01).onChange(trace);
    LightFolder.add(pointLight, 'distance', -10, 10, 0.01).onChange(trace);
    LightFolder.addColor(pointLight, 'color').onChange(trace);
    LightFolder.open();
  },
};
