<template>
  <div class="btc-mint__picture" :class="{ '--active': nft.isMinted() }" @click.prevent.stop.exact="openModal">
    <div class="--relative --overflow-hidden">
      <img :src="previewUrl" alt="" />
      <!--      <div class="state &#45;&#45;over" :class="nft.state === 'RESERVED' ? ' &#45;&#45;no-click' : ''" @click="openTx(nft)">-->
      <!--        {{ nftState }}-->
      <!--      </div>-->
    </div>
    <!--    <div class="state &#45;&#45;bg" @click="openNft(nft)" v-if="nft.isMinted()">{{ nft.inscriptionId.substr(0, 5) }}...{{ nft.inscriptionId.substr(-5) }}</div>-->
    <div class="dl-trigger" v-if="nft.isMinted() || nft.isReserved()" @click.prevent.stop.exact="openModal" :class="{ '--active': download }" style="cursor: pointer">
      <div class="icon">
        <svg class="ico"><use xlink:href="#ico-download"></use></svg>
      </div>
      More details
    </div>
  </div>
</template>
<script>
import { NftInscription } from './order';
import config from '../../config';
import { Collection } from '@/components/btc-mint/collection';

export default {
  name: 'btcNftPreview',
  props: {
    nft: {
      type: NftInscription,
      default: new NftInscription(),
    },
    collection: {
      type: Collection,
      default: new Collection(),
    },
  },
  data: () => ({
    download: false,
  }),
  computed: {
    nftState() {
      if (this.nft.getState() === 'MINTING') {
        return 'INSCRIBE (confirm: ' + this.nft.confirmations + '/' + this.collection.maxConfirmation + ')';
      }
      if (this.nft.getState() === 'SENDING') {
        return 'SENDING';
      }
      return this.nft.getState();
    },
    isImage() {
      return this.nft.fileUrl.match(/(.jpg)|(.png)|(.gif)|(.jpeg)|(.webp)/i);
    },
    isIframe() {
      return this.nft.fileUrl.match(/(.html)/i);
    },
    previewUrl() {
      if (this.isIframe) {
        return this.nft.previewUrl;
      }
      return this.nft.fileUrl;
    },
  },
  methods: {
    openNft(nft) {
      if (!nft.isMinted()) {
        return;
      }
      // window.open('https://www.ord.io/' + nft.inscriptionId);
      const network = config.envs.NETWORK_NAME === 'testnet3' ? 'testnet.' : '';
      window.open('https://' + network + 'ordinals.com/inscription/' + nft.inscriptionId);
    },
    openTx(nft) {
      if (nft.state === 'RESERVED') {
        return;
      }
      const network = config.envs.NETWORK_NAME === 'testnet3' ? 'testnet/' : '';
      const tx = nft.txSending ? nft.txSending : nft.tx;
      window.open('https://mempool.space/' + network + 'tx/' + tx);
    },
    openModal() {
      this.emitter.emit('download', this.nft);
    },
  },
};
</script>

<style scoped lang="scss">
.btc-mint__picture {
  position: relative;

  &:hover {
    .dl-trigger {
    }
    .dl-content {
      display: block !important;
    }
  }
}
.dl-trigger {
  background: var(--btc-bg-primary);
  color: var(--btc-primary-desaturated);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  svg {
    fill: var(--btc-primary-desaturated);
  }
  .icon {
    padding: 3px 6px 3px 6px;
  }
  &:hover {
    background-color: var(--btc-primary-darker-bg);
  }
}
.dl-content {
  position: absolute;
  top: 43px;
  right: 5px;
  background: var(--btc-bg-primary);
  border: 1px solid var(--btc-primary-darker);
  color: var(--btc-primary-desaturated);
  border-radius: var(--btc-border-radius);
  display: none !important;

  a {
    display: block;
    padding: 4px;
    margin-bottom: 2px;
    &:before {
      display: none;
    }
  }
}
</style>
