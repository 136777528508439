export default {
  animateLoader() {
    return true;
  },

  animateLoaderLight({ lights, render }) {
    let finish = 0;
    for (const composition of lights) {
      if (composition.light.intensity < composition.config.intensity) {
        composition.light.intensity += composition.config.intensity / 60;
      } else {
        finish++;
      }
    }

    // scene.background = new THREE.Color(0x333333).lerp(new THREE.Color(parseInt(config.scene.background, 16)), render.alphaLoaderBackground += 1 / 60);
    if (render.alphaLoaderBackground >= 1) {
      finish += 1;
    }

    // for (const gltf of assetsLoaded) {
    //     gltf.object.scene.traverse(n => {
    //         if (n.isMesh) {
    //             if (gltf.meshColor[n.uuid]) {
    //                 const color = new THREE.Color(0x000000).lerp(new THREE.Color(gltf.meshColor[n.uuid]), render.alphaLoaderBackground);
    //                 n.material.color.set(color);
    //             }
    //         }
    //     })
    // }

    return finish !== lights.length + 1;
  },
};
