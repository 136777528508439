export default {
  base: {
    Player: {
      // clan
      M: {
        // genre
        Body: {
          // layer
          Hold2: {
            // name
            file: 'Meta_Mastermind_Pose_Reduced-v2.glb',
          },
          Hold3: {
            // name
            file: 'Cyber_Thinker_Pose_Reduced-v1.glb',
          },
          Hold4: {
            // name
            file: 'ShadowReduced-v1.glb',
          },
        },
      },
    },
  },
};
