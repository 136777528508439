<script>
export default {
  name: 'GalleryFilterItem',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    label: String,
    isOpen: Boolean,
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    labelSelected() {
      if (!this.selected.length) {
        return this.label;
      }
      if (this.selected.length === 1) {
        return this.label + ': ' + this.selected[0].label;
      }
      return this.label + ': ' + this.selected.length + ' filters';
    },
  },
  methods: {
    isSelected(option) {
      return this.selected.some((o) => o.value === option.value);
    },
  },
};
</script>

<template>
  <div class="filter-item">
    <div class="value" :class="{ open: isOpen, selected: selected.length > 0 }" @click.prevent.stop="$emit('open')">
      <div class="label">
        {{ labelSelected }}
      </div>
      <div class="arrow">
        <svg class="ico"><use xlink:href="#ico-arrow"></use></svg>
      </div>
    </div>
    <div class="options scroll-style" :class="{ open: isOpen }">
      <div v-for="option in options" :key="option.value" class="option" @click.prevent.stop="$emit('select', option)">
        <div class="checkbox" :class="{ active: isSelected(option) }">
          <svg class="ico" v-if="isSelected(option)"><use xlink:href="#ico-cross"></use></svg>
        </div>
        <div class="option-value">
          {{ option.label }} <span>{{ option.count }}</span>
        </div>
      </div>
      <div v-if="options.length === 0" class="option" style="cursor: default">No filters match</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filter-item {
  margin-bottom: 25px;
  position: relative;

  .value {
    background: #004444;
    display: flex;
    justify-content: space-between;
    border: 1px solid #356666;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    margin-bottom: 10px;

    &.open,
    &.selected,
    &:hover {
      border-color: #00ffff;

      .arrow {
        border-color: #00ffff;
      }
    }

    &.open {
      .arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .label {
      padding: 12px 16px;
    }
    .arrow {
      display: flex;
      align-items: center;
      border-left: 1px solid #356666;
      padding: 5px 20px;
      transition: 0.3s;

      svg {
        width: 8px;
        fill: #00ffff;
        transition: 0.3s;
        transform: rotate(0);
      }
    }
  }
}
.options {
  background: #004444;
  border: 1px solid #356666;
  padding: 0px 0px;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 5;
  transition: 0.3s;
  pointer-events: none;
  max-height: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  overflow: hidden;

  &.open {
    opacity: 1;
    pointer-events: auto;
    height: auto;
    max-height: 30vh;
    overflow: auto;
  }

  .option {
    padding: 15px 20px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0px;
      height: 1px;
      border-bottom: 1px solid #356666;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .option-value {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .checkbox {
      width: 20px;
      height: 20px;
      background: #071010;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      svg {
        width: 10px;
        height: 10px;
        fill: #00ffff;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
</style>
