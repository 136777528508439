<template>
  <div class="btc-mint__orders --padding btc-mint__container-wrapper">
    <div class="btc-mint__header">
      <h2>{{ $t('orders.statusCheck') }}</h2>
    </div>
    <div class="btc-mint__step">
      <h3 class="">Recipient Address:</h3>
      <p class="--mb-s">Enter your <b class="btc-mint__c-primary-desaturated">Ordinal Address</b> to see your orders:</p>
      <div class="btc-mint__input">
        <input type="text" placeholder="Your ordinal address..." v-model="myOrdAddress" />
        <div class="btc-mint__btn --primary --active" @click="getOrders">GET MY ORDERS</div>
      </div>
    </div>
    <div class="btc-mint__step">
      <h3 class="--mb">{{ $t('orders.pastOrders') }}</h3>
      <div class="btc-mint__rows --relative">
        <BtcLoaderGlobal :show="$parent.loaders.orders" />
        <div class="btc-mint__row btc-mint__bordered btc-mint__click" v-for="order in orders" :key="order.id" @click="select(order)" :class="order.state === 'COMPLETED' ? '--active' : ''">
          <h4>{{ order.id.substr(0, 15) }}...</h4>
          <span :class="order.state === 'COMPLETED' ? 'btc-mint__c-primary' : 'btc-mint__c-primary-desaturated'">
            {{ order.getState() }}
          </span>
          <span class="btc-mint__c-primary-desaturated">{{ order.createdAt.toLocaleDateString('en-US') }} {{ order.createdAt.toLocaleTimeString('en-US') }}</span>
        </div>
        <div v-if="orders.length <= 0">{{ $t('orders.noOrders') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BtcLoaderGlobal from '@/components/btc-mint/btcLoaderGlobal.vue';

export default {
  name: 'btcOrders',
  components: { BtcLoaderGlobal },
  data: () => ({
    myOrdAddress: '',
  }),
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.myOrdAddress = localStorage.getItem('_btc_ordinal_address');
  },
  methods: {
    select(order) {
      this.$parent.currentOrderId = order.id;
      this.$parent.menu = '';
    },
    getOrders() {
      this.$parent.getOrders(this.myOrdAddress, true);
    },
  },
};
</script>

<style scoped lang="scss"></style>
