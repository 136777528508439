export const FR = {
  header: {
    title: 'ENDANGERED LABS',
    subtitle: 'Endangered Labs est un studio <strong>Omniverse</strong> pour les êtres conscients. Ensemble, nous construirons un <strong>réseau axé sur l’autonomisation des créateurs</strong> via une propriété intellectuelle interopérable et des <strong>expériences gamifiées.</strong>',
  },
  menu: {
    constro: 'Le Constro.',
    identity: 'Identité.',
    narrative: 'Récit.',
    team: 'Équipe.',
    faq: 'FAQ.',
  },
  floating: {
    mint: 'Mint',
    premint: 'Rejoins nous',
    meme: {
      free: 'Free',
      text: 'Memetics',
    },
    trailer: {
      watch: 'Regardez le',
      trailer: 'Trailer',
    },
    game: {
      free: '',
      text: 'PLAY NOW',
    },
    download: {
      download: 'Télécharger',
      models: 'les models',
    },
  },
  constro: {
    title: 'Le Constro',
    detail1: 'Le Constro est un portail construit pour la prochaine génération de la culture Internet. Ensemble, nous accueillerons les Ailus <strong>dans l’économie virtuelle grâce à l’identité souveraine et des expériences gamifiées.</strong>',
    detail2: 'AILU, la collection originale, se compose de personnages de panda cybernétiques <strong>conçus pour la création de contenu sans friction</strong> à travers l’univers virtuel ouvert, les plateformes sociales et les moteurs de jeu.',
    detail3: 'Les Ailus sont dotés d’outils pour explorer de nouvelles formes d’expression et <strong>contribuer à l’effet de réseau ultime.</strong>',
    detail4: 'En tant qu’écosystème inclusif, nous sommes passionnés par la construction de marques, le <strong>récit collaboratif et les technologies émergentes.</strong> Les possibilités sont infinies et Le Constro s’étendra en permanence.',
  },
  identity: {
    title: 'Votre nouvelle identité',
    avatar: {
      title: 'Interopérabilité d’avatar',
      detail: 'Avec un <strong>modèle 3D</strong>, les utilisateurs peuvent interagir les uns avec les autres sur de nombreux systèmes et participer à des activités et des événements ensemble dans un <strong>espace virtuel partagé.</strong> Utilisez vos personnages sur les <strong>réseaux sociaux, les moteurs de jeu et les mondes virtuels.</strong>',
    },
    content: {
      title: 'Opportunités de création de contenu',
      detail: 'Des actifs optimisés pour la <strong>création de contenu sans friction</strong>, protégés par jeton, 3D et équipés de rigging. Utilisez votre Ailu pour <strong>créer des animations</strong>, <strong>les intégrer dans des jeux</strong> et <strong>diffuser avec capture de mouvement.</strong>',
    },
    identity: {
      title: 'Identité d’avatar',
      detail: 'Soyez votre Ailu sur Zoom, Twitch, Google Meets, VRchat et plus encore avec le suivi du visage et du corps. <strong>Aucun équipement de capture de mouvement n’est nécessaire.</strong> Juste votre Ailu et une webcam.',
    },
    printing: {
      title: 'Impression 3D',
      detail: "Vous pouvez imprimer en 3D votre modèle pour créer des <strong>jouets, sculptures et moules</strong> de votre Ailu. L'impression 3D vous permet de créer des objets personnalisés et de les produire en masse de manière efficace.",
    },
    types: {
      title: 'Tous les types de fichiers disponibles.',
      detail: '- Modèle 3D articulé<br/>\n' + ' - PNG avec fond <br/>\n' + ' - PNG avec fond transparent',
    },
  },
  narrative: {
    title: 'Récit',
    n1:
      '<span>> </span>\n' +
      'Vous êtes un PANDA AILU unique.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      "Nos CRÉATEURS vous ont créé en fusionnant de l'ADN de différentes étoiles pour RÉSURRECTER votre espèce.\n" +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      "Bienvenue à la COUCHE UN. C'est votre nouveau corps. Vous pourrez exister sous cette forme dans de nombreux systèmes. Votre code unique vous permet de prospérer dans n'importe quel univers que vous choisissez de visiter.",
    n2:
      '<span>> </span>\n' +
      'Les YEUX dans votre TÊTE et vos MAINS ont des fonctionnalités uniques.\n' +
      '<br/><br/>\n"' +
      '<span>> </span>\n' +
      'Votre PATTE AILU ouvrira de nombreuses portes. Utilisez-la pour accéder ou déverrouiller des VÉHICULES, des OBJETS, des PORTAILS, voire la CONSTRO elle-même.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      "Des faisceaux de plasma. L'enregistrement de la mémoire. La projection AR.\n" +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      "... D'autres améliorations sont à venir.",
    n3: '<span>> </span>\n' + 'La GÉOMÉTRIE sur votre BRAS CYBERNÉTIQUE est SACRÉE. Découvrir son plein potentiel prendra du temps.\n' + '<br/><br/>\n' + '<span>> </span>\n' + "Vos missions ne seront pas faciles. De nombreux jeux sont en cours et vous apprendrez à connaître d'autres PANDAS AILUS qui pourront vous aider dans le temps et l'espace.",
    n4:
      '<span>> </span>\n' +
      'Permettez-moi de me présenter correctement. Je suis KORII, la voix qui sert CONSTRO. Nos Créateurs ont établi cette IA sur TERRE au début de la GRANDE ASCENSION.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      "C'était une période d'avancées technologiques pour les humains alors que les machines et l'homme ne faisaient plus qu'un. Peu après, la race humaine s'est éloignée de ce que la nature avait prévu.\n" +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      'Dans le but de redevenir plus en phase avec qui ils étaient autrefois, les humains se sont fusionnés avec différentes espèces, redécouvrant les animaux en eux.',
    n5: '<span>> </span>\n' + " L'état de la Terre à l'heure actuelle reste inconnu.\n" + ' <br/><br/>\n' + ' <span>> </span>\n' + ' Tout ce qui est connu reste ici sur CONSTRO, notre foyer, un lieu entre les mondes.\n',
  },
  faq: {
    title: 'FAQ',
    questions: {
      q1: {
        question: "Qu'est-ce qu'Endangered Labs ?",
        answer: '<p>Endangered Labs est un studio Omniverse pour les êtres sensibles.\n' + ' <br/><br/>\n' + " Ensemble, nous construirons un état de réseau axé sur l'autonomisation des créateurs via des Artefacts digitals, une propriété intellectuelle interopérable et des expériences gamifiées.\n" + ' </p>',
      },
      q2: {
        question: "Qu'est-ce que la collection AILU ?",
        answer:
          "<p>AILU est la collection originale d'Endangered Labs.\n" +
          ' <br/><br/>\n' +
          " Collection d'avatars de pandas cybernétiques 3D, articulés et conçus pour autonomiser l'économie de créateurs pseudonymes avec une génération de contenu sans friction sur les réseaux sociaux et les moteurs de jeu.\n" +
          ' <br/><br/>\n' +
          ' Les Ailus vous permettent de créer du contenu grâce au suivi facial et corporel, sans aucune expérience préalable en 3D, animation ou capture de mouvement.\n' +
          ' </p>',
      },
      q3: {
        question: 'Que puis-je faire avec mes AILU ?',
        answer:
          '<p>Nous encourageons tous les membres de la communauté à canaliser leurs compétences créatives, leurs passions et leur inspiration vers la construction du métaverse ouvert.\n' +
          ' <br/><br/>\n' +
          " Nous recommandons vivement à chaque Ailu de travailler ensemble et de s'éduquer mutuellement sur les logiciels créatifs et les langages de programmation tels que Unreal Engine 5, Blender, Zbrush, Maya, Solidity, Rust et Python.\n" +
          ' <br/><br/>\n' +
          ' Voici quelques façons de tirer parti de votre Ailu :\n' +
          " Créer la lore et l'histoire uniques de votre panda.\n" +
          " Construire votre marque en tant qu'influenceur virtuel\n" +
          ' Produire de la musique en tant que votre personnage pour devenir une Metastar\n' +
          ' Créer des animations\n' +
          ' Concevoir des vêtements\n' +
          ' Impression 3D.\n' +
          '              <br/><br/>\n' +
          "              Les possibilités sont infinies, et nous ne faisons qu'effleurer la surface de la création de contenu.\n" +
          '              </p>',
      },
      v4: {
        question: 'Quelle est la date de sortie et le prix ?',
        answer: '<p>Des détails sur la date de mint et le prix seront annoncés sur notre 𝕏 à mesure que nous nous rapprocherons de la sortie de la collection.</p>',
      },
      q5: {
        question: 'Y a-t-il une liste de mint garantie ?',
        answer: `<p>Suivez <a href="https://twitter.com/Ailu2100" target="_blank">{'@'}endangeredlabs</a> pour avoir les infos.</p>`,
      },
      q6: {
        question: 'Quels sont les droits de propriété intellectuelle des titulaires de AILU ?',
        answer: '<p>Les propriétaires de AILU disposent de tous les droits commerciaux. Voir tous les détails dans<a href="Endangered_Labs_Terms.pdf" target="_blank">Terms.</a></p>',
      },
      q7: {
        question: "Il y a t'il une roadmap",
        answer:
          "<p>Le Web3 est en constante évolution, et Endangered Labs est construit pour s'adapter à un avenir en mutation. Nous ne voulons pas nous confiner à une feuille de route stricte car nous devons être polyvalents pour livrer un produit réussi.\n" +
          '                <br/><br/>\n' +
          "              Notre objectif est d'exécuter et de livrer. Pas besoin de feuille de route.\n" +
          '                <br/><br/>\n' +
          "              Pour l'instant, vous pouvez vous faire une idée de ce sur quoi nous travaillons en lisant le Récit.\n" +
          '              </p>',
      },
    },
  },
  footer: {
    backtotop: 'Retour au début',
    followus: 'Suivez nous',
    ontwitter: 'Sur 𝕏',
    contact: 'Contact.',
    terms: 'Conditions.',
    privacy: 'Confidentialité.',
  },
  downloadmodule: {
    title: 'Télécharger les fichiers',
    model: 'Model 3D',
    fullview: 'Vue intégrale',
    nobackground: 'Sans fond',
    glb: 'fichier glb',
    png: 'fichier png',
    pfp: 'PFP',
    nobg: 'Sans fond',
    modelA: 'Model - Pose A',
    modelT: 'Model - Pose T',
  },
};
