<template>
  <div>
    <section id="loading" :class="{ ready: ready, close: close }">
      <div class="content">
        <video autoplay muted playsinline loop height="230">
          <source src="videos/Holo-hevc-safari.mp4" type="video/mp4;codecs=hvc1" />
          <source src="videos/Holo-vp9-chrome.webm" type="video/webm" />
        </video>
        <div class="sep"></div>
        <div class="loader">
          <span :style="{ width: progression + '%' }" class="progress"></span>
          <span class="btn-ready" id="btn-music-play" @click="onEnter">ENTER</span>
        </div>
        <div v-if="!ready" class="progress-status">Loading system...</div>
        <div v-if="ready" class="progress-status">System loaded successfully</div>
        <div class="version">v 246.4b048 7a30</div>
      </div>
    </section>

    <div id="popup-yt" v-if="popupOpen">
      <div class="popup-yt-content">
        <div class="close-popup-yt" @click="popupOpen = false">
          <svg class="ico">
            <use xlink:href="#ico-more"></use>
          </svg>
        </div>
        <iframe class="popup-video" src="https://www.youtube.com/embed/SZ7kLvWPiqE" height="100%" allowfullscreen="allowfullscreen"></iframe>
      </div>
    </div>

    <btcMintPopup v-if="openBtcMint" @close="openBtcMint = false" />

    <header>
      <div class="container topBar row">
        <div class="left row load-hidden">
          <div class="logo">
            <svg class="ico">
              <use xlink:href="#ico-logo"></use>
            </svg>
          </div>
          <div class="dropdown-container">
            <div class="dropdown-toggle">
              <div class="language-active">{{ $i18n.locale }}</div>
              <div class="dropdown-arrow">
                <svg class="ico">
                  <use xlink:href="#ico-arrow"></use>
                </svg>
              </div>
            </div>
            <ul class="dropdown-menu">
              <li v-for="locale of $i18n.availableLocales" :key="locale" @click="$i18n.locale = locale"><span></span>{{ locale }}</li>
            </ul>
          </div>
          <div class="btn-burger">
            <span class="btn-burger-title">Menu</span>
            <div class="cross">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div class="menu row">
          <nav class="load-hidden">
            <a href="#constro" @click.prevent.stop="scrollTo('constro')">{{ $t('menu.constro') }}</a>
            <a href="#hub">{{ $t('menu.identity') }}</a>
            <a href="#narrative" @click.prevent.stop="scrollTo('narrative')">{{ $t('menu.narrative') }}</a>
            <a href="#team" @click.prevent.stop="scrollTo('team')">{{ $t('menu.team') }}</a>
            <!-- <a href="#faq">{{ $t('menu.faq') }}</a> -->
          </nav>
          <ul class="social load-hidden">
            <li class="social-link">
              <SocialTwitter url="https://twitter.com/Ailu2100" />
            </li>
            <li class="social-link">
              <SocialTelegram url="https://t.me/ailu2100" />
            </li>
            <li class="social-link">
              <SocialDiscord url="https://discord.gg/AILU" />
            </li>
            <li class="social-link">
              <SocialMagicEden url="https://magiceden.io/ordinals/marketplace/ailu" />
            </li>
            <li class="social-link">
              <SocialSoundCloud url="https://soundcloud.com/endangeredlabs" />
            </li>
            <li class="social-link">
              <SocialInstagram url="https://instagram.com/ailu2100" />
            </li>
          </ul>
        </div>
      </div>
      <div class="headerBanner" v-if="close">
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light left ligh-1" />
        <video autoplay muted playsinline>
          <source src="videos/Intro-hevc-safari.mp4" type="video/mp4;codecs=hvc1" />
          <source src="videos/Intro-vp9-chrome.webm" type="video/webm" />
        </video>
        <div class="bannerLogo load-hidden-header-text"></div>
      </div>
    </header>

    <div class="container btns-home">
      <div class="fixed-btn load-hidden-bigBtn">
        <a class="btn btn-big" href="#hub">
          <div class="text">
            <span>{{ 'AILU HUB' }}</span>
          </div>
          <div class="icon">
            <img src="/images/site/ico_mint.png" alt="" />
          </div>
        </a>
        <a class="btn btn-big btn-big-secondary" @click="popupOpen = true">
          <div class="text">
            <span class="sub-text">{{ $t('floating.trailer.watch') }}</span>
            <span>{{ $t('floating.trailer.trailer') }}</span>
          </div>
          <div class="icon">
            <svg class="ico">
              <use xlink:href="#ico-play"></use>
            </svg>
          </div>
        </a>
        <a class="btn btn-big btn-big-secondary" href="https://memes.endangeredlabs.io/" target="_blank">
          <div class="text">
            <span>{{ $t('floating.meme.text') }}</span>
          </div>
          <div class="icon">
            <img src="/images/site/ico_meme.png" />
          </div>
        </a>
        <a class="btn btn-big btn-big-secondary" href="https://satoshisurfer.endangeredlabs.io/" target="_blank">
          <div class="text">
            <span>{{ $t('floating.game.text') }}</span>
          </div>
          <div class="icon">
            <img src="/images/site/ico_game.png" alt="" />
          </div>
        </a>
      </div>
    </div>

    <section id="endangeredLabs">
      <div class="container">
        <div class="title load-hidden">
          <div class="title-decoration load-hidden">
            <p><span>Data</span>01011001001</p>
            <p>////////</p>
          </div>
          <h2 id="messengertitle" class="load-hidden">{{ $t('header.title') }}</h2>
        </div>
        <p class="project-description c-4 load-hidden" v-html="$t('header.subtitle')"></p>
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light right ligh-2" />
      </div>
    </section>

    <section id="constro">
      <div class="container row">
        <div class="constro-anim c-2">
          <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="maze-light" />
          <img src="images/site/svg/constro-decoration.svg" alt="Decorative circles around Constro" class="constro-decoration" />
          <div class="constro-orbit">
            <img src="images/site/svg/constro-orbit.svg" alt="Rotating Orbit" />
          </div>
          <img src="images/site/constro-orbit-decoration.png" alt="Sci-fi decoration" class="constro-orbit-decoration" />

          <video autoplay muted playsinline loop width="600" height="600">
            <source src="videos/Constro-hevc-safari.mp4" type="video/mp4;codecs=hvc1" />
            <source src="videos/Constro-vp9-chrome.webm" type="video/webm" />
          </video>
        </div>
        <div class="constro-lore c-3">
          <div class="title">
            <div class="title-decoration load-hidden">
              <p><span>Data</span>10010100110</p>
              <p>////////</p>
            </div>
            <h2 id="constrotitle" class="load-hidden">{{ $t('constro.title') }}</h2>
          </div>
          <p class="lore load-hidden" v-html="$t('constro.detail1')"></p>
          <p class="lore load-hidden" v-html="$t('constro.detail2')"></p>
          <p class="lore load-hidden" v-html="$t('constro.detail3')"></p>
          <p class="lore load-hidden" v-html="$t('constro.detail4')"></p>
        </div>
      </div>
    </section>

    <section id="identity">
      <div class="container load-hidden-constro">
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light right ligh-3" />
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light left ligh-4" />
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light right ligh-5" />
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light left ligh-6" />

        <div class="title">
          <div class="title-decoration load-hidden">
            <p><span>Data</span>01001011001</p>
            <p>////////</p>
          </div>
          <h2 id="identitytitle" class="load-hidden">{{ $t('identity.title') }}</h2>
        </div>

        <div class="identity-file">
          <div class="file load-hidden">
            <div class="file-title">{{ $t('identity.avatar.title') }}</div>
            <div class="row">
              <div class="file-decoration">
                <img src="images/site/svg/folder.svg" alt="Folder Icon" />
                <img src="images/site/svg/file-decoration.svg" alt="Sci-fi decoration under the folder icon" />
              </div>
              <div class="file-content">
                <p v-html="$t('identity.avatar.detail')"></p>
                <div class="file-content-decoration">
                  <img src="images/site/svg/file-content-decaoration-left.svg" alt="Decorative Barcode" />
                  <img src="images/site/svg/file-content-decaoration-right.svg" alt="Decorative Binari" />
                </div>
              </div>
            </div>
          </div>
          <div class="file-img load-hidden">
            <img src="images/site/avatar-interoperability.png" alt="Avatar Interoperability" class="file-img-content" />
          </div>
        </div>

        <div class="identity-file">
          <div class="file load-hidden">
            <div class="file-title">{{ $t('identity.content.title') }}</div>
            <div class="row">
              <div class="file-decoration">
                <img src="images/site/svg/folder.svg" alt="Folder Icon" />
                <img src="images/site/svg/file-decoration.svg" alt="Sci-fi decoration under the folder icon" />
              </div>
              <div class="file-content">
                <p v-html="$t('identity.content.detail')"></p>
                <div class="file-content-decoration">
                  <img src="images/site/svg/file-content-decaoration-left.svg" alt="Decorative Barcode" />
                  <img src="images/site/svg/file-content-decaoration-right.svg" alt="Decorative Binari" />
                </div>
              </div>
            </div>
          </div>
          <div class="file-img load-hidden">
            <img src="images/site/content-creation-opportunities.png" alt="Panda in a spaceship" class="file-img-content" />
          </div>
        </div>

        <div class="identity-file">
          <div class="file load-hidden">
            <div class="file-title">{{ $t('identity.identity.title') }}</div>
            <div class="row">
              <div class="file-decoration">
                <img src="images/site/svg/folder.svg" alt="Folder Icon" />
                <img src="images/site/svg/file-decoration.svg" alt="Sci-fi decoration under the folder icon" />
              </div>
              <div class="file-content">
                <p v-html="$t('identity.identity.detail')"></p>
                <div class="file-content-decoration">
                  <img src="images/site/svg/file-content-decaoration-left.svg" alt="Decorative Barcode" />
                  <img src="images/site/svg/file-content-decaoration-right.svg" alt="Decorative Binari" />
                </div>
              </div>
            </div>
          </div>
          <div class="file-img load-hidden">
            <img src="images/site/avatar-identity.png" alt="Ongoing call between pandas" class="file-img-content" />
          </div>
        </div>

        <div class="identity-file">
          <div class="file load-hidden">
            <div class="file-title">{{ $t('identity.printing.title') }}</div>
            <div class="row">
              <div class="file-decoration">
                <img src="images/site/svg/folder.svg" alt="Folder Icon" />
                <img src="images/site/svg/file-decoration.svg" alt="Sci-fi decoration under the folder icon" />
              </div>
              <div class="file-content">
                <p v-html="$t('identity.printing.detail')"></p>
                <div class="file-content-decoration">
                  <img src="images/site/svg/file-content-decaoration-left.svg" alt="Decorative Barcode" />
                  <img src="images/site/svg/file-content-decaoration-right.svg" alt="Decorative Binari" />
                </div>
              </div>
            </div>
          </div>
          <div class="file-img load-hidden">
            <img src="images/site/3d-printing.png" alt="Printed Panda on each side" class="file-img-content" />
          </div>
        </div>

        <div class="identity-file">
          <div class="file load-hidden">
            <div class="file-title">{{ $t('identity.types.title') }}</div>
            <div class="row">
              <div class="file-decoration">
                <img src="images/site/svg/folder.svg" alt="Folder Icon" />
                <img src="images/site/svg/file-decoration.svg" alt="Sci-fi decoration under the folder icon" />
              </div>
              <div class="file-content">
                <p v-html="$t('identity.types.detail')"></p>
                <div class="file-content-decoration">
                  <img src="images/site/svg/file-content-decaoration-left.svg" alt="Decorative Barcode" />
                  <img src="images/site/svg/file-content-decaoration-right.svg" alt="Decorative Binari" />
                </div>
              </div>
            </div>
          </div>
          <div class="file-img load-hidden">
            <img src="images/site/all-file-types-available.png" alt="Pandas in a 3D software" class="file-img-content" />
          </div>
        </div>
      </div>
    </section>

    <section id="narrative">
      <div class="container">
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light right ligh-7" />

        <div class="title">
          <div class="title-decoration load-hidden">
            <p><span>Data</span>1010110001</p>
            <p>////////</p>
          </div>
          <h2 id="narrativetitle" class="load-hidden">{{ $t('narrative.title') }}</h2>
        </div>

        <div class="narrative-screen load-hidden">
          <div class="narrative-screen-decoration">
            <img src="images/site/svg/barcode.svg" alt="Decorative Barcode" />
            <img src="images/site/svg/narrative-screen-decoration.svg" alt="Decorative Sci-fi shape" />
          </div>
          <div class="narrative-component">
            <div class="narrative-prompt scroll-style" v-if="narrative === 1">
              <p class="prompt-title">C://narrative/geometry:<br /><br /></p>
              <div v-html="$t('narrative.n1')"></div>
            </div>

            <div class="narrative-prompt scroll-style" v-if="narrative === 2">
              <p class="prompt-title">C://narrative/geometry:<br /><br /></p>
              <div v-html="$t('narrative.n2')"></div>
            </div>

            <div class="narrative-prompt scroll-style" v-if="narrative === 3">
              <p class="prompt-title">C://narrative/geometry:<br /><br /></p>
              <div v-html="$t('narrative.n3')"></div>
            </div>

            <div class="narrative-prompt scroll-style" v-if="narrative === 4">
              <p class="prompt-title">C://narrative/geometry:<br /><br /></p>
              <div v-html="$t('narrative.n4')"></div>
            </div>

            <div class="narrative-prompt scroll-style" v-if="narrative === 5">
              <p class="prompt-title">C://narrative/geometry:<br /><br /></p>
              <div v-html="$t('narrative.n5')"></div>
            </div>

            <div class="narrative-img load-hidden">
              <div class="narrative-img-decoration"></div>
              <img :src="'images/site/narrative-pagination-img-' + narrative + (narrative === 2 ? 'b' : '') + '.png'" alt="Sketch of a Panda" height="380" />
            </div>
          </div>
          <ul class="narrative-pagination scroll-style">
            <li @click="narrative = 1" class="active narrative-pagination-page load-hidden">
              <div class="activeAnimation">
                <span class="center"></span>
              </div>
              <img src="images/site/narrative-pagination-img-1.png" alt="Sketch of a Panda" class="narrative-pagination-img" />
              <svg class="ico file-icon">
                <use xlink:href="#ico-file"></use>
              </svg>
            </li>
            <li @click="narrative = 3" class="narrative-pagination-page load-hidden">
              <div class="activeAnimation">
                <span class="center"></span>
              </div>
              <img src="images/site/narrative-pagination-img-3.png" alt="Sketch of a hand" class="narrative-pagination-img" />
              <svg class="ico file-icon">
                <use xlink:href="#ico-file"></use>
              </svg>
            </li>
            <li @click="narrative = 2" class="narrative-pagination-page load-hidden">
              <div class="activeAnimation">
                <span class="center"></span>
              </div>
              <img src="images/site/narrative-pagination-img-2b.png" alt="Panda Portrait" class="narrative-pagination-img" />
              <svg class="ico file-icon">
                <use xlink:href="#ico-file"></use>
              </svg>
            </li>
            <li @click="narrative = 4" class="narrative-pagination-page load-hidden">
              <div class="activeAnimation">
                <span class="center"></span>
              </div>
              <img src="images/site/narrative-pagination-img-4.png" alt="Holographic Panda" class="narrative-pagination-img" />
              <svg class="ico file-icon">
                <use xlink:href="#ico-file"></use>
              </svg>
            </li>
            <li @click="narrative = 5" class="narrative-pagination-page load-hidden">
              <div class="activeAnimation">
                <span class="center"></span>
              </div>
              <img src="images/site/narrative-pagination-img-5.png" alt="Abstract illustration" class="narrative-pagination-img" />
              <svg class="ico file-icon">
                <use xlink:href="#ico-file"></use>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section id="team">
      <div class="container">
        <div class="title">
          <div class="title-decoration load-hidden">
            <p><span>Data</span>01110100101</p>
            <p>////////</p>
          </div>
          <h2 id="theteamtitle" class="load-hidden">the team</h2>
        </div>
        <div class="row">
          <div class="team-content c-3">
            <div class="team-content-viewer">
              <div class="team-corner-decoration">
                <span class="topRight"></span>
                <span class="bottomRight"></span>
              </div>
              <div class="left-decoration load-hidden">
                <div class="team-side-decoration"></div>
                <ul class="size">
                  <li>
                    <span></span>
                    <p>200</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°1" />
                  </li>
                  <li>
                    <span></span>
                    <p>190</p>
                  </li>
                  <li>
                    <span></span>
                    <p>180</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°2" />
                  </li>
                  <li>
                    <span></span>
                    <p>170</p>
                  </li>
                  <li>
                    <span></span>
                    <p>160</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°3" />
                  </li>
                  <li>
                    <span></span>
                    <p>150</p>
                  </li>
                  <li>
                    <span></span>
                    <p>140</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°4" />
                  </li>
                  <li>
                    <span></span>
                    <p>130</p>
                  </li>
                  <li>
                    <span></span>
                    <p>120</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°5" />
                  </li>
                  <li>
                    <span></span>
                    <p>110</p>
                  </li>
                  <li>
                    <span></span>
                    <p>100</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°6" />
                  </li>
                  <li>
                    <span></span>
                    <p>90</p>
                  </li>
                  <li>
                    <span></span>
                    <p>80</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°7" />
                  </li>
                  <li>
                    <span></span>
                    <p>70</p>
                  </li>
                  <li>
                    <span></span>
                    <p>60</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°8" />
                  </li>
                  <li>
                    <span></span>
                    <p>50</p>
                  </li>
                  <li>
                    <span></span>
                    <p>40</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°9" />
                  </li>
                  <li>
                    <span></span>
                    <p>30</p>
                  </li>
                  <li>
                    <span></span>
                    <p>20</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°10" />
                  </li>
                  <li>
                    <span></span>
                    <p>10</p>
                  </li>
                  <li>
                    <span></span>
                    <p>0</p>
                    <img src="images/site/svg/cross.svg" alt="Cross icon n°11" />
                  </li>
                </ul>
              </div>
              <div class="viewer load-hidden" ref="viewer3D">
                <div class="qrcode" :class="{ active: ar }">
                  <p>Scan with your smart phone</p>
                  <img :src="'images/site/qrcode-' + team + '.png'" alt="QrCode" />
                </div>
                <!-- ################################## -->
                <!--             3D VIEWER              -->
                <!-- ################################## -->
                <!--                <NFTViewer :assets3d="assets3d" @ready="nftViewerReady = true" v-if="!isMobileView" />-->
                <div class="viewer-mobile">
                  <img :src="assets3d.image" alt="" />
                </div>
              </div>
            </div>
            <div class="team-content-info row">
              <div class="team-corner-decoration load-hidden">
                <span class="topRight"></span>
                <span class="bottomRight"></span>
              </div>
              <div class="left-decoration load-hidden">
                <div class="team-side-decoration"></div>
                <ul class="square-decoration">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              <div class="info" v-if="team === 'metapanda'">
                <p><span id="team-name">Metapanda</span><br /><span id="team-job">Alchemist</span></p>
                <ul class="social">
                  <li class="social-link">
                    <a href="https://twitter.com/0xMetapanda" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-twitter"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="social-link">
                    <a href="https://www.linkedin.com/in/metapanda/" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-linkedin"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="social-link">
                    <a href="https://www.instagram.com/0xmetapanda/" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-instagram"></use>
                      </svg>
                    </a>
                  </li>
                  <!-- <li><div class="btn btn-ar" :class="{active: ar}" @click="ar = !ar"><span v-if="!ar">ar mode</span><span v-if="ar">close</span></div></li> -->
                </ul>
              </div>

              <div class="info" v-if="team === 'cyber'">
                <p><span id="team-name">Cyber</span><br /><span id="team-job">Operations</span></p>
                <ul class="social">
                  <li class="social-link">
                    <a href="https://twitter.com/0xCYBERPANDA" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-twitter"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="social-link">
                    <a href="https://www.linkedin.com/in/cyberpanda/" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-linkedin"></use>
                      </svg>
                    </a>
                  </li>
                  <!-- <li><div class="btn btn-ar" :class="{active: ar}" @click="ar = !ar"><span v-if="!ar">ar mode</span><span v-if="ar">close</span></div></li> -->
                </ul>
              </div>

              <div class="info" v-if="team === 'shadow'">
                <p><span id="team-name">Shadow</span><br /><span id="team-job">Creative Director</span></p>
                <ul class="social">
                  <li class="social-link">
                    <a href="https://twitter.com/0xShadowPanda" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-twitter"></use>
                      </svg>
                    </a>
                  </li>
                  <li class="social-link">
                    <a href="https://www.linkedin.com/in/shadowpanda/" target="_blank">
                      <svg class="ico">
                        <use xlink:href="#ico-linkedin"></use>
                      </svg>
                    </a>
                  </li>
                  <!-- <li><div class="btn btn-ar" :class="{active: ar}" @click="ar = !ar"><span v-if="!ar">ar mode</span><span v-if="ar">close</span></div></li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="idcard-list c-2">
            <div @click="card('metapanda')" :class="{ active: team === 'metapanda' }" class="idcard load-hidden">
              <svg class="idcard-side-decoration">
                <use xlink:href="#deco-idcard-side"></use>
              </svg>
              <div class="idcard-decoration-top">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div class="idcard-content">
                <div class="idcard-name">Metapanda</div>
                <span class="idcard-separation"></span>
                <div class="idcard-spec">
                  <div class="idcard-picture">
                    <img src="images/site/pfp-metapanda.png" alt="Metapanda's avatar" />
                  </div>
                  <ul class="idcard-infos">
                    <li>ID: 8b7i-h3y7s 45</li>
                    <li>Address: Constro</li>
                    <li>Class: Alchemist</li>
                  </ul>
                </div>
              </div>
              <div class="idcard-decoration-bottom">
                <div class="left">
                  <svg class="ico">
                    <use xlink:href="#ico-barcode"></use>
                  </svg>
                  <p>01011001001</p>
                </div>
                <p class="right">9nr5fvk787 - 41640</p>
              </div>
            </div>
            <!--            <div @click="card('cyber')" :class="{ active: team === 'cyber' }" class="idcard load-hidden">-->
            <!--              <svg class="idcard-side-decoration"><use xlink:href="#deco-idcard-side"></use></svg>-->
            <!--              <div class="idcard-decoration-top">-->
            <!--                <span></span>-->
            <!--                <span></span>-->
            <!--                <span></span>-->
            <!--                <span></span>-->
            <!--                <span></span>-->
            <!--                <span></span>-->
            <!--              </div>-->
            <!--              <div class="idcard-content">-->
            <!--                <div class="idcard-name">Cyber</div>-->
            <!--                <span class="idcard-separation"></span>-->
            <!--                <div class="idcard-spec">-->
            <!--                  <div class="idcard-picture">-->
            <!--                    <img src="images/site/pfp-cyber.png" alt="Cyber's avatar" />-->
            <!--                  </div>-->
            <!--                  <ul class="idcard-infos">-->
            <!--                    <li>ID: 25jt-5ge49 m4</li>-->
            <!--                    <li>Address: The Constro</li>-->
            <!--                    <li>Class: Operations</li>-->
            <!--                  </ul>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="idcard-decoration-bottom">-->
            <!--                <div class="left">-->
            <!--                  <svg class="ico"><use xlink:href="#ico-barcode"></use></svg>-->
            <!--                  <p>10110101001</p>-->
            <!--                </div>-->
            <!--                <p class="right">2d2cz6c92h - 64103</p>-->
            <!--              </div>-->
            <!--            </div>-->
            <div @click="card('shadow')" :class="{ active: team === 'shadow' }" class="idcard load-hidden">
              <svg class="idcard-side-decoration">
                <use xlink:href="#deco-idcard-side"></use>
              </svg>
              <div class="idcard-decoration-top">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div class="idcard-content">
                <div class="idcard-name">Shadow</div>
                <span class="idcard-separation"></span>
                <div class="idcard-spec">
                  <div class="idcard-picture">
                    <img src="images/site/pfp-shadow.png" alt="Shadow's avatar" />
                  </div>
                  <ul class="idcard-infos">
                    <li>ID: 423q-na3wv 64</li>
                    <li>Address: Constro</li>
                    <li>Class: Creative Director</li>
                  </ul>
                </div>
              </div>
              <div class="idcard-decoration-bottom">
                <div class="left">
                  <svg class="ico">
                    <use xlink:href="#ico-barcode"></use>
                  </svg>
                  <p>11010100001</p>
                </div>
                <p class="right">8s9ry75t7j - 10031</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section id="faq">
      <div class="container row">
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light left ligh-8" />
        <img src="images/site/maze-light.png" alt="Light effect with a maze pattern" class="background-light right ligh-9" />
        <div class="faq-container c-3">
          <div class="title">
            <div class="title-decoration load-hidden">
              <p><span>Data</span>001010001101</p>
              <p>////////</p>
            </div>
            <h2 id="faqtitle" class="load-hidden">faq</h2>
          </div>
          <ul class="faqList">
            <li class="item load-hidden" v-for="i in [1, 2, 3, 4, 5, 6, 7]" :key="i" @click="openItem(i)" :class="{ open: open[i] }">
              <svg class="sd-card"><use xlink:href="#sd-card"></use></svg>
              <div class="item-content">
                <div class="item-decoration">
                  <svg><use xlink:href="#deco-faq-side"></use></svg>
                </div>
                <div class="question">
                  <div class="question-decoration"></div>
                  <div class="questionTitle">
                    <span>{{ i }}.</span>{{ $t('faq.questions.q' + i + '.question') }}
                  </div>
                  <div class="btnFaq">
                    <svg class="ico"><use xlink:href="#ico-more"></use></svg>
                  </div>
                </div>
                <div class="answer" v-html="$t('faq.questions.q' + i + '.answer')"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section> -->

    <div id="surfer" class="load-hidden">
      <video autoplay muted playsinline loop>
        <source src="videos/Surfer-hevc-safari.mp4" type="video/mp4;codecs=hvc1" />
        <source src="videos/Surfer-vp9-chrome.webm" type="video/webm" />
      </video>
    </div>

    <section id="join" class="load-hidden">
      <div class="background-light ligh-10"><img src="images/site/maze-light.png" alt="Light effect with a maze pattern" /></div>

      <div class="join-top-decoration">
        <div class="btn-back load-hidden" @click="scrollToTop">
          {{ $t('footer.backtotop') }}
          <svg>
            <use xlink:href="#btn-back"></use>
          </svg>
        </div>
        <img src="images/site/svg/join-top-decoration.svg" alt="Decorative Sci-fi shape" />
      </div>
      <div class="join-bottom-part load-hidden">
        <div class="container">
          <a class="btn-join" href="https://twitter.com/Ailu2100" target="_blank">
            <span>{{ $t('footer.followus') }}</span>
            <span>{{ $t('footer.ontwitter') }}</span>
            <div class="btn-join-animation">
              <div class="arrow">
                <svg class="ico">
                  <use xlink:href="#ico-arrow"></use>
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>

    <footer>
      <div class="container">
        <div class="load-hidden">
          <a href="https://twitter.com/Ailu2100" style="color: white" target="_blank"> © Endangered Labs </a>
        </div>
        <ul>
          <li class="load-hidden">
            <a href="mailto:press@endangeredlabs.io?subject=Info%20request">{{ $t('footer.contact') }}</a>
          </li>
          <li class="load-hidden">
            <a href="Endangered_Labs_Terms.pdf" target="_blank">{{ $t('footer.terms') }}</a>
          </li>
          <li class="load-hidden">
            <a href="Endangered_Labs_Privacy_Policy.pdf" target="_blank">{{ $t('footer.privacy') }}</a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import asset3d_1 from '../viewer3d/characters/1';
import asset3d_2 from '../viewer3d/characters/2';
import asset3d_3 from '../viewer3d/characters/3';

import NFTViewer from '../components/NFTViewer';
import btcMintPopup from '@/components/btc-mint/btcMintPopup.vue';
import SocialTwitter from '@/components/gallery/social/SocialTwitter.vue';
import SocialTelegram from '@/components/gallery/social/SocialTelegram.vue';
import SocialDiscord from '@/components/gallery/social/SocialDiscord.vue';
import SocialMagicEden from '@/components/gallery/social/SocialMagicEden.vue';
import SocialInstagram from '@/components/gallery/social/SocialInstagram.vue';
import SocialSoundCloud from '@/components/gallery/social/SocialSoundCloud.vue';

export default {
  name: 'Home',
  components: {
    btcMintPopup,
    SocialSoundCloud,
    SocialInstagram,
    SocialMagicEden,
    SocialDiscord,
    SocialTelegram,
    SocialTwitter,
  },
  data: () => ({
    open: { 0: false },
    narrative: 1,
    team: 'metapanda',
    ready: true,
    close: true,
    progression: 0,
    ar: false,
    musicPlaying: false,
    popupOpen: false,
    nftViewerReady: false,
    isMobileView: true,
    openBtcMint: false,
  }),
  computed: {
    assets3d() {
      switch (this.team) {
        case 'metapanda':
          return asset3d_1;
        case 'cyber':
          return asset3d_2;
        case 'shadow':
          return asset3d_3;
      }

      return asset3d_1;
    },
  },
  methods: {
    openItem(key) {
      this.open[key] = this.open[key] ? false : true;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    card(name) {
      this.team = name;
    },
    checkForMobileDevice() {
      this.isMobileView = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    onEnter() {
      this.close = true;

      if (document.location.hash === '#mint') {
        this.openBtcMint = true;
      }

      window.runScrollReveal();
      window.isLoaded = true;
    },
    scrollTo(to) {
      window.scrollTo({
        top: document.getElementById(to).getBoundingClientRect().top,
      });
    },
  },
  async mounted() {
    this.checkForMobileDevice();
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    while (!window.runScrollReveal) {
      await delay(100);
    }
    window.runScrollReveal();

    // if (window.isLoaded) {
    //   setTimeout(() => {
    //     this.progression = 100;
    //     this.close = true;
    //
    //     window.runScrollReveal();
    //   }, 300);
    //
    //   return;
    // }

    const pageLoading = async () => {
      await delay(50);
      while (this.progression < 100) {
        await delay(20);
        this.progression++;
      }
      await delay(50);
      this.ready = true;
    };

    await pageLoading();
  },
};
</script>
