<template>
  <div class="btc-mint__closed">
    <div class="btc-mint__row --align-center">
      <div class="btc-mint__closed__left">
        <h2 v-if="isClose && privateIsOpen">BE BACK SOON</h2>
        <h2 v-if="isClose && !privateIsOpen">ACCESS LIST</h2>
        <h2 v-else-if="isSoldOut">SOLDOUT</h2>
        <p v-if="isClose && privateIsOpen" class="btc-mint__c-white">Please Be Patient Anon.</p>
        <div v-if="!privateIsOpen">Opens in <BtcCountdown :date="new Date(1713106815000).toString()" @done="privateIsOpen = true" /></div>
        <p v-else-if="isSoldOut" class="btc-mint__c-white">All ordinals in this collection have been reserved.</p>

        <div v-if="collection.hasWhitelist && !privateIsOpen" class="btc-mint__step" style="margin-top: 50px">
          <h3>Access List Checker</h3>
          <input type="text" :placeholder="$t('mint.wallet.placeholder')" v-model="ordAddress" />
          <div class="btc-mint__step --relative" @click="checkWhitelist" v-if="!privateIsOpen" style="margin-top: 10px">
            <BtcLoaderGlobal :show="$parent.loaders.checkWhitelist" />
            <div class="btc-mint__btn --active --big">{{ $t('mint.check') }}</div>
          </div>
        </div>
      </div>
      <div class="btc-mint__closed__image">
        <img src="/images/site/narrative-pagination-img-2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { Collection } from './collection';
import BtcCountdown from '@/components/btc-mint/btcCountdown.vue';
import BtcLoaderGlobal from '@/components/btc-mint/btcLoaderGlobal.vue';
import api from '@/components/btc-mint/api';

export default {
  name: 'btcSalesClosed',
  components: { BtcLoaderGlobal, BtcCountdown },
  props: {
    collection: {
      type: Collection,
      default: () => new Collection(),
    },
  },
  data: function () {
    return {
      ordAddress: null,
      privateIsOpen: false,
      publicIsOpen: false,
    };
  },

  computed: {
    isClose() {
      return false;
      // return this.collection && this.collection.isClosed();
    },
    isSoldOut() {
      return true;
      // return this.collection && this.collection.isSoldOut();
    },
  },
  methods: {
    async checkWhitelist() {
      if (!this.ordAddress) {
        return;
      }
      if (!this.ordAddress.startsWith('bc1p')) {
        return this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.notTaproot') });
      }
      await this.$parent.setLoader('checkWhitelist', true);
      const result = await api.post(`collection/${this.collection.id}/check`, {
        address: this.ordAddress, //this.userOrdAddressWhitelist,
        password: this.$parent.currentPasswordWhitelist,
      });

      if (result.error()) {
        await this.$parent.setLoader('checkWhitelist', false);
        //@TODO gérer les erreur
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.whitelist') });
        return;
      }

      await this.$parent.setLoader('checkWhitelist', false);
      const data = result.data();

      this.userOrdAddressIsValid = data.addressIsValid;
      if (!this.userOrdAddressIsValid) {
        this.$parent.showNotif({ type: 'error', text: this.$t('mint.notifs.errors.whitelistWalletBad') });
      } else {
        this.$parent.showNotif({ type: 'success', text: this.$t('mint.notifs.errors.whitelisted') });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#btc-mint {
  .btc-mint__closed {
    overflow: hidden;
  }
  .btc-mint__closed__left {
    padding: 30px 0 30px 30px;
    width: 50%;
  }
  .btc-mint__closed__image {
    width: 50%;
    position: relative;
    text-align: right;

    img {
      height: 100%;
      margin-bottom: -8px;
    }
  }
}
</style>
