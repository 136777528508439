import axios from 'axios';
export default {
  baseURL: '',
  mockUrls: {
    // "collection/[0-9]+": 'https://run.mocky.io/v3/7edb90e6-4915-4f6f-862c-4728b0319e6c',
    // "amount/[0-9]+/1/[0-9]+": 'https://run.mocky.io/v3/63463916-8dd1-4bde-93c8-54c9ee2570de',
    // "amount/[0-9]+/2/[0-9]+": 'https://run.mocky.io/v3/503e7005-1cf4-4873-8786-a9c1ba817b98',
    // "amount/[0-9]+/3/[0-9]+": 'https://run.mocky.io/v3/6d70c540-c958-4af0-94b9-085df67b5cf7',
    // "https://cex.io/api/last_price/BTC/USD": 'http://localhost:5000/api/last_price/BTC/USD',
    // "https://mempool.space/api/v1/fees/recommended": 'http://localhost:5000/api/v1/fees/recommended',
  },
  init(baseUrl) {
    this.baseURL = baseUrl;
  },
  async run(method, url, options) {
    const response = new apiResponse();
    let res = null;
    try {
      for (const reg in this.mockUrls) {
        if (url.match(reg)) {
          url = this.mockUrls[reg];
        }
      }

      const _url = url.match(/http?/) ? url : this.baseURL + '/' + url;

      res = await axios[method](_url, options);
    } catch (e) {
      response.setError(e || 'server');
      return response;
    }
    response.setData(res);

    return response;
  },
  get(url, options) {
    return this.run('get', url, options);
  },
  post(url, options) {
    return this.run('post', url, options);
  },
};

class apiResponse {
  constructor() {
    this._error = null;
  }

  setData(res) {
    this._response = res;
    this._data = res.data;

    if (this._data.error) {
      this.setError({ response: this._response });
    }
  }

  setError(error) {
    this._error = error;

    if (this._error.response && this._error.response.data) {
      this._data = this._error.response.data;
    }
  }

  error() {
    return this._error !== null;
  }

  errorMsg() {
    if (this._error.response && this._error.response.data.message) {
      return this._error.response.data.message;
    }
    if (this._error.message) {
      return this._error.message;
    }
    return this._error;
  }

  data() {
    return this._data;
  }

  payload() {
    return this._data.payload;
  }
}
