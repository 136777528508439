<script>
import GalleryMenuItem from '@/components/gallery/item/GalleryMenuItem.vue';
import SocialTwitter from '@/components/gallery/social/SocialTwitter.vue';
import SocialTelegram from '@/components/gallery/social/SocialTelegram.vue';
import SocialDiscord from '@/components/gallery/social/SocialDiscord.vue';
import SocialMagicEden from '@/components/gallery/social/SocialMagicEden.vue';
import GalleryPage from '@/components/gallery/page/GalleryPage.vue';
import SocialInstagram from '@/components/gallery/social/SocialInstagram.vue';
import SocialSoundCloud from '@/components/gallery/social/SocialSoundCloud.vue';
import ContentPage from '@/components/gallery/page/ContentPage.vue';

export default {
  name: 'Cms',
  components: {
    ContentPage,
    GalleryPage,
    SocialMagicEden,
    SocialDiscord,
    SocialTelegram,
    SocialTwitter,
    GalleryMenuItem,
    SocialSoundCloud,
    SocialInstagram,
  },
  data: () => ({
    sidebarOpen: false,
    // currentPage: 'gallery',
  }),
  computed: {
    currentPage() {
      return this.$route.name;
    },
  },
  methods: {
    openPopup(variable) {
      this[variable] = true;
      document.body.style.overflow = 'hidden';
    },
    closePopup(variable) {
      this[variable] = false;
      document.body.style.overflow = '';
    },
    changePage(page) {
      this.$router.push('/' + page);
      this.closePopup('sidebarOpen');
    },
  },
};
</script>

<template>
  <div id="gallery">
    <div class="page">
      <div class="sidebar" :class="{ sidebarOpen }" @click.stop.exact="closePopup('sidebarOpen')">
        <a href="/#" class="logo">
          <img src="/images/site/loaderON.png" alt="logo" />
        </a>
        <div class="menu" @click.prevent.stop>
          <GalleryMenuItem ico-img="/images/site/ico_mint.png" label="GALLERY" :active="currentPage === 'hub'" @click.stop="changePage('hub')" />
          <GalleryMenuItem ico-img="/images/site/documents.png" label="DOCUMENTS" :active="currentPage === 'documents'" @click.stop="changePage('documents')" />
          <GalleryMenuItem ico-img="/images/site/network.png" label="NETWORK STATE" :active="false" soon />
          <GalleryMenuItem ico-img="/images/site/gift.png" label="GIFT SHOP" :active="false" soon />
        </div>
        <div class="footer" @click.stop>
          <ul class="social">
            <li class="social-link">
              <SocialTwitter url="https://twitter.com/Ailu2100" />
            </li>
            <li class="social-link">
              <SocialTelegram url="https://t.me/ailu2100" />
            </li>
            <li class="social-link">
              <SocialDiscord url="https://discord.gg/AILU" />
            </li>
            <li class="social-link">
              <SocialMagicEden url="https://magiceden.io/ordinals/marketplace/ailu" />
            </li>
            <li class="social-link">
              <SocialSoundCloud url="https://soundcloud.com/endangeredlabs" />
            </li>
            <li class="social-link">
              <SocialInstagram url="https://instagram.com/ailu2100" />
            </li>
          </ul>
          <div class="copyright"><a href="https://twitter.com/Ailu2100" style="color: white" target="_blank"> © Endangered Labs </a></div>
        </div>
      </div>
      <GalleryPage v-if="currentPage === 'hub'" />
      <ContentPage v-if="['documents', 'community'].includes(currentPage)" :category-id="currentPage === 'documents' ? '8b6db965-7728-4798-9f15-58826b684b6a' : '8ed297c2-bc2c-4973-84d9-68ad2ad45582'" :label="currentPage === 'documents' ? 'Documents' : 'Network State'" />
    </div>
  </div>
</template>

<style scoped lang="scss">
#gallery {
  .page {
    position: relative;

    .sidebar {
      position: fixed;
      top: 0;
      left: 25px;
      bottom: 0;
      width: 240px;
      display: flex;
      flex-direction: column;

      @media (max-width: 900px) {
        z-index: 20;
        left: 0;
        padding: 0 20px;
        right: 0;
        width: auto;
        backdrop-filter: blur(0px);
        pointer-events: none;
        transition: 0.5s;

        & > div,
        & > .logo {
          transform: translateX(-110%);
          width: 300px;
          transition: 0.5s;
        }
        &.sidebarOpen {
          pointer-events: all;
          backdrop-filter: blur(10px);

          & > div,
          & > .logo {
            transform: translateX(0%);
          }
        }
      }
      @media (max-width: 520px) {
        width: auto;
        & > div,
        & > .logo {
          transform: translateX(-110%);
          width: auto;
          transition: 0.5s;
        }
        &.sidebarOpen {
          transform: translateX(0%);
          background: #0a1b1b;
        }
      }

      .logo {
        margin-top: 45px;
        margin-bottom: 45px;
        text-align: center;

        img {
          width: 75px;
        }
      }

      .menu {
        background: #0a1b1b;
        border: 1px solid #356666;
        border-radius: 6px;
        padding: 16px 0;
        height: 100%;
      }

      .footer {
        margin-top: 20px;
        background: #0a1b1b;
        border: 1px solid #356666;
        border-radius: 6px 6px 0 0;
        padding: 16px 0 0 0;

        .social {
          border-top: 1px solid #356666;
          border-bottom: 1px solid #356666;
          padding: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0px;
        }

        .copyright {
          text-align: center;
          padding: 12px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#gallery {
  .content {
    padding-left: calc(240px + 50px);
    padding-right: 40px;

    @media (max-width: 900px) {
      padding-left: 40px;
    }

    @media (max-width: 520px) {
      padding: 20px;
    }

    .header {
      display: flex;
      justify-content: flex-end;

      .menu-mobile {
        display: none;
        @media (max-width: 900px) {
          display: flex;
          margin-right: auto;
          margin-top: 40px;
          gap: 10px;
        }
        @media (max-width: 520px) {
          margin-top: 0px;
        }
      }

      .connect-wallet {
        @media (max-width: 900px) {
          display: none;
        }
      }
    }

    .connect-wallet {
      width: 240px;

      span {
        font-size: 16px;
      }
    }
  }
}
</style>
