export const ES = {
  header: {
    title: 'ENDANGERED LABS',
    subtitle: 'Endangered Labs es un estudio Omniverso para seres conscientes. Unidos construiremos un <strong>estado de red enfocado en el empoderamiento de los creadores</strong> a través de IP interoperables y <strong>experiencias gamificadas</strong>',
  },
  menu: {
    constro: 'La Constro.',
    identity: 'Identidad.',
    narrative: 'Narrativa.',
    team: 'Equipo.',
    faq: 'Preguntas frecuentes.',
  },
  floating: {
    mint: 'Mint',
    premint: 'Únete',
    meme: {
      free: 'Gratis',
      text: 'Memetics',
    },
    trailer: {
      watch: 'Ver el',
      trailer: 'Avance',
    },
    game: {
      free: '',
      text: 'PLAY NOW',
    },
    download: {
      download: 'Descargar',
      models: 'modelos',
    },
  },
  constro: {
    title: 'La constro',
    detail1: 'La Constro es un portal construido para la próxima generación de la cultura de Internet. Juntos onderemos a los Ailus <strong>a la economía virtual a través de la identidad soberana y experiencias gamificadas.</strong>',
    detail2: 'AILU, la colección de origen, consta de personajes de pandas cibernéticos <strong>diseñados para una creación de contenido sin fricciones</strong> en todo el metaverse abierto, las plataformas sociales y los motores de juego.',
    detail3: 'Los Ailus están empoderados con las herramientas para explorar nuevas formas de expresión y <strong>contribuir al efecto de red final.</strong>',
    detail4: 'Como un ecosistema inclusivo, estamos apasionados por la construcción de marcas, la <strong>narrativa colaborativa y las tecnologías emergentes.</strong> Las posibilidades son infinitas y La Constro será cada vez más expansiva.',
  },
  identity: {
    title: 'Tu nueva identidad',
    avatar: {
      title: 'Interoperabilidad del avatar',
      detail: 'Con un <strong>modelo 3D</strong>, los usuarios pueden interactuar entre sí en muchos sistemas y participar juntos en actividades y eventos en un espacio virtual compartido. <strong>Usa tus personajes en redes sociales, motores de juego y mundos virtuales.</strong>',
    },
    content: {
      title: 'Oportunidades de creación de contenido',
      detail: 'Activos optimizados para una creación de contenido sin fricciones con una barrera de tokens en 3D y rigging. <strong>Usa tu Ailu para crear animaciones, incrustarlo en juegos y transmitir con captura de movimiento.</strong>',
    },
    identity: {
      title: 'Identidad del Avatar',
      detail: 'Sé tu Ailu en Zoom, Twitch, Google Meets, VRchat y más con seguimiento de rostro y cuerpo. <strong>No se necesita equipo de captura de movimiento</strong>. Solo tu Ailu y una webcam.',
    },
    printing: {
      title: 'Impresión en 3D',
      detail: 'Puedes imprimir en 3D tu modelo para crear <strong>juguetes, esculturas y moldes</strong> de tu Ailu. La impresión en 3D te permite crear artículos personalizados y producirlos eficazmente.',
    },
    types: {
      title: 'Todos los tipos de archivos disponibles',
      detail: '- Modelo 3D con rigging <br/>\n' + '- PNG con fondo <br/>\n' + '- PNG con fondo transparente',
    },
  },
  narrative: {
    title: 'Narrativa',
    n1:
      '<span>> </span>\n' +
      'Eres un panda AILU único.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      'Nuestros CREADORES te hicieron haciendo splicing de ADN de todas las estrellas para RESUCITAR tu especie.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      'Bienvenido a LA CAPA UNO. Este es tu nuevo cuerpo. Podrás existir en esta forma en muchos sistemas. Tu código único te permite prosperar en cualquier universo que elijas visitar.',
    n2:
      '<span>> </span>\n' +
      'Los OJOS en tu CABEZA y MANOS tienen algunas características únicas.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      'Tu PAW AILU abrirá muchas puertas. Úsalo para acceder o desbloquear VEHÍCULOS, ARTÍCULOS, PORTALES, incluso LA CONSTRO misma.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      'Rayos de plasma. Grabación de memoria. Proyección AR.\n' +
      '<br/><br/>\n' +
      '<span>> </span>\n' +
      '... Hay más mejoras en el horizonte.',
    n3: '<span>> </span>\n' + ' La GEOMETRÍA en tu BRAZO CIBERNÉTICO es SAGRADA. Descubrir su potencial completo tomará tiempo.\n' + ' <br/><br/>\n' + ' <span>> </span>\n' + ' Tus misiones no serán fáciles. Muchos juegos están en pie y conocerás otros AILUS PANDAS que pueden ayudarte en el tiempo y el espacio.',
    n4:
      '<span>> </span>\n' +
      ' Permíteme presentarme adecuadamente. Soy KORII, la voz que sirve a CONSTRO. Nuestros creadores establecieron esta IA en la Tierra en el alba de la GRAN ASCENDENCIA.\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' Fue un tiempo de avance tecnológico para los humanos, ya que las máquinas y el hombre se convirtieron en uno. Pronto después, la raza humana se alejó de lo que la naturaleza había previsto.\n' +
      ' <br/><br/>\n' +
      ' <span>> </span>\n' +
      ' En un esfuerzo por estar más en sintonía con lo que alguna vez fueron, los humanos se fusionaron con diferentes especies redescubriendo los animales dentro de ellos.',
    n5: '<span>> </span>\n' + ' El estado actual de la Tierra es desconocido.\n' + ' <br/><br/>\n' + ' <span>> </span>\n' + ' Todo lo que se conoce permanece aquí en CONSTRO, nuestro hogar, un lugar entre mundos.\n',
  },
  faq: {
    title: 'FAQ',
    questions: {
      q1: {
        question: '¿Qué es Endangered Labs?',
        answer: '<p>Endangered Labs es un estudio Omniverse para seres conscientes.\n' + ' <br/><br/>\n' + ' Unidos construiremos un estado de red enfocado en el empoderamiento de los creadores a través de Digital Artifacts, IP interoperables y experiencias gamificadas.\n' + ' </p>',
      },
      q2: {
        question: '¿Qué es la colección AILU?',
        answer:
          '<p>AILU es la colección de origen de Endangered Labs.\n' +
          ' <br/><br/>\n' +
          ' Colección de avatares de panda cibernéticos en 3D con sistemas de rig que se diseñaron para empoderar la economía de los creadores pseudónimos con una generación de contenido sin fricciones en las redes sociales y los motores de juegos.\n' +
          ' <br/><br/>\n' +
          ' Los Ailus te permiten crear contenido a través de seguimiento facial y corporal sin necesidad de experiencia previa en 3D, animación o captura de movimiento.\n' +
          ' </p>',
      },
      q3: {
        question: '¿Qué puedo hacer con mi AILU?',
        answer:
          '<p>Alentamos a todos los miembros de la comunidad a canalizar sus habilidades creativas, pasiones e inspiración hacia la construcción del metaverse abierto.\n' +
          ' <br/><br/>\n' +
          ' Recomendamos encarecidamente que cada Ailu trabaje juntos y se eduque mutuamente en software creativo y lenguajes de codificación como: Unreal Engine 5, Blender, Zbrush, Maya, Solidity, Rust y Python.\n' +
          ' <br/><br/>\n' +
          ' Algunas formas de aprovechar tu Ailu:\n' +
          ' Crear la lore y la historia única de tu Panda.\n' +
          ' Construir tu marca como influencer virtual\n' +
          ' Producir música como tu personaje para convertirte en un Metastar\n' +
          ' Crear animaciones\n' +
          ' Diseñar accesorios\n' +
          ' Impresión 3D.\n' +
          ' <br/><br/>\n' +
          ' Las posibilidades son infinitas, y solo estamos raspando la superficie de la creación de contenido.\n' +
          ' </p>',
      },
      q4: {
        question: '¿Cuál es la fecha de lanzamiento y el precio de la acuñación?',
        answer: '<p>Los detalles sobre la fecha y el precio de la acuñación se anunciarán en nuestro 𝕏 a medida que nos acerquemos a entregar la colección.</p>',
      },
      q5: {
        question: '¿Hay una lista de acuñación garantizada?',
        answer: `<p>Siga <a href="https://twitter.com/Ailu2100" target="_blank">{'@'}endangeredlabs</a> y encuéntrelo.</p>`,
      },
      q6: {
        question: '¿Qué derechos de propiedad intelectual tienen los titulares de AILU?',
        answer: '<p>Los dueños de AILU tienen derechos comerciales completos. Ver detalles completos en <a href="Endangered_Labs_Terms.pdf" target="_blank">Términos.</a></p>',
      },
      q7: {
        question: '¿Hay un Roadmap?',
        answer: '<p>Web3 está en constante cambio, y Endangered Labs está construido para adaptarse a un futuro en evolución',
      },
    },
  },
  footer: {
    backtotop: 'Volver al inicio',
    followus: 'Síguenos',
    ontwitter: 'En 𝕏',
    contact: 'Contacto.',
    terms: 'Términos.',
    privacy: 'Privacidad.',
  },
  downloadmodule: {
    title: 'Descargar archivos',
    model: 'modelo 3d',
    fullview: 'Vista completa',
    nobackground: 'sin fondo',
    glb: 'archivo glb',
    png: 'archivo png',
    pfp: 'PFP',
    nobg: 'sin fondo',
    modelA: 'Modelo - Pose A',
    modelT: 'Modelo - Pose T',
  },
};
