<template>
  <div class="btc-mint__loader-global" :class="{ show: show }">
    <svg class="btc-mint__ico" viewBox="0 0 93 95">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M89.0446 60.4346C83.5374 78.1426 67.0202 91 47.5 91C23.4756 91 4 71.5244 4 47.5C4 23.4756 23.4756 4 47.5 4C55.3662 4 62.7448 6.08795 69.1117 9.73988L71.4356 6.46259C64.4072 2.35432 56.2285 0 47.5 0C21.2665 0 0 21.2665 0 47.5C0 73.7335 21.2665 95 47.5 95C68.7374 95 86.7195 81.0625 92.7986 61.8353L89.0446 60.4346Z" fill="#00FFFF" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'btcLoaderGlobal',
  props: {
    show: {
      type: [Boolean, Number],
      default: false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
