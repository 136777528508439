<template>
  <span class="countdown">
    {{ days > 0 || show.d ? days + 'd ' : '' }}
    {{ hours > 0 || show.h ? hours + 'h ' : '' }}
    {{ minutes > 0 || show.m ? minutes + 'm ' : '' }}
    {{ seconds > 0 || show.s ? seconds + 's ' : '' }}
  </span>
</template>

<script>
import moment from 'moment';
import 'moment-countdown';

export default {
  props: {
    date: { type: String, default: '' },
    show: {
      type: Object,
      default: () => ({
        d: true,
        h: true,
        m: true,
        s: true,
      }),
    },
  },
  data: () => ({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }),
  mounted() {
    this.onInterval();
    this.interval = setInterval(this.onInterval, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    onInterval() {
      const date = new Date(this.date);
      const count = moment().countdown(date);
      const { days, hours, minutes, seconds } = count;
      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
      if (date <= new Date()) {
        clearInterval(this.interval);
        this.$emit('done');
      }
      if (this.days === 0 && this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
        clearInterval(this.interval);
        this.$emit('done');
      }
    },
  },
};
</script>

<style lang="scss">
.countdown {
  text-transform: uppercase;
}
</style>
