export const EN = {
  header: {
    title: 'ENDANGERED LABS',
    subtitle: 'Endangered Labs is an <strong>Omniverse studio </strong>for sentient beings. United we will build a <strong>network state focused on creator empowerment</strong> via interoperable IP and <strong>gamified experiences</strong>',
  },
  menu: {
    constro: 'Constro.',
    identity: 'AILU.',
    narrative: 'Narrative.',
    team: 'Team.',
    faq: 'FAQ.',
  },
  floating: {
    mint: 'Mint',
    premint: 'Join us',
    meme: {
      free: 'Free',
      text: 'Memetics',
    },
    game: {
      free: '',
      text: 'PLAY NOW',
    },
    trailer: {
      watch: 'Watch the',
      trailer: 'Trailer',
    },
    download: {
      download: 'Download',
      models: 'models',
    },
  },
  constro: {
    title: 'Constro',
    detail1: 'Constro is a portal built for the next generation of internet culture. Together we will onboard Ailus <strong>into the virtual economy via sovereign identity and gamified experiences.</strong>',
    detail2: 'AILU, the origin collection, consists of cybernetic panda characters <strong>designed for frictionless content creation</strong> across the open metaverse, social platforms and game engines.',
    detail3: 'Ailus are empowered with the tools to explore new forms of expression and <strong>contribute towards the ultimate network effect.',
    detail4: "As an inclusive ecosystem, we're passionate about brand building, collaborative <strong>storytelling and emerging technologies.</strong> The possibilities are infinite and Constro will be ever-expanding.",
  },
  identity: {
    title: 'AILU',
    avatar: {
      title: 'Avatar interoperabilty',
      detail: 'With a <strong>3D model</strong> users can interact with each other across many systems and participate in activities and events together in a shared <strong>virtual space.</strong> Use your characters across <strong>social networks, game engines, and virtual worlds.</strong>',
    },
    content: {
      title: 'Content creation opportunities',
      detail: 'Token gated, 3D, rigged assets optimized for <strong>frictionless content creation.</strong> Use your Ailu to <strong>create animations</strong>, <strong>plug it into games</strong>, and <strong>stream with motion capture.</strong>',
    },
    identity: {
      title: 'Avatar Identity',
      detail: 'Be your Ailu on Zoom, Twitch, Google Meets, VRchat and more with face and body tracking. <strong>No motion capture equipment needed.</strong> Just your Ailu and a webcam.',
    },
    printing: {
      title: '3D Printing',
      detail: 'You can 3D print your model to create <strong>toys, sculptures and molds</strong> of your Ailu. 3D printing allows you to create custom items and mass-produce them effectively.',
    },
    types: {
      title: 'All file types available',
      detail: '- 3D rigged model<br/>\n' + '                - PNG with background <br/>\n' + '                - PNG with transparent background',
    },
  },
  narrative: {
    title: 'Narrative',
    n1:
      '<span>> </span>\n' +
      '            You are a unique AILU PANDA.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            Our CREATORS made you by splicing DNA from across the stars to RESURRECT your kind.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            Welcome to LAYER ONE. This is your new body. You will be able to exist in this form across many systems. Your unique code allows you to thrive in any universe you choose to visit.',
    n2:
      '<span>> </span>\n' +
      '           The EYES in your HEAD and HANDS have some unique features.\n' +
      '           <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            Your AILU PAW will open many doors. Use it to access or unlock VEHICLES, ITEMS, PORTALS, even CONSTRO itself.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            Plasma beams. Memory recording. AR projection.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            ... More upgrades are on the horizon.',
    n3: '<span>> </span>\n' + '            The GEOMETRY on your CYBER ARM is SACRED. Figuring out its full potential will take time.\n' + '            <br/><br/>\n' + '            <span>> </span>\n' + '            Your missions will not be easy. Many games are afoot and you will come to know other AILUS PANDAS that can assist you in time and space.',
    n4:
      '<span>> </span>\n' +
      '            Let me properly introduce myself. I am KORII, the voice that serves CONSTRO. Our Creators established this AI on EARTH at the dawn of the GREAT ASCENDANCE.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            It was a time of technological advancement for humans as machines and man became one. Soon after the human race grew apart from what nature had intended.\n' +
      '            <br/><br/>\n' +
      '            <span>> </span>\n' +
      '            In an effort to become more in touch with who they once were, humans merged with different species rediscovering the animals within.',
    n5: '<span>> </span>\n' + '           The state of Earth at the present time remains unknown.\n' + '           <br/><br/>\n' + '            <span>> </span>\n' + '            All that is known remains here on CONSTRO, our home, a place between worlds.\n',
  },
  faq: {
    title: 'FAQ',
    questions: {
      q1: {
        question: 'What is Endangered Labs?',
        answer: `<p>Endangered Labs is an Omniverse studio for sentient beings.<br/><br/>United we will build a network state focused on creator empowerment via Digital Artifacts, interoperable IP and gamified experiences.</p>`,
      },
      q2: {
        question: 'What is the AILU Collection?',
        answer: `<p>AILU is the origin collection by Endangered Labs.
                        <br/><br/>
                        Collection of 3D, rigged, cybernetic panda avatars designed to empower the pseudonymous creator economy with frictionless content generation across social networks and game engines.
                        <br/><br/>
                        Ailus allow you to create content through facial and body tracking without any prior 3D, animation, or motion capture experience.
                        </p>`,
      },
      q3: {
        question: 'What can I do with my AILU?',
        answer: `<p>We encourage all community members to channel their creative skills, passions, and inspiration towards building the open metaverse.
                        <br/><br/>
                        We strongly recommend each Ailu to work together and educate each other on creative software and coding languages such as: Unreal Engine 5, Blender, Zbrush, Maya, Solidity, Rust, and Python.
                        <br/><br/>
                        Some ways to leverage your Ailu: Crafting your Panda's unique lore and story. Building your brand as a virtual influencer. Producing music as your character to become a Metastar. Creating animations Designing wearables 3D printing.
                        <br/><br/>
                        The possibilities are endless, and we are just scratching the surface of content creation.
                        </p>`,
      },
      q4: {
        question: 'What is the release date and mint price?',
        answer: '<p>Details on the mint date and price will be announced on our 𝕏 as we get closer to delivering the collection.</p>',
      },
      q5: {
        question: 'Is there a guaranteed mint list?',
        answer: `<p>Follow <a href="https://twitter.com/Ailu2100" target="_blank">{'@'}endangeredlabs</a> and find out.</p>`,
      },
      q6: {
        question: 'What intellectual property rights do AILU holders have?',
        answer: `<p>Owners of AILU have full commercial rights. See full details in <a href="Endangered_Labs_Terms.pdf" target="_blank">Terms.</a></p>`,
      },
      q7: {
        question: 'Is there a Roadmap',
        answer: `<p>Web3 is constantly changing, and Endangered Labs is built to adapt to an evolving future. We don't want to confine ourselves to a strict roadmap as we must be versatile to deliver a successful product.<br/><br/>Our goal is to execute and deliver. No roadmap needed.<br/><br/>For now, you can get an idea of what we are working on by reading the Narrative.</p>`,
      },
    },
  },
  footer: {
    backtotop: 'Back to the top',
    followus: 'Follow us',
    ontwitter: 'On 𝕏',
    contact: 'Contact.',
    terms: 'Terms.',
    privacy: 'Privacy.',
  },
  downloadmodule: {
    title: 'Download Files',
    model: '3D model',
    fullview: 'Full view',
    nobackground: 'No background',
    glb: 'glb file',
    png: 'png file',
    webp: 'webp file',
    fullbody: 'Full Body',
    zip: 'All',
    pfp: 'PFP',
    nobg: 'No Background',
    ordfullbody: 'FULL BODY',
    ordpfp: 'PFP',
    ordnobg: 'NO BACKGROUND',
    currentPose: 'Pose',
    modelA: 'Model - A pose',
    modelT: 'Model - T pose',
    vrm: 'VRM',
    'vrm-file': 'vrm file',
    'zip-file': 'Zip file',
  },
  connect: {
    connect: 'Connect Wallet',
    hiro: 'Leather Wallet',
    unisat: 'UNISAT Wallet',
    xverse: 'XVERSE Wallet',
    phantom: 'Phantom Wallet',
    magicEden: 'Magic Eden Wallet',
    noconnect: 'Use your own wallet',
  },
  mint: {
    popup: {
      connect: 'CONNECT',
      disconnect: 'DISCONNECT',
      mint: 'INSCRIBE',
      close: 'CLOSE',
    },
    title: {
      whitelist: 'Initialisation',
      mint: 'Inscribe',
      waitingPaiement: 'Pending Payment',
      waitingConfirmations: 'Payment Progress',
      orderDetails: 'Order Details',
    },
    supply: 'Supply',
    recipientAddress: {
      title: 'Recipient Address',
      text: `Tell us the <b class="btc-mint__c-primary-desaturated">Ordinal address</b> (taproot) you want AILU sent to:`,
      placeholder: `Enter your ordinal address here..`,
    },
    refundAddress: {
      title: 'Refund Address',
      text: `Tell us the <b class="btc-mint__c-primary-desaturated">BTC address</b> for refund if needed:`,
      placeholder: `Enter your BTC address here..`,
    },
    amount: {
      title: 'Select count',
      mintPrice: 'Price',
    },
    fee: {
      title: 'Fee',
      text: 'Select the network fee you want to pay',
      button: {
        economy: 'Economy',
        normal: 'Normal',
        custom: 'Custom',
      },
      delay: {
        multipleHours: 'Multiple hours',
        hours: 'hour',
        minutes: 'minutes',
        timeNotGuaranteed: `Times shown are not guaranteed. USD values are estimates only`,
      },
      cost: {
        mint: 'Mint Cost',
        network: 'Network fee',
        service: 'Service Fee',
        total: 'Total',
      },
    },
    password: {
      title: 'Access Password',
      text: `Enter your <b class="btc-mint__c-primary-desaturated">access password</b>:`,
      placeholder: `Enter your password here..`,
    },
    wallet: {
      title: 'Access List',
      text: `Enter your <b class="btc-mint__c-primary-desaturated">Ordinal Address</b> (taproot):`,
      placeholder: `Enter your Ordinal Address (taproot) here..`,
    },
    waitingPaiement: 'Waiting for your full payment',
    waitingPaiementConfirm: 'Waiting for payment confirmation',
    mintClosed: 'Order Expired',
    refresh: 'If you have already paid you can click here to refresh',
    expiration: 'The order will expire on ',
    noExpiration: 'The order will no longer expire.',
    mintDelay: 'Depending on your gas fees, payment can be confirmed in several hours (minimum 20 minutes).',
    check: 'Check',
    mint: 'Inscribe',
    connectMessage: 'Address for receiving Ordinals and refund if needed',
    notAllowed: 'Max minted',
    payWithWallet: {
      hiro: 'Pay with Leather Wallet',
      unisat: 'Pay with Unisat Wallet',
      xverse: 'Pay with xVerse Wallet',
      phantom: 'Pay with Phantom Wallet',
    },
    notifs: {
      connectSuccess: {
        hiro: 'You are connected with your Leather Wallet',
        unisat: 'You are connected with your Unisat Wallet',
        xverse: 'You are connected with your xVerse Wallet',
        phantom: 'You are connected with your Phantom Wallet',
        magicEden: 'You are connected with your Magic Eden Wallet',
      },
      connectError: {
        unisat: "You don't have Unisat wallet.",
        phantom: "You don't have Phantom wallet, or Bitcoin wallet is not setup",
      },
      payWithWallet: {
        xverse: 'The payment has been validated and will be processed in a few seconds.',
        unisat: 'The payment has been validated and will be processed in a few seconds.',
      },
      errors: {
        collection: 'Error: Unable to retrieve the collection',
        orders: 'Error: Unable to retrieve the orders',
        currency: 'Error: Unable to retrieve the estimate BTC/USD',
        gas: 'Error: Unable to retrieve gas estimate',
        gasTooLow: 'Error: Gas is too low',
        cost: 'Error: Unable to retrieve the cost calculation',
        notWhitelisted: 'Error: Your wallet is not whitelisted',
        passwordMissing: 'Error: Please enter your password',
        whitelist: 'Error: Unable to retrieve the whitelist',
        passwordBad: 'Error: Is not the good password',
        whitelisted: 'Success: Your Ordinal address is whitelisted',
        whitelistWalletBad: 'Error: Your Ordinal address is not whitelisted',
        maxAllowed: 'Error: Too many inscriptions asked',
        notTaproot: 'Error: Your Ordinal address is not Taproot, please change it.',
        userRefundAddress: 'Error: Refund Address is required.',
        payWithWallet: {
          xverse: 'Error: Unable to send transaction',
          unisat: 'Error: Unable to send transaction',
        },
      },
    },
  },
  myinscriptions: {
    title: 'My Inscriptions',
    pictures: 'Pictures',
    noArtifacts: `You don't have inscriptions.`,
    inscriptionsIds: 'Inscription Ids',
    refresh: 'REFRESH',
    orders: 'SEE MY ORDERS',
    more: 'INSCRIBE MORE',
  },
  orders: {
    completed: {
      ordinal: {
        inscribe: '',
        preview: `Here's what they'll look like`,
        completed: `Here's what they look like`,
        payment: 'Congrats, we have received your payment, your ordinals will be inscribed very soon!',
        congrats: 'Congrats, your ordinals has been inscribed',
      },
      orderDetails: 'Order Details',
      inscriptions: 'Inscriptions',
      orderId: 'Order Id',
      recipientAddress: 'Recipient Address',
      shareTitle: 'Share on X',
      shareText: 'Share',
      mintTx: 'Inscription Tx',
      receiveAddress: 'You will receive your ordinal(s) on ',
      receivedAddress: 'You received your ordinal(s) on',
      artifact: 'Artifact Mint',
      status: 'Sending status',
      mintingProgress: 'We are inscribing your Ordinals',
      orderRefund: 'Refund',
      minting: 'Inscribe in process',
      minted: 'Inscribe done',
      all: 'All ordinals are inscribe and sent to your recipient wallet',
      refresh: 'REFRESH',
      orders: 'SEE MY ORDERS',
      more: 'INSCRIBE MORE',
    },
    received: 'Received',
    needed: 'Needed',
    confirmOrder: 'Please Send <btc>{{ btcAmount }}</btc> BTC to <address>{{ receiveAddress }}</address> to confirm your order..',
    waitPAiement: 'Waiting on payment.',
    refreshAlreadyPais: 'If you have already paid you can click here to refresh',
    soldout: 'SOLDOUT',
    soldoutCollection: 'All ordinals in this collection have been inscribed, so the only way to get one is to buy it on the secondary market.',
    statusCheck: 'Orders',
    pastOrders: 'Past Orders',
    noOrders: `You don't have a current orders.`,
  },
};
